import React from "react";
import { Card } from "@mui/material";

const styles = {
  card: {
    borderRadius: "8px",
    flexGrow: 1,
    height: "100%",
    boxShadow:
      "0px 2px 1px rgba(22, 8, 79, 0.05), 0px 0px 1px rgba(22, 8, 79, 0.25)",
  },
};

export default function OutPointCard({ children, sx = {}, ...rest }) {
  return (
    <Card sx={{ ...styles.card, ...sx }} {...rest}>
      {children}
    </Card>
  );
}
