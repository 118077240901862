import { createTheme } from "@mui/material";

const POPPINS = {
  fontFamily: "Poppins",
  fontWeight: "600",
  letterSpacing: "0.342857px",
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#2B2B2B",
    },
    secondary: {
      main: "rgba(0, 0, 0, 0.6)",
    },
    inverseSecondary: {
      main: "rgba(255, 255, 255, 0.3)",
    },
    base: {
      main: "#FFFFFF",
    },
    grey: {
      light: "#FAFAFC",
      main: "#76757D",
    },
    blue: {
      main: "#3D31ED",
    },
    green: {
      main: "#027D58",
    },
  },
  typography: {
    h1: POPPINS,
    h2: POPPINS,
    h3: POPPINS,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
