/**
 * This file contains helper functions for formatting data.
 * */
import { capitalize } from "lodash";

/**
 * Values for KPI in our db are in snake_case. This prettifies
 * it so that it can be displayd.
 * */
export const formatKpiForDisplay = (kpi) => {
  const formattedKpi = kpi.replaceAll("_", " ");
  if (formattedKpi.length > 4) return capitalize(formattedKpi);
  return formattedKpi.toUpperCase();
};

/**
 * Since our db values for kpi are in snake_case, this shall return
 * a snake_cased version of a display-formatted KPI that can be used
 * to pass to the backend.
 * */
export const unformatKpi = (kpi) => {
  const formattedKpi = kpi.replaceAll(" ", "_");
  return formattedKpi.toLowerCase();
};
