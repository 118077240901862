//
// Methods in this file should handle date related data processing for a generic dataset.
//

import { format, utcToZonedTime } from "date-fns-tz";
import { parse, subDays, subMonths, subYears } from "date-fns";
import {
  SEC_DAY,
  CALENDAR_DATE_FORMAT,
  DEFAULT_DATE_FORMAT,
  KHC_TAXONOMY_DATE_FORMAT,
} from "utils/enums";

export const DAYS = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export function rawHourTo24hClock(hour) {
  return `${hour < 10 ? "0" : ""}${hour}:00`;
}

export const HOURS = Array.from({ length: 24 }, (_, index) =>
  rawHourTo24hClock(index),
);

/* Given a date in seconds, return a string of the format DEFAULT_DATE_FORMAT */
export function dateSecondsToString(date, dateFormat = DEFAULT_DATE_FORMAT) {
  const dateString = format(new Date(date), dateFormat);
  return dateString;
}

/* Creates a new date using standard UTC time */
export function createNewDate(dateInitData = null) {
  const dateObj =
    dateInitData !== null || dateInitData === undefined
      ? new Date(dateInitData)
      : new Date();
  const date = utcToZonedTime(dateObj, "UTC");
  return date;
}

export const secondsToDate = (seconds) => {
  const newDate = new Date(0);
  newDate.setSeconds(seconds);

  return newDate.toISOString().split("T")[0];
};

export const secondsToLocaleString = (seconds, options = {}) => {
  /* 
  TODO link to read me (pls don't approve PR if u catch this)
  */
  const newDate = new Date(0);
  newDate.setSeconds(seconds);

  return newDate.toLocaleDateString("en-US", options);
};

/* Given a date of the form "YYYY-MM-DD", return a string of the form "Month Day, Year" */
export function dateToString(date) {
  if (date) {
    const [year, month, day] = date.split("-");
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return `${monthNames[parseInt(month, 10) - 1]}  ${parseInt(
      day,
      10,
    )}, ${year}`;
  }
  return null;
}

/* Given a date of the format DEFAULT_DATE_FORMAT, add one day and return it in the same format */
export function addDayToDateString(dateString) {
  return dateSecondsToString(createNewDate(dateString).getTime() + SEC_DAY);
}

export const getDateRange = ({ endDate = undefined, options = {} }) => {
  const dateB = endDate || new Date();

  const {
    rangeMeasurement = "year",
    rangeUnit = 1,
    dateFormat = CALENDAR_DATE_FORMAT,
  } = options;

  // supported date ranges for now include days, months, and years
  // feel free to add more support if required
  let dateA;

  if (rangeMeasurement === "day") {
    dateA = subDays(dateB, rangeUnit);
  }

  if (rangeMeasurement === "month") {
    dateA = subMonths(dateB, rangeUnit);
  }

  if (rangeMeasurement === "year") {
    dateA = subYears(dateB, rangeUnit);
  }

  return [format(dateA, dateFormat), format(dateB, dateFormat)];
};

export const formatDate = ({
  date,
  fromFormat = CALENDAR_DATE_FORMAT,
  toFormat = DEFAULT_DATE_FORMAT,
}) => {
  if (!date) return null;

  return format(parse(date, fromFormat, new Date()), toFormat);
};

export function strpdate(dateString) {
  const dateObj = new Date(Date.parse(dateString));
  if (!Number.isNaN(dateObj.valueOf())) return dateObj;
  return parse(dateString, "yyyy-MM-dd HH:mm:ss.SSSSSSX", new Date());
}

export const mmddToCalendarFmt = (mmdd) => {
  if (!mmdd) {
    return null;
  }

  let formattedDate = null;

  try {
    const parsedDate = parse(mmdd, KHC_TAXONOMY_DATE_FORMAT, new Date());
    formattedDate = format(parsedDate, CALENDAR_DATE_FORMAT);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(">> date parsing error (mmdd to cal):", { e, mmdd });
  }

  return formattedDate;
};

export const calendarDateFmtToMmdd = (cal) => {
  if (!cal) {
    return null;
  }

  let formattedDate = null;
  try {
    const parsedDate = parse(cal, CALENDAR_DATE_FORMAT, new Date());
    formattedDate = format(parsedDate, KHC_TAXONOMY_DATE_FORMAT);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn(">> date parsing error (cal to mmdd):,", { e, cal });
    return null;
  }

  return formattedDate;
};

export const dateStringToMmdd = (datestring) => {
  if (!datestring) {
    return null;
  }
  try {
    const parsedDate = new Date(datestring);
    const utcMonth = parsedDate.getUTCMonth() + 1;
    const utcDay = parsedDate.getUTCDate();
    const formattedDate = `${utcMonth < 10 ? 0 : ""}${utcMonth}${
      utcDay < 10 ? 0 : ""
    }${utcDay}`;
    return formattedDate;
  } catch (e) {
    return null;
  }
};
