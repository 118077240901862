/* eslint-disable no-case-declarations */
/* eslint-disable camelcase */
import React from "react";

import InvalidIcon from "@mui/icons-material/HelpOutlineOutlined";
import StopIcon from "@mui/icons-material/DangerousOutlined";
import ExpireIcon from "@mui/icons-material/EventBusyOutlined";
import CheckIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ClockIcon from "@mui/icons-material/UpdateOutlined";

import OutPointCard from "library/surface/OutPointCard";
import BodyText from "library/text/body/BodyText";
import SubHead from "library/text/headers/SubHead";
import { strpdate } from "utils/data/dates";

const styles = {
  card: {
    position: "absolute",
    right: 0,
    top: "40px",
    width: "400px",
    height: "auto",
    maxHeight: "600px",
    overflowY: "scroll",
  },
  notificationContainer: {
    padding: "13px 10px",
    display: "flex",
    flexDirection: "row",
    borderBottom: "1px solid #E4E8EE",
    position: "relative",
  },
  headerDiv: {
    padding: "13px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  readDot: {
    position: "absolute",
    height: "5px",
    width: "5px",
    backgroundColor: "#90CDF4",
    border: "1px solid #4299E1",
    borderRadius: "50%",
    left: "6px",
    top: "6px",
  },
};

function getNotificationText(notificationType, notificationData) {
  let text = null;
  switch (notificationType) {
    case "boost_action":
      text = (
        <>
          {"You boosted "}
          <b>{notificationData.adset_name}</b>
          {" by "}
          <b>${notificationData.boost_amount}</b>
        </>
      );
      break;
    case "extend_action":
      const extendDateObj = strpdate(notificationData.boost_end);
      const extendDateString = extendDateObj.toLocaleString("default", {
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      text = (
        <>
          {"A boost for "}
          <b>{notificationData.adset_name}</b>
          {" was extended until "}
          <b>{extendDateString}</b>
        </>
      );
      break;
    case "boost_expired":
      const expireDateObj = strpdate(notificationData.boost_end);
      const expireDateString = expireDateObj.toLocaleString("default", {
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      text = (
        <>
          {"A boost for "}
          <b>{notificationData.adset_name}</b>
          {" has expired on "}
          <b>{expireDateString}</b>
        </>
      );
      break;
    case "stop_action":
      const stopDateObj = strpdate(notificationData.stop_time);
      const stopDateString = stopDateObj.toLocaleString("default", {
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      text = (
        <>
          {"A boost for "}
          <b>{notificationData.adset_name}</b>
          {" was manually stopped on "}
          <b>{stopDateString}</b>
        </>
      );
      break;
    case "boost_invalidated":
      const invalidDateObj = strpdate(notificationData.boost_end);
      const invalidDateString = invalidDateObj.toLocaleString("default", {
        month: "long",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
      text = (
        <>
          {"A boost for "}
          <b>{notificationData.adset_name}</b>
          {" was found to be invalid on "}
          <b>{invalidDateString}</b>
        </>
      );
      break;
    default:
      text = <div />;
      break;
  }
  return text;
}

function NotificationBadge({ notificationType }) {
  let backgroundColor = null;
  let lineColor = null;
  let Icon = null;

  switch (notificationType) {
    case "boost_action":
      backgroundColor = "#EBFFFD";
      lineColor = "#00856E";
      Icon = CheckIcon;
      break;
    case "extend_action":
      backgroundColor = "#FFF3D9";
      lineColor = "#872F00";
      Icon = ClockIcon;
      break;
    case "boost_expired":
      backgroundColor = "#F5F5F5";
      lineColor = "black";
      Icon = ExpireIcon;
      break;
    case "stop_action":
      backgroundColor = "#FFECEF";
      lineColor = "#89001A";
      Icon = StopIcon;
      break;
    case "boost_invalidated":
      backgroundColor = "#ffeac9";
      lineColor = "#965a00";
      Icon = InvalidIcon;
      break;
    default:
      Icon = CheckIcon;
      break;
  }

  return (
    <div
      style={{
        display: "flex",
        flex: "0 0 30px",
        backgroundColor,
        borderRadius: 100,
        width: "30px",
        height: "30px",
        lineHeight: "30px",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "10px",
      }}
    >
      <Icon sx={{ color: lineColor }} />
    </div>
  );
}

export default function NotificationsMenu({
  notifications,
  handleReadNotifications,
}) {
  return (
    <OutPointCard sx={styles.card}>
      <div style={styles.headerDiv}>
        <SubHead sx={{ fontSize: "15px" }} color="secondary">
          Notifications
        </SubHead>
        <SubHead
          onClick={handleReadNotifications}
          sx={{
            fontSize: "15px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
          }}
          color="secondary"
        >
          Mark all as read{" "}
          <CheckIcon sx={{ fontSize: "17px", marginLeft: "5px" }} />
        </SubHead>
      </div>
      {notifications.map(
        ({ created_at, notification_data, notification_type, read_time }) => {
          const dateObj = strpdate(created_at);
          const date = dateObj.toLocaleString("default", {
            month: "long",
            day: "numeric",
          });
          const time = dateObj.toLocaleString("default", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });
          const dateString = `${date} at ${time}`;

          return (
            <div style={styles.notificationContainer}>
              {!read_time && <div style={styles.readDot} />}
              <NotificationBadge notificationType={notification_type} />
              <div>
                <BodyText sx={{ marginBottom: "15px" }}>
                  {getNotificationText(notification_type, notification_data)}
                </BodyText>
                <BodyText color="secondary">{dateString}</BodyText>
              </div>
            </div>
          );
        },
      )}
    </OutPointCard>
  );
}
