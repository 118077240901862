import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import { STATUS } from "utils/enums";

export const getJesterData = createAsyncThunk("asyncRedux/jester", async () => {
  return makeAuthenticatedGetRequest("integrations");
});

export const getMetadataFields = createAsyncThunk(
  "asyncRedux/metadataFields",
  async () => {
    return makeAuthenticatedGetRequest("metadata_fields");
  },
);

const jesterSlice = createSlice({
  name: "jesterSlice",
  initialState: {
    data: null,
    status: null,
    fields: {},
  },
  reducers: {
    jesterLogOut: (state, _) => {
      state.data = null;
      state.status = null;
      state.fields = {};
    },
  },
  extraReducers: {
    [getJesterData.pending]: (state, _) => {
      state.status = STATUS.LOADING;
    },
    [getJesterData.fulfilled]: (state, { payload }) => {
      state.data = payload || {};
      state.status = STATUS.SUCCESS;
    },
    [getJesterData.rejected]: (state, _) => {
      state.status = STATUS.FAILED;
    },

    [getMetadataFields.pending]: (state, _) => {
      state.fields.status = STATUS.LOADING;
    },
    [getMetadataFields.fulfilled]: (state, { payload }) => {
      state.fields.data = payload;
      state.fields.status = STATUS.SUCCESS;
    },
    [getMetadataFields.rejected]: (state, _) => {
      state.fields.status = STATUS.FAILED;
    },
  },
});

export const { jesterLogOut } = jesterSlice.actions;

export default jesterSlice.reducer;
