import React from "react";

import MetricDisplay from "library/display/MetricDisplay";
import OutPointCard from "library/surface/OutPointCard";

const styles = {
  card: {
    padding: "20px",
    height: "auto",
  },
};

export default function ReportPageCard({ title, body }) {
  return (
    <OutPointCard sx={styles.card}>
      <MetricDisplay keyText={title} valueText={body} />
    </OutPointCard>
  );
}
