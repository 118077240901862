import React from "react";

import LogoChip from "pages/connections/components/LogoChip";
import SubHeadLight from "library/text/headers/SubHeadLight";
import OutPointCard from "library/surface/OutPointCard";
import { THEME } from "assets/palette";
import AddCredentialsButton from "./AddCredentialsButton";

const styles = {
  card: {
    boxShadow: "none",
    borderStyle: "solid",
    position: "relative",
    borderColor: "#E3E6EC",
    borderWidth: "1px",
  },
  hoverable: {
    "&:hover": {
      borderColor: THEME.primary.main,
      borderWidth: "1px",
    },
  },
  topContainer: {
    padding: "12px",
  },
  bottomContainer: {
    paddingLeft: "12px",
    position: "absolute",
    bottom: 0,
    left: 0,
    height: "60px",
    width: "100%",
    borderTop: "1px solid #E3E6EC",
    display: "flex",
    alignItems: "center",
  },
  addButton: {
    color: "grey",
    width: "75px",
  },
  logoChip: {
    marginBottom: "10px",
  },
};

export default function PlatformCardTemplate({
  channel,
  whereFrom,
  logo,
  tokenType,
  height = "175px",
}) {
  return (
    <OutPointCard sx={{ ...styles.card, ...styles.hoverable, height }}>
      <div style={styles.topContainer}>
        <LogoChip sx={styles.logoChip} logo={logo} channel={channel} />
        <SubHeadLight sx={{ textTransform: "capitalize" }}>
          {channel}
        </SubHeadLight>
      </div>
      <div style={styles.bottomContainer}>
        <AddCredentialsButton
          tokenType={tokenType}
          whereFrom={whereFrom}
          channel={channel}
        />
      </div>
    </OutPointCard>
  );
}
