import AmazonLogo from "assets/small logos/Amazon.png";
import BingAdsLogo from "assets/logos/bing-ads.png";
import FacebookLogo from "assets/small logos/Meta.jpeg";
import GoogleAdsLogo from "assets/small logos/Google-Ads.png";
import GoogleAnalyticsLogo from "assets/small logos/Google-Analytics.png";
import InstagramLogo from "assets/small logos/instagram.png";
import PinterestLogo from "assets/small logos/Pinterest.png";
import ShopifyLogo from "assets/small logos/Shopify.png";
import SnapchatLogo from "assets/small logos/Snap.png";
import SpotifyAdsLogo from "assets/small logos/Spotify.png";
import StripeLogo from "assets/small logos/Stripe.png";
import TiktokLogo from "assets/small logos/TikTok.png";
import TaboolaLogo from "assets/small logos/taboola.png";
import GoogleLogo from "assets/small logos/google.png";
import YoutubeLogo from "assets/small logos/youtube.png";
import TwitterLogo from "assets/small logos/Twitter.png";
import FallbackLogo from "assets/small logos/FallbackLogo.svg";
// App bar logos
import FrontrunnrLogo from "assets/frontrunnr_logo.png";
// Navbar logos
import CampaignsLogoSmall from "assets/icons/navbar/campaigns.svg";
import ChannelDiscoverySmall from "assets/icons/navbar/channel_discovery.svg";
import HomeSmall from "assets/icons/navbar/home.svg";
import InsightsSmall from "assets/icons/navbar/insights.svg";
import IntegrationsSmall from "assets/icons/navbar/integrations.svg";
import RecommendationsSmall from "assets/icons/navbar/recommendations.svg";
import UploadSmall from "assets/icons/navbar/Upload.svg";

export const logosAll = {
  channel: {
    amazon: AmazonLogo,
    bing: BingAdsLogo,
    facebook: FacebookLogo,
    google: GoogleLogo,
    google_ads: GoogleAdsLogo,
    google_analytics: GoogleAnalyticsLogo,
    instagram: InstagramLogo,
    pinterest: PinterestLogo,
    shopify: ShopifyLogo,
    snapchat: SnapchatLogo,
    spotify: SpotifyAdsLogo,
    stripe: StripeLogo,
    taboola: TaboolaLogo,
    tiktok: TiktokLogo,
    youtube: YoutubeLogo,
    twitter: TwitterLogo,
  },
  outpointLogo: FrontrunnrLogo,
  navbarCampaigns: CampaignsLogoSmall,
  navbarChannelDiscovery: ChannelDiscoverySmall,
  navbarHome: HomeSmall,
  navbarInsights: InsightsSmall,
  navbarIntegrations: IntegrationsSmall,
  navbarRecommendations: RecommendationsSmall,
  navbarUpload: UploadSmall,
  fallbackLogo: FallbackLogo,
};

export const getChannelLogo = (channel = "") => {
  const lowercaseChannel = channel.toLowerCase();
  const channelLogos = logosAll.channel || {};
  const logo = channelLogos[lowercaseChannel];

  if (logo) return logo;

  if (lowercaseChannel.includes("google")) {
    if (lowercaseChannel.includes("ads")) {
      return channelLogos.google_ads;
    }

    if (lowercaseChannel.includes("analytics")) {
      return channelLogos.google_analytics;
    }

    return channelLogos.google;
  }

  // return fallback logo if channel does not exist
  return logosAll.fallbackLogo;
};

export const getLogosGeneric = (name = "") => {
  const logo = logosAll[name];

  if (logo) return logo;

  // return fallback logo if asset is not resolved
  return logosAll.fallbackLogo;
};
