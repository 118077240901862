import React, { useState } from "react";

import { useDispatch } from "react-redux";

import { Grid } from "@mui/material";
import Warning from "@mui/icons-material/WarningRounded";
import BodyText from "library/text/body/BodyText";
import PrimaryButton from "library/buttons/PrimaryButton";
import Hyperlink from "library/text/body/Hyperlink";
import TextFieldSimple from "library/form/OutPointTextField";
import IconText from "library/containers/IconText";
import Label from "library/text/body/LabelText";
import { isValidEmail } from "utils/data/strings";
import { RED, THEME } from "assets/palette";
import OutPointCard from "library/surface/OutPointCard";

import { addMessage } from "redux/snackbarSlice";
import { signUpUser } from "utils/authentication";
import PasswordFields, {
  getPasswordConditions,
  PasswordCheckField,
} from "./PasswordFields";

const styles = {
  form: {
    paddingRight: "5%",
  },
  textBox: {
    marginBottom: "20px",
  },
  fullTextBox: {
    width: "100%",
  },
  halfTextBoxFirst: {
    width: "47.5%",
    marginRight: "5%",
  },
  halfTextBoxSecond: {
    width: "47.5%",
  },
  formField: {
    paddingTop: "3px",
    marginBottom: "15px",
  },
  primary: {
    color: THEME.primary.main,
  },
  progressBar: {
    width: "100%",
  },
  checkIcon: {
    fontSize: "0.95rem",
    marginRight: "0.2em",
  },
  hyperlink: {
    color: THEME.primary.main,
    fontWeight: "600",
    textDecoration: "none",
  },
  errorCard: {
    backgroundColor: "#FEE8E8",
    padding: "16px",
    margin: "20px 0",
  },
  warningIcon: {
    fontSize: "32px",
    color: RED,
    marginRight: "16px",
  },
};

const inputFields = [
  {
    id: "firstname",
    name: "First name",
    moreStyles: styles.halfTextBoxFirst,
    type: "text",
  },
  {
    id: "lastname",
    name: "Last name",
    moreStyles: styles.halfTextBoxSecond,
    type: "text",
  },
  {
    id: "company",
    name: "Company name",
    moreStyles: styles.fullTextBox,
    type: "text",
  },
  {
    id: "email",
    name: "Work email address",
    moreStyles: styles.fullTextBox,
    type: "text",
  },
];

const setUserInputState = (setState, id, data) => {
  setState((prev) => {
    const newState = { ...prev };
    newState[id] = data;
    return newState;
  });
};
const onSubmit = (
  event,
  dispatch,
  userInputData,
  canSubmit,
  setEmail,
  setFirstName,
  setSignUpSuccessful,
  setFocussed,
  setErrorCard,
) => {
  event.preventDefault();
  setFocussed(null);
  setErrorCard({});

  if (!canSubmit) return;

  signUpUser(
    userInputData.firstname,
    userInputData.lastname,
    userInputData.email,
    userInputData.password,
  )
    .then(() => {
      setEmail(userInputData.email);
      setFirstName(userInputData.firstname);
      setSignUpSuccessful(true);
    })
    .catch((error) => {
      dispatch(addMessage({ message: error }));
    });
};

function ErrorCard({ title, body }) {
  return (
    <OutPointCard sx={styles.errorCard}>
      <IconText>
        <Warning sx={styles.warningIcon} />
        <div>
          <Label sx={{ marginBottom: "5px" }}>{title}</Label>
          <BodyText>{body}</BodyText>
        </div>
      </IconText>
    </OutPointCard>
  );
}

function SignupForm({ setSignUpSuccessful, setEmail, setFirstName }) {
  const dispatch = useDispatch();
  const [userInputData, setUserInputData] = useState({});
  const [userInputError, setUserInputError] = useState({});
  const [errorCard, setErrorCard] = useState({});
  const [focussed, setFocussed] = useState();

  const validEmail = isValidEmail(userInputData.email);

  const passwordConditions = getPasswordConditions(userInputData.password);

  const canSubmit =
    inputFields.reduce((prev, { id }) => {
      const hasText = userInputData[id]?.length > 0;
      return hasText && prev;
    }, true) &&
    passwordConditions.every(({ condition }) => condition) &&
    validEmail &&
    userInputData.password === userInputData.confirmPassword;

  return (
    <form
      style={styles.form}
      onSubmit={(event) => {
        event.preventDefault();
        onSubmit(
          event,
          dispatch,
          userInputData,
          canSubmit,
          setEmail,
          setFirstName,
          setSignUpSuccessful,
          setFocussed,
          setErrorCard,
        );
      }}
    >
      <Grid>
        <BodyText sx={{ marginBottom: "20px" }} color="secondary">
          Already have an account?{" "}
          <Hyperlink sx={styles.hyperlink} where="/login">
            Log in
          </Hyperlink>
        </BodyText>

        {errorCard?.title && <ErrorCard {...errorCard} />}

        {inputFields.map(({ id, name, type, moreStyles, ...fields }) => (
          <TextFieldSimple
            key={name}
            name={name}
            type={type}
            sx={{ ...styles.textBox, ...moreStyles }}
            {...fields}
            onFocus={() => setFocussed(id)}
            onBlur={() => {
              if (!userInputData[id])
                setUserInputState(setUserInputError, id, `${name} is required`);
              if (id === "email") {
                if (userInputData.email && !validEmail)
                  setUserInputState(
                    setUserInputError,
                    "email",
                    "Not a valid email address.",
                  );
              }
            }}
            onChange={(text) => {
              setUserInputState(setUserInputData, id, text);
              if (text) setUserInputState(setUserInputError, id, "");
            }}
            error={Boolean(userInputError[id])}
            errorMsg={userInputError[id]}
          />
        ))}

        <div style={styles.textBox}>
          <TextFieldSimple
            id="password"
            name="Password"
            type="password"
            sx={styles.fullTextBox}
            onFocus={() => setFocussed("password")}
            onChange={(text) => {
              setUserInputState(setUserInputData, "password", text);
            }}
          />
          {focussed === "password" && (
            <PasswordFields password={userInputData.password} />
          )}
        </div>

        <div style={styles.textBox}>
          <TextFieldSimple
            name="Confirm password"
            id="confirmPassword"
            type="password"
            sx={styles.fullTextBox}
            onFocus={() => setFocussed("confirmPassword")}
            onChange={(text) => {
              setUserInputState(setUserInputData, "confirmPassword", text);
            }}
          />
          {userInputData.confirmPassword &&
            userInputData.password === userInputData.confirmPassword && (
              <div style={{ margin: "10px 0" }}>
                <PasswordCheckField condition text="Passwords match" />
              </div>
            )}
        </div>

        <BodyText color="secondary" sx={styles.terms}>
          By continuing you agree to our{" "}
          <Hyperlink
            where="https://www.outpoint.app/terms-of-service"
            newPage
            sx={styles.hyperlink}
          >
            terms
          </Hyperlink>{" "}
          and{" "}
          <Hyperlink
            where="https://www.outpoint.app/privacy-policy"
            newPage
            sx={styles.hyperlink}
          >
            privacy policy.
          </Hyperlink>
        </BodyText>

        <PrimaryButton
          type="submit"
          sx={{ marginTop: "15px", marginBottom: "25px" }}
          size="large"
          disabled={!canSubmit}
        >
          Create Account
        </PrimaryButton>
      </Grid>
    </form>
  );
}

export default SignupForm;
