import React from "react";

const styles = {
  iconContainer: {
    display: "flex",
    alignItems: "center",
  },
};

function IconText({ children, sx = {}, ...rest }) {
  return (
    <span {...rest} style={{ ...styles.iconContainer, ...sx }}>
      {children}
    </span>
  );
}

export default IconText;
