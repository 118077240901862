/**
 * Contains helpers relevant to taxonomy generation.
 * */
import { isEmpty } from "lodash";

/// NOTE: as of v3.0 every field will have a codified key, so there won't be instances of
//        multiple free-form (i.e. unbounded) attributes that are separated by their own delimiter
const DELIM_SUBSTRING_SUBSTRING = "_";
const DELIM_ATTRNAME_ATTRVAL = "~";
const DELIM_UNBOUNDEDVAL_UNBOUNDEDVAL = "-";

export function isAttributeNAN(value) {
  if (typeof value === "string") {
    return ["NAN", "NA"].includes(value);
  }

  if (typeof value === "number") return false;

  return true;
}

/**
 *
 * Based on the known taxonomy rules, converts a set of attributes into a single string that represents those
 * attributes.
 *
 * @param{Object} relevantOptions - describes the structure of attributes that are relevant for the name generation.
 *                                  This information is originally defined in the internal spread sheets (taxonomy_lookups.csv).
 * @param{Object} formAttributes - contains information from the taxonomy form that is represents the currently-selected user input.
 *
 * Pre-conditions:
 * 1. the keys in formAttributes can be found in the set of keys for relevantOptions.
 * */
const encodeTaxonomyDetails = (relevantOptions, formAttributes) => {
  const canNameBeGenerated =
    formAttributes &&
    relevantOptions &&
    !isEmpty(relevantOptions) &&
    !isEmpty(formAttributes);
  if (!canNameBeGenerated) {
    return null;
  }

  const substrings = relevantOptions.map((option) => {
    const {
      code: codifiedAttributeName,
      name: attributeName,
      options: allOptions,
      is_bounded_value: isBoundedValue,
    } = option;

    const { currentValue } = formAttributes[attributeName];

    let codifiedValue = isBoundedValue
      ? allOptions[currentValue]
      : currentValue; /// if it's unbounded, then its freeform and doesn't have a value that can be codified

    /// SPECIAL CASE: there's a duplicate field which is defined as a free-form but actually it's the same value as creative_exec_size_or_duration
    if (attributeName === "size_or_duration") {
      const referenceAttrName = "creative_exec_size_or_duration";
      const referenceOption = relevantOptions.find(
        (possibleOption) => possibleOption.name === referenceAttrName,
      );
      const creativeExecSizeOrDurationVal =
        formAttributes[referenceAttrName].currentValue;
      codifiedValue = referenceOption.options[creativeExecSizeOrDurationVal];
    }

    /// So, based on the most recent taxonomy as of Feb 1 2024, there's a contiguous set of unbounded values that don't have an attribute code. study this:AT~NAT_CT~NAT_LG~AN_AG~Uber_SZ~1000x540_CV~myCreativeName-myCopyDesc-1000x540-12346_LD~0626_DI~na
    //  in this case, -myCopyDesc-1000x540-12346 is the contiguous substring
    const isPartOfContiguousUnboundedAttrs =
      !codifiedAttributeName || isAttributeNAN(codifiedAttributeName);
    let substring = null;
    if (isPartOfContiguousUnboundedAttrs) {
      substring = `${DELIM_UNBOUNDEDVAL_UNBOUNDEDVAL}${codifiedValue}`;
    } else {
      substring = `${codifiedAttributeName}${DELIM_ATTRNAME_ATTRVAL}${codifiedValue}`;
    }

    return [isPartOfContiguousUnboundedAttrs, substring];
  });

  const generatedName = substrings.reduce(
    (accum, [isPartOfContiguousUnboundedAttrs, substring], idx) => {
      let updated = accum;
      if (isPartOfContiguousUnboundedAttrs) {
        updated += substring;
      } else {
        // handle normally:
        const prefix = idx === 0 ? "" : DELIM_SUBSTRING_SUBSTRING;
        updated += `${prefix}${substring}`;
      }

      return updated;
    },
    "",
  );

  return generatedName;
};

export default encodeTaxonomyDetails;
