const { useState, useCallback } = require("react");

const useToggle = (initialState = false) => {
  const [state, setState] = useState(initialState);
  const toggleOn = useCallback(() => setState(true), []);
  const toggleOff = useCallback(() => setState(false), []);
  const toggle = useCallback(() => {
    if (state) return toggleOff();

    return toggleOn();
  }, [state]);

  return {
    state,
    toggleOn,
    toggleOff,
    toggle,
  };
};

export default useToggle;
