import React from "react";
import SubHead from "library/text/headers/SubHead2";
import BodyText from "library/text/body/BodyText";
import { Grid } from "@mui/material";

function MetricRow({ metric, value }) {
  return (
    <Grid
      item
      xs={6}
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <SubHead style={{ marginRight: "3px" }}>{metric}:</SubHead>
      <BodyText>{value}</BodyText>
    </Grid>
  );
}

function CompactMetricDisplay({ cpe, spend, enr }) {
  return (
    <Grid container spacing={0}>
      <MetricRow metric="CPE" value={`$${cpe.toFixed(2)}`} />
      <MetricRow metric="USD" value={`$${spend.toFixed(2)}`} />
      <MetricRow metric="ENR" value={`${enr.toFixed(2)}%`} />
    </Grid>
  );
}

export default CompactMetricDisplay;
