import React, { useState } from "react";

import Header1 from "library/text/headers/Header1";
import Header4 from "library/text/headers/Header4";
import SubHead from "library/text/headers/SubHeadLight";
import useUrlPrettifier from "hooks/useUrlPrettifier";
import PlatformList from "pages/connections/components/PlatformList";
import { useSelector } from "react-redux";
import OutPointButtonGroup from "library/buttons/OutPointButtonGroup";
import PlatformCards from "./components/PlatformCards";

const styles = {
  logo: {
    height: "30px",
  },
  headerMain: {
    marginBottom: "5px",
  },
  smallHeader: {
    marginTop: "50px",
    marginBottom: "5px",
  },
  subhead: {
    marginBottom: "24px",
  },
};

const subheaderText = {
  ad: "Easily create ad campaigns and track your data by connecting the apps you use to track and measure revenue and ad spend.",
  page: "Track and manage your posts by connecting apps that you have page access for.",
};

function ConnectionsPage() {
  const [tokenType, setTokenType] = useState("ad");
  const agileSocialFlag = useSelector(
    (state) => state.features?.flags?.agile_social,
  );

  useUrlPrettifier();

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Header1
            sx={styles.headerMain}
          >{`${tokenType} Integrations`}</Header1>
          <SubHead sx={styles.subhead} color="secondary">
            {subheaderText[tokenType]}
          </SubHead>
        </div>
        {!!agileSocialFlag && (
          <OutPointButtonGroup
            curState={tokenType}
            values={["ad", "page"]}
            setter={setTokenType}
          />
        )}
      </div>
      <PlatformList whereFrom="connections" tokenType={tokenType} />
      <Header4 sx={styles.smallHeader}>Available apps</Header4>
      <PlatformCards whereFrom="connections" tokenType={tokenType} />
    </>
  );
}

export default ConnectionsPage;
