/* eslint-disable camelcase */
import React, { useMemo, useState, useEffect } from "react";

import { Grid } from "@mui/material";
import useUrlPrettifier from "hooks/useUrlPrettifier";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";

import BoostHistoryIcon from "@mui/icons-material/RestoreRounded";

import Header1 from "library/text/headers/Header1";
import OutPointCSVDownloader from "library/buttons/CSVDownloader";
import PressableText from "library/buttons/PressableText";
import { capitalize } from "lodash";
import OutPointDropdown from "library/buttons/OutPointDropdown";
import { strpdate } from "utils/data/dates";
import { addCommasToDecimal, addCommasToNumber } from "utils/data/strings";
import {
  getSelectionsFromLocalStorage,
  setSelection,
} from "redux/homeFiltersSlice";
import { makeAuthenticatedPostRequest } from "utils/backend-api";
import { formatKpiForDisplay } from "utils/data/formatters";

import BodyText from "library/text/body/BodyText";
import OutPointToggle from "library/buttons/OutPointToggle";
import BasicDataGrid from "library/display/BasicDataGrid";
import HomePageCard from "./components/HomePageCard";
import gridColumns from "./data/gridColumns";

const styles = {
  toolbar: {
    margin: "20px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selector: {
    marginRight: "20px",
  },
  button: {
    marginTop: "20px",
    marginBottom: "20px",
    fontWeight: "bold",
    width: "250px",
  },
};

function HomePage() {
  useUrlPrettifier();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSelectionsFromLocalStorage());
  }, []);

  const [columnsToInclude, setColumnsToInclude] = useState([]);
  const actions = ["All", "Boostable", "Extendable"];
  const [activeAdsets, setActiveAdsets] = useState(0);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalEngagements, setTotalEngagements] = useState(0);
  const [boostedContent, setBoostedContent] = useState(0);

  const ingestionData = useSelector((state) => state.ingestion.data) || [];

  const [enablePrediction, setEnablePrediction] = useState(false);
  const [rankData, setRankData] = useState([]);
  useEffect(() => {
    const getRankData = async () => {
      try {
        const response = await makeAuthenticatedPostRequest("rank", {
          enablePrediction,
          boostPerAdset: ingestionData.reduce(
            (obj, row) => ({
              ...obj,
              [row.id]: row.boost_amount,
            }),
            {},
          ),
        });
        setRankData(response?.data?.intra_channel_ranking?.target || []);
      } catch (e) {
        // Note: Error is already logged by makeAuthenticatedRequest
      }
    };
    getRankData();
  }, [enablePrediction]);

  useEffect(() => {
    gridColumns.forEach((column) => {
      if (column.field === "adset_name") {
        column.width =
          ingestionData.length === 0
            ? 180
            : ingestionData.reduce(
                (prevMax, currentRow) =>
                  currentRow.adset_name.length > prevMax
                    ? currentRow.adset_name.length
                    : prevMax,
                0,
              ) *
                7 +
              50;
      } else if (column.field === "campaign_name") {
        column.width =
          ingestionData.length === 0
            ? 180
            : ingestionData.reduce(
                (prevMax, currentRow) =>
                  currentRow.campaign_name.length > prevMax
                    ? currentRow.campaign_name.length
                    : prevMax,
                0,
              ) *
                7 +
              20;
      } else if (column.field === "adset_brand") {
        column.minWidth = 180;
      } else {
        column.minWidth = 120;
      }
    });
  }, [ingestionData]);

  const {
    platformSelection,
    adsetBrandSelection,
    credentialBrandSelection,
    kpiSelection,
    actionSelection,
    displaySelection,
    statusSelection,
  } = useSelector((state) => state.homeFilters);
  const brands = useSelector((state) => state.ingestion.brands) || [];
  const metricsData = useSelector((state) => state.metrics)?.data || [];
  const optionalKpis = [
    ...new Set(metricsData.map(({ metric }) => formatKpiForDisplay(metric))),
  ];
  const optionalColumns = {};
  metricsData.forEach((item) => {
    const metricName = formatKpiForDisplay(item.metric);
    if (!(item.metric in optionalColumns)) {
      optionalColumns[metricName] = {
        headerName: metricName,
        field: item.metric,
        headerAlign: "center",
        headerClassName: "header",
        minWidth: metricName.length * 10,
        renderCell: ({ value }) =>
          value !== null
            ? `${item.unit === "$" ? item.unit : ""}${
                item.unit ? addCommasToDecimal(value) : addCommasToNumber(value)
              }${item.unit === "%" ? item.unit : ""}`
            : "-",
      };
    }
  });

  const boostData = useSelector((state) => state.boostReport.data) || [];

  const groupedData = useMemo(() => ({
    recommendations: ingestionData.filter(({ boost_amount }) => boost_amount),
    actioned: ingestionData.filter(
      ({ most_recent_boost_end_time }) =>
        strpdate(most_recent_boost_end_time) > Date.now(),
    ),
    "all items": ingestionData,
  }));

  const filteredData = groupedData[displaySelection].filter(
    ({
      channel,
      adset_brand,
      target_metric,
      boost_amount,
      status,
      reached_threshold,
      brand,
    }) => {
      if (
        platformSelection === "All" &&
        actionSelection === "All" &&
        adsetBrandSelection === "All" &&
        credentialBrandSelection === "All" &&
        kpiSelection === "All" &&
        statusSelection === "All"
      ) {
        return true;
      }

      let includeRow = true;
      if (platformSelection !== "All") {
        includeRow = includeRow && platformSelection === channel;
      }
      if (adsetBrandSelection !== "All") {
        includeRow = includeRow && adset_brand === adsetBrandSelection;
      }
      if (credentialBrandSelection !== "All") {
        includeRow = includeRow && brand?.includes(credentialBrandSelection);
      }
      if (kpiSelection !== "All") {
        includeRow =
          includeRow && kpiSelection === formatKpiForDisplay(target_metric);
      }
      if (actionSelection !== "All") {
        if (actionSelection === "Boostable") {
          includeRow = includeRow && !!boost_amount;
        } else if (actionSelection === "Extendable") {
          includeRow = includeRow && !boost_amount && !reached_threshold;
        }
      }
      if (statusSelection !== "All") {
        includeRow = includeRow && statusSelection.toUpperCase() === status;
      }

      return includeRow;
    },
  );

  const maxScore = filteredData.reduce((max, row) => {
    if (rankData[row.id]?.score > max) return rankData[row.id]?.score;
    return max;
  }, 0);

  const displayData = filteredData.map((row) => {
    const newRow = { ...row };
    newRow.target_metric_value = row[row.target_metric];
    newRow.rank =
      ((rankData[row.id]?.score ?? 0) / Math.max(maxScore, 1)) * 100;
    newRow.result_value = row[row.result];
    return newRow;
  });

  useEffect(() => {
    const filteredBoostData = boostData.filter(({ api_adset_id }) =>
      displayData.find((row) => row.api_adset_id === api_adset_id),
    );
    setActiveAdsets(
      displayData.reduce(
        (acc, { status }) => (status === "ACTIVE" ? acc + 1 : acc),
        0,
      ),
    );
    setTotalInvestment(
      filteredBoostData.reduce(
        (acc, { boost_amount }) => boost_amount + acc,
        0,
      ),
    );
    setTotalEngagements(
      displayData.reduce((acc, { engagements }) => acc + engagements, 0),
    );
    setBoostedContent(filteredBoostData?.length?.toLocaleString());
  }, [displayData]);

  const channels = Array.from(
    new Set(ingestionData.map(({ channel }) => channel)),
  );
  const kpis = Array.from(
    new Set(
      ingestionData.map(({ target_metric }) =>
        formatKpiForDisplay(target_metric),
      ),
    ),
  );

  const cardData = [
    {
      title: "Active Adsets",
      body: addCommasToNumber(activeAdsets),
    },
    {
      title: "Total Frontrunnr Investment",
      body: `$${addCommasToDecimal(totalInvestment)}`,
    },
    {
      title: "Total engagements",
      body: addCommasToNumber(totalEngagements),
    },
    {
      title: "Boosted content",
      body: boostedContent,
    },
  ];

  const displayHeaders = [
    ...gridColumns.slice(0, 10),
    ...columnsToInclude.map((column) => optionalColumns[column]),
    ...gridColumns.slice(10, gridColumns.length),
  ];

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Header1 sx={styles.headerMain}>Home</Header1>
        <PressableText
          onClick={() => navigate("/report")}
          sx={{
            fontSize: "18px",
            color: "#00705B",
            fontWeight: 600,
            display: "flex",
            alignItems: "center",
          }}
        >
          <BoostHistoryIcon sx={{ marginRight: "5px" }} />
          Boost History
        </PressableText>
      </div>

      <Grid
        container
        spacing={3}
        sx={{ width: "100%", paddingTop: "20px", marginBottom: "80px" }}
      >
        {cardData.map((data) => (
          <Grid key={`grid-${data.title}`} item xs={3}>
            <HomePageCard key={data.title} {...data} />
          </Grid>
        ))}
      </Grid>
      <div style={{ display: "flex", direction: "row" }}>
        {Object.keys(groupedData).map((group) => {
          return (
            <PressableText
              key={group}
              sx={{ fontSize: "20px", marginRight: "10px" }}
              onClick={() =>
                dispatch(
                  setSelection({
                    selectionName: "display",
                    selectionValue: group,
                  }),
                )
              }
              active={displaySelection === group}
            >
              {capitalize(group)}
              {` (${groupedData[group].length})`}
            </PressableText>
          );
        })}
      </div>

      <Grid container sx={styles.toolbar}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <OutPointDropdown
            sx={styles.selector}
            menuItems={optionalKpis}
            selectedValue={columnsToInclude}
            onChange={(values) => setColumnsToInclude(values)}
            leading="Columns: "
            isSentenceCaseMenu={false}
            multiple
          />
          <OutPointDropdown
            sx={styles.selector}
            menuItems={["All", "Active", "Paused"]}
            selectedValue={statusSelection}
            onChange={(value) =>
              dispatch(
                setSelection({
                  selectionName: "status",
                  selectionValue: value,
                }),
              )
            }
            leading="Status: "
          />
          <OutPointDropdown
            sx={styles.selector}
            menuItems={["All", ...channels]}
            selectedValue={platformSelection}
            onChange={(value) =>
              dispatch(
                setSelection({
                  selectionName: "platform",
                  selectionValue: value,
                }),
              )
            }
            leading="Platform: "
          />
          <OutPointDropdown
            sx={styles.selector}
            menuItems={["All", ...brands]}
            selectedValue={adsetBrandSelection}
            onChange={(value) =>
              dispatch(
                setSelection({
                  selectionName: "adsetBrand",
                  selectionValue: value,
                }),
              )
            }
            leading="Adset Brand: "
          />
          <OutPointDropdown
            sx={styles.selector}
            menuItems={["All", ...brands]}
            selectedValue={credentialBrandSelection}
            onChange={(value) =>
              dispatch(
                setSelection({
                  selectionName: "credentialBrand",
                  selectionValue: value,
                }),
              )
            }
            leading="Credential Brand: "
          />
          <OutPointDropdown
            sx={styles.selector}
            menuItems={["All", ...kpis]}
            selectedValue={kpiSelection}
            onChange={(value) =>
              dispatch(
                setSelection({
                  selectionName: "kpi",
                  selectionValue: value,
                }),
              )
            }
            leading="KPI: "
            isSentenceCaseMenu={false}
          />
          <OutPointDropdown
            sx={styles.selector}
            menuItems={actions}
            selectedValue={actionSelection}
            onChange={(value) =>
              dispatch(
                setSelection({
                  selectionName: "action",
                  selectionValue: value,
                }),
              )
            }
            leading="Action: "
          />
          <BodyText>Predicted rank:</BodyText>
          <OutPointToggle
            checked={enablePrediction}
            onChange={() => {
              setEnablePrediction(!enablePrediction);
            }}
          />
        </div>
        <OutPointCSVDownloader
          data={displayData}
          label="Download Data"
          filename="frontrunnrData"
          ButtonProps={{
            sx: styles.button,
            color: "green",
            variant: "outlined",
          }}
        />
      </Grid>

      <BasicDataGrid
        displayColumns={displayHeaders}
        displayRows={displayData}
        sx={{ border: "none" }}
      />
    </>
  );
}

export default HomePage;
