export const GREEN = "#66D064";
export const LIGHT_GREEN = "#80B362";
export const BG_GREEN = "#E5F5F0";

export const BRIGHT_YELLOW = "#FFE81E";
export const DARK_YELLOW = "#ffce44";
export const FAINT_YELLOW = "#FBF7EF";
export const BG_YELLOW = "#FFFAEE";

export const RED = "#F41D43";
export const LIGHT_RED = "#FF7276";
export const BG_RED = "#FEE8E8";

export const NEUTRAL = "#FCF7F8";
export const LIGHT_GREY = "#F9F9F9";
export const BRAND_GRAY_700 = "#606066";
export const BRAND_GRAY_100 = "#FAFAFC";
export const BORDER_GREY = "#FBFBFB";
export const RED_GREY = "#FCF7F8";

export const FAINT_BLUE = "#F4F3F7";
export const PERIWINKLE_BLUE = "#5F75EE";
export const PERIWINKLE_BLUE_LIGHT = `${PERIWINKLE_BLUE}1A`;
export const TEAL = "#167A81";

export const FEEDBACK_OR_WARNING = "#FFC453";
export const FEEDBACK_OR_ERROR_100 = "#FEE8E8";
export const FEEDBACK_OR_ERROR_900 = "#F41212";

export const FEEDBACK_ERROR_ERROR = "#D40808";
export const FEEDBACK_WARNING_WARNING = "#DE7A00";
export const FEEDBACK_SUCCESS = "#009F66";

export const THEME = {
  primary: {
    main: "#2B2B2B",
  },
  secondary: {
    main: "rgba(0, 0, 0, 0.6)",
  },
  inverseSecondary: {
    main: "rgba(255, 255, 255, 0.3)",
  },
  base: {
    main: "#FFFFFF",
  },
  grey: {
    light: "#FAFAFC",
    main: "#76757D",
  },
  blue: {
    main: "#3D31ED",
  },
  green: {
    main: "#027D58",
  },
};
