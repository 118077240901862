import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import { STATUS } from "utils/enums";

export const getNotificationsData = createAsyncThunk(
  "asyncRedux/notifications",
  async () => {
    return makeAuthenticatedGetRequest("notifications");
  },
);

const notificationsSlice = createSlice({
  name: "notificationsSlice",
  initialState: {
    data: null,
    status: null,
  },
  reducers: {
    notificationsLogOut: (state, _) => {
      state.data = null;
      state.status = null;
    },
    markAllNotificationsAsRead: (state, { _ }) => {
      state.data = state.data.map((notification) => {
        notification.read_time = Date.now();
        return notification;
      });
    },
  },
  extraReducers: {
    [getNotificationsData.pending]: (state, _) => {
      state.status = STATUS.LOADING;
    },
    [getNotificationsData.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.status = STATUS.SUCCESS;
    },
    [getNotificationsData.rejected]: (state, _) => {
      state.status = STATUS.FAILED;
    },
  },
});

export const { notificationsLogOut, markAllNotificationsAsRead } =
  notificationsSlice.actions;

export default notificationsSlice.reducer;
