import React from "react";
import {} from "@mui/material";
import BodyText from "library/text/body/BodyText";

export default function TableCellDate({ date }) {
  if (date === null) {
    return <>N/A</>;
  }

  const dateObj = new Date(Date.parse(date));
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();

  return (
    <>
      {dateObj.getFullYear()}-{month < 10 ? 0 : ""}
      {month}-{day < 10 ? 0 : ""}
      {day}
      <BodyText color="secondary" align="right">
        {dateObj.getHours() < 10 ? 0 : ""}
        {dateObj.getHours()}:{dateObj.getMinutes() < 10 ? 0 : ""}
        {dateObj.getMinutes()}
      </BodyText>
    </>
  );
}
