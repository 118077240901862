import React from "react";
import { Typography } from "@mui/material";
import { sentenceCase } from "utils/data/strings";

const styles = {
  title: {
    width: "auto",
    fontSize: "3rem",
    lineHeight: "3.75rem",
    fontWeight: "bold",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    letterSpacing: "-0.02em",
  },
};

export default function Title({
  text,
  sx = {},
  isSentenceCase = true,
  ...rest
}) {
  return (
    <Typography sx={{ ...styles.title, ...sx }} {...rest}>
      {isSentenceCase ? sentenceCase(text) : text}
    </Typography>
  );
}
