import React from "react";
import { Modal, Card, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const styles = {
  card: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    padding: "30px",
    maxHeight: "90%",
    overflowY: "auto",
    width: "600px",
  },
  closeModalButton: {
    position: "absolute",
    top: "20px",
    right: "20px",
  },
};

export default function OutPointModal({
  children,
  modalOpen,
  setModalOpen,
  sx = {},
  onClose = null,
  closeButton = true,
  ...rest
}) {
  const closeModal = onClose
    ? () => onClose(setModalOpen)
    : () => setModalOpen(false);

  return (
    <Modal
      open={modalOpen}
      onClose={closeModal}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Card sx={{ ...styles.card, ...sx }} {...rest}>
        {closeButton && (
          <IconButton
            sx={styles.closeModalButton}
            onClick={closeModal}
            disableRipple
          >
            <Close />
          </IconButton>
        )}

        {children}
      </Card>
    </Modal>
  );
}
