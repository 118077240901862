import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { STATUS } from "utils/enums";
import { makeAuthenticatedGetRequest } from "utils/backend-api";

export const getFeatures = createAsyncThunk("asyncRedux/features", async () => {
  return makeAuthenticatedGetRequest("features");
});

const initialState = {
  flags: null,
  status: null,
};

const featuresSlice = createSlice({
  name: "featuresSlice",
  initialState,
  reducers: {
    featuresLogOut: () => initialState,
  },
  extraReducers: {
    [getFeatures.pending]: (state) => {
      state.status = STATUS.LOADING;
    },
    [getFeatures.fulfilled]: (state, { payload }) => {
      state.flags = payload;
      state.status = STATUS.SUCCESS;
    },
    [getFeatures.rejected]: (state) => {
      state.status = STATUS.FAILED;
    },
  },
});

export const { featuresLogOut } = featuresSlice.actions;

export default featuresSlice.reducer;
