import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import useToggle from "hooks/useToggle";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { StyledEngineProvider, ThemeProvider } from "@mui/material";

import { React, useState, useCallback } from "react";

import { useDispatch, useSelector } from "react-redux";
import { logOut } from "utils/authentication";
import { getLogosGeneric } from "utils/resolveLogos";
import OPMenu from "library/form/OPMenu";
import PngContainer from "library/containers/PngContainer";
import { useNavigate } from "react-router-dom";
import redTheme from "themes/redTheme";
import NotificationsButton from "pages/appbar/NotificationsButton";

export default function OPAppBar({ pages }) {
  const appbarPages = { ...pages };
  const features = useSelector((state) => state.features.flags) || {};

  delete appbarPages.show_account;
  if (!features.agile_social) {
    delete appbarPages.show_posts;
    delete appbarPages.show_reporting;
  }

  const { state: menuState, toggleOff, toggle } = useToggle();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const profileClickHandler = (event = {}) => {
    toggle();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
    toggleOff();
  }, [anchorEl]);

  const handleTabChange = (event, value) => {
    navigate(value);
  };

  const menuOptions = [
    {
      display: "Account settings",
      onClick: () => {
        navigate("/account");
      },
    },
    {
      display: "Log out",
      onClick: () => {
        logOut(dispatch);
      },
      "data-cy": "appbar-logout",
    },
  ];

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={redTheme}>
        <Box sx={{ flexGrow: 1, height: "56px", padding: "0px" }}>
          <AppBar
            position="fixed"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.4)",
              backdropFilter: "blur(5px)",
              borderBottom: 1,
              borderColor: "#E5E4F2",
              boxShadow: "none",
              height: "56px",
              minHeight: "56px",
            }}
          >
            <Toolbar
              sx={{
                paddingBottom: "6px",
              }}
            >
              <PngContainer
                icon={getLogosGeneric("outpointLogo")}
                boxStyle={{ width: "205px", flexGrow: 1, marginBottom: "6px" }}
              />
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={window.location.pathname}>
                  <Box>
                    <TabList
                      onChange={handleTabChange}
                      aria-label="lab API tabs example"
                    >
                      {Object.values(appbarPages).map(({ path, name }) => (
                        <Tab
                          sx={{ textTransform: "none" }}
                          label={name}
                          value={path}
                          key={name}
                        />
                      ))}
                    </TabList>
                  </Box>
                </TabContext>
              </Box>

              <NotificationsButton />

              <Button
                data-cy="avatar-button"
                sx={{
                  width: "76px",
                  height: "44px",
                  borderRadius: 100,
                  "&:hover": {
                    backgroundColor: "#E5E4F2",
                  },
                  marginBottom: "8px",
                }}
                onClick={profileClickHandler}
              >
                <AccountCircleIcon sx={{ color: "#713DBB", width: "32px" }} />
                <ArrowDropDownIcon sx={{ color: "#323045", width: "30px" }} />
              </Button>
              <OPMenu
                anchorEl={anchorEl}
                menuState={menuState}
                handleMenuClose={handleMenuClose}
                menuOptions={menuOptions}
              />
            </Toolbar>
          </AppBar>
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
