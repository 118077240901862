import React from "react";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";
import BaseHeader from "./BaseHeader";

const styles = {
  header: {
    fontSize: "1rem",
    fontWeight: "600",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
  },
};

function SubHead({ children, sx = {}, isSentenceCase = true, ...rest }) {
  return (
    <BaseHeader
      sx={{ ...styles.header, ...sx }}
      isSentenceCase={isSentenceCase}
      {...rest}
    >
      {children}
    </BaseHeader>
  );
}

export default SubHead;
