import AccountPage from "pages/account/AccountPage";
import ConnectionsPage from "pages/connections/ConnectionsPage";
import HomePage from "pages/home/HomePage";
import OptimizationPage from "pages/optimization/OptimizationPage";
import MetricsPage from "pages/metrics/MetricsPage";
import HistoryPage from "pages/history/HistoryPage";
import PostsPage from "pages/posts/PostsPage";
import ReportingPage from "pages/reporting/ReportingPage";

function getPageInfo(component, path, name) {
  return { component, path, name };
}

const PAGES = Object.freeze({
  show_home: getPageInfo(HomePage, "/", "Home"),
  show_connections: getPageInfo(
    ConnectionsPage,
    "/connections",
    "Integrations",
  ),
  show_report: getPageInfo(HistoryPage, "/history", "History"),
  show_metrics: getPageInfo(MetricsPage, "/metrics", "Metrics"),
  show_optimization: getPageInfo(
    OptimizationPage,
    "/optimization",
    "Optimization",
  ),
  show_posts: getPageInfo(PostsPage, "/posts", "Posts"),
  show_reporting: getPageInfo(ReportingPage, "/reporting", "Reporting"),
  show_account: getPageInfo(AccountPage, "/account", "Account"),
});

export default PAGES;
