import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import { STATUS } from "utils/enums";

export const getHourlyData = createAsyncThunk(
  "asyncRedux/hourlyData",
  async () => {
    return makeAuthenticatedGetRequest("hourly");
  },
);

const hourlyDataSlice = createSlice({
  name: "hourlyDataSlice",
  initialState: {
    data: null,
    status: null,
  },
  reducers: {
    hourlyDataLogOut: (state, _) => {
      state.data = null;
      state.status = null;
    },
  },
  extraReducers: {
    [getHourlyData.pending]: (state, _) => {
      state.status = STATUS.LOADING;
    },
    [getHourlyData.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.status = STATUS.SUCCESS;
    },
    [getHourlyData.rejected]: (state, _) => {
      state.status = STATUS.FAILED;
    },
  },
});

export const { hourlyDataLogOut } = hourlyDataSlice.actions;

export default hourlyDataSlice.reducer;
