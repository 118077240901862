//
// Methods in this file should assist with data processing of graphs at the item level.
//
import { SEC_DAY } from "utils/enums";

export function createGraphItem(x, y) {
  return { x, y };
}

/*  Given graph items of the form produced by createGraphItem, return the minimum and maximum values of y */
export function determineGraphRange(graphItems) {
  const vals = graphItems.map((item) => parseFloat(item.y));
  const minValue = Math.min(...vals);
  const maxValue = Math.max(...vals);
  return [Math.floor(minValue), Math.ceil(maxValue)];
}

export function getChartRange(lineData) {
  let minX = Infinity;
  let maxX = -Infinity;
  let minY = Infinity;
  let maxY = -Infinity;

  lineData.forEach((series) => {
    series.forEach((point) => {
      if (point.x < minX) {
        minX = point.x;
      }
      if (point.x > maxX) {
        maxX = point.x;
      }
      if (point.y < minY) {
        minY = point.y;
      }
      if (point.y > maxY) {
        maxY = point.y;
      }
    });
  });

  return [
    [minX, maxX],
    [minY - (maxY - minY) * 0.2, maxY + (maxY - minY) * 0.2],
  ];
}

/* Given graph items of the form produced by createGraphItem, filter outliers on y */
export function removeOutliersFromGraphData(graphItems, min, max) {
  return graphItems
    .filter((item) => (max ? parseFloat(item.y) < max : true))
    .filter((item) => (min ? parseFloat(item.y) > min : true));
}

/* Given graph items of the form produced by createGraphItem, filter y == 0 entries */
export function removeZerosFromGraphData(graphItems) {
  return graphItems.filter((item) => parseFloat(item.y) !== 0);
}

/**
 * Generic function that takes in a pair of header names representing the headers that currently represent x and y
 * and returns another function that can take in a header and convert that into the correct x or y coordinate header.
 * Returns original header otherwise.
 *
 * Used closely with the papaparser csv library
 *
 * Inputs:
 *   * xHeaderName: the string value that represents the current header that shall be used as the x-axis
 *   * yHeaderName: the string value that represents the current header that shall be the y-axis
 *
 * */
export const createCoordinateHeaderTransformer = (xHeaderName, yHeaderName) => {
  return (header) => {
    try {
      const xheader = xHeaderName?.toLowerCase();
      const yheader = yHeaderName?.toLowerCase();
      const lowercasedHeader = header?.toLowerCase();

      if (!lowercasedHeader || !xheader || !yheader) {
        return header;
      }

      if (lowercasedHeader === xheader) {
        return "x";
      }

      if (lowercasedHeader === yheader) {
        return "y";
      }

      return header;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Could not transform csv header.", e);
      return header;
    }
  };
};
/**
 * Given a setState function, returns another function that calls the setState function
 * after parsing coordinates.
 * Intended to be used with papaparse library.
 * */
export const createOnParseHandler = (callback) => (parsedResults) => {
  const coordinates = parsedResults.data;
  const chronologicalComparator = (a, b) => a.x - b.x;
  coordinates.sort(chronologicalComparator);
  callback(coordinates);
  return coordinates;
};

/**
 * Transforms a CSV cell object with x and y keys by parsing them into the correct
 * data types for graph-plotting purposes
 * - a cell marked as 'x' is to be parsed as date
 * - a cell marked as 'y' is to be parsed as a float
 *
 * */
export const transformCsvCell = (val, header) => {
  if (header === "x") {
    return Date.parse(val);
  }
  if (header === "y") {
    return parseFloat(val);
  }
  return val;
};

/**
 * Uses a FileReader to read the csvFile as text and fires up the callback upon
 * successful reads.
 *
 * NOTE: This way, the parseCsv function is library-agnostic.
 * */
export const parseCsv = async (csvFile, callback) => {
  const freader = new FileReader();

  freader.onload = (e) => {
    const csvString = e.target.result;
    callback(csvString);
  };

  freader.onerror = (e) => {
    // eslint-disable-next-line no-console
    console.error(
      "There was an error trying to parse csv file into coordinates.",
      e,
    );
  };

  freader.readAsText(csvFile);
};

/**
 * Returns true if the list of coordinates can be visualized. i.e. if they
 * have both x and y attributes in every coordinate
 * */
export const canVisualizeCoordinates = (coordinates) => {
  if (!coordinates || coordinates.length === 0) {
    return false;
  }
  const requiredKeys = ["x", "y"];
  return coordinates.reduce((prevVal, currCoord) => {
    const keys = Object.keys(currCoord);
    return (
      prevVal && requiredKeys.every((requiredKey) => keys.includes(requiredKey))
    );
  }, true);
};

/**
 * Number values only -- in the future you may extend this if required, or create a different but similar function
 * @param {value} the number you are looking for
 * @param {dataset} the array which holds the value you are looking for
 * @param {key} if array is composed of objects, supply the key you are looking for
 * @param {fallback} value to return if invalid data is provided
 * @returns the closest index of the value in the array
 */
export const getClosestValueInList = ({
  value,
  dataset,
  key,
  fallback = 0,
}) => {
  if (!dataset?.length || !value) return fallback;

  let closest = 0;
  let closestIndex = 0;

  dataset.reduce((prev, curr, idx) => {
    const currVal = key ? curr[key] : curr;
    const prevVal = key ? prev[key] : prev;

    const currentIsLess = Math.abs(currVal - value) < Math.abs(prevVal - value);

    const currentClosest = currentIsLess ? curr : prev;
    const currentClosestValue = key ? currentClosest[key] : currentClosest;

    if (closest !== currentClosestValue) {
      closest = currentClosestValue;
      closestIndex = idx;
    }

    return currentClosest;
  });

  return closestIndex;
};

export const createCurves = (
  data = [],
  supportedGraphAxis = {},
  applyProps = {},
) => {
  if (!data.length || !supportedGraphAxis) return [];

  const keys = Object.keys(data[0]);

  if (!keys.length) return [];

  return data.map((datapoint) => {
    const coordinate = {};

    keys.forEach((key) => {
      if (!supportedGraphAxis[key]) return;
      coordinate[supportedGraphAxis[key]] = datapoint[key];
    });

    return { ...coordinate, ...applyProps };
  });
};

export const handleExplicitlySetRange = ({ curves }) => {
  const explicitlySetRange = [];

  curves.forEach(({ linePoints }) => {
    linePoints.forEach(({ x }) => {
      if (!explicitlySetRange.includes(x)) explicitlySetRange.push(x);
    });
  });

  return explicitlySetRange.sort();
};

// The default sorted roas is asc to desc
// Given a sorted roas array, fill in missing days with zero spend and return
export const fillInRoasData = (roas = []) => {
  const data = JSON.parse(JSON.stringify(roas)).reverse();

  for (let i = 0; i < data.length; i += 1) {
    const current = data[i].date;
    const next = (data[i + 1] || {}).date;
    const diff = (next - current) / SEC_DAY;

    // eslint-disable-next-line no-continue
    if (diff === 1) continue;

    for (let j = 1; j < diff; j += 1) {
      i += 1;

      data.splice(i, 0, {
        date: current + j * SEC_DAY,
        spend: 0,
        return: 0,
        lifted_return: 0,
      });
    }
  }

  return data.reverse();
};
