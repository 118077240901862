import React from "react";
import { Button } from "@mui/material";

const styles = {
  button: {
    width: "170px",
    justifyContent: "flex-start",
  },
  highlighted: { backgroundColor: "#FCF7F8" },
};

export default function Sidebar({ pages = [], setPage, currentPage }) {
  return (
    <>
      {pages.map(({ name }, id) => (
        <Button
          style={
            currentPage === id
              ? { ...styles.button, ...styles.highlighted }
              : styles.button
          }
          variant="text"
          onClick={() => setPage(id)}
        >
          {name}
        </Button>
      ))}
    </>
  );
}
