import React from "react";
import Header2 from "library/text/headers/Header2";
import PrimaryButton from "library/buttons/PrimaryButton";
import BodyText from "library/text/body/BodyText";
import BoostDetails from "./BoostDetails";

const styles = {
  header: {
    marginBottom: "30px",
  },
  subHeader: {
    marginBottom: "20px",
  },
  buttonDiv: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "right",
  },
  button: {
    width: "200px",
  },
};

export default function InfoModal({
  adsetName,
  recommendedOn,
  currentBudget,
  boostAmount,
  submittedOn,
  expiredOn,
  close,
  from,
}) {
  let headerText;
  switch (from) {
    case "boost_report":
      headerText = <Header2 sx={styles.header}>Boost history details</Header2>;
      break;
    default:
      headerText = (
        <>
          <Header2 sx={styles.header}>
            We don’t have any recommendations for this ad set
          </Header2>
          <BodyText sx={styles.subHeader}>
            Based on your key metrics and benchmarks, we don’t have any
            recommendations for this ad set.
          </BodyText>
        </>
      );
  }

  return (
    <>
      {headerText}
      <BoostDetails
        adsetName={adsetName}
        currentBudget={currentBudget}
        recommendedOn={recommendedOn}
        boostAmount={boostAmount}
        submittedOn={submittedOn}
        expiredOn={expiredOn}
      />

      <div style={styles.buttonDiv}>
        <PrimaryButton sx={styles.button} onClick={() => close()}>
          Done
        </PrimaryButton>
      </div>
    </>
  );
}
