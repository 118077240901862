import React from "react";
import {
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import OPAutoComplete from "library/form/OutPointAutoComplete";

const styles = {
  selector: {
    width: 200,
    m: 1,
  },
};

/**
 * Customized Free-Solo implementation that allows autocomplete-based dropdown selection
 * and adding of new options via a dialog.
 * */
export default function OPAutoCompleteDynamic({
  name,
  options,
  margin = "dense",
  handleSelection,
  isValidInput,
  textFieldLabel,
  dialogTextFieldLabel,
  dialogTitle,
  dialogContentText,
  sx = {},
  ...rest
}) {
  const [open, toggleOpen] = React.useState(false);
  const [pending, setPending] = React.useState(null);
  const [value, setValue] = React.useState(null);
  const [dialogValue, setDialogValue] = React.useState("");

  const displayedDialogTextFieldLabel = dialogTextFieldLabel || (
    <Typography>{textFieldLabel}</Typography>
  );

  const handleClose = async () => {
    setDialogValue("");
    toggleOpen(false);
  };

  const onAutoCompleteInputChange = (e, val) => {
    if (!val || !isValidInput(val)) {
      return;
    }

    if (!options.includes(val)) {
      /// timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        toggleOpen(true);
        setDialogValue(val);
      });
      return;
    }
    setValue(val);
    handleSelection(val);
  };

  const handleDialogFormSubmit = async () => {
    const success = await handleSelection(dialogValue);
    handleClose();
    return success;
  };

  // eslint-disable-next-line react/no-unstable-nested-components, no-shadow
  function ContinueButton({ children, ...props }) {
    return pending ? (
      <LoadingButton sx loading {...props}>
        {children}
      </LoadingButton>
    ) : (
      <Button {...props}>{children}</Button>
    );
  }

  return (
    <>
      <OPAutoComplete
        name={`${name}-autocomplete-${value}`}
        key="dynamicAutoComplete"
        options={options}
        margin={margin}
        value={value}
        autoComplete
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        openOnFocus
        forcePopupIcon
        sx={{ ...styles.selector, ...sx }}
        renderInput={(params) => (
          <TextField {...params} label={value || textFieldLabel} />
        )}
        onChange={onAutoCompleteInputChange}
        {...rest}
      />
      {/** TODO: might be good to use OPDialog component here */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogContentText}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id={`${name}-text`}
            value={dialogValue}
            onChange={(event) => {
              setDialogValue(event.target.value);
            }}
            label={displayedDialogTextFieldLabel}
            type="text"
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <ContinueButton
            variant="contained"
            disabled={!isValidInput(dialogValue)}
            type="submit"
            color="primary"
            onClick={async () => {
              setPending(true);
              const success = await handleDialogFormSubmit();
              setPending(false);
              return success;
            }}
          >
            Add
          </ContinueButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
