import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { capitalize } from "utils/data/strings";

const defaultFilterOptions = createFilterOptions({
  matchFrom: "any",
  stringify: (option) => option.toString(),
});

function OutPointAutoComplete({
  name,
  onChange,
  options,
  value,
  margin = "dense",
  size = "small",
  filterOptions,
  textFieldPaceholder,
  renderInput,
  startAdornment = "",
  ...rest
}) {
  const defaultInputRender = (params) => {
    /**
     * TODO: remove this and make it generic
     * suggestion: maybe name shouldn't be always used for the textfield label?
     *
     * Refer to this comment: https://github.com/OutPoint-app/outpoint-frontend/pull/265#discussion_r976840477
     * */
    let label = "Channel";

    if (name) {
      if (typeof name === "string") {
        label = capitalize(name);
      } else {
        label = name;
      }
    }

    params.InputProps.startAdornment = startAdornment;

    return (
      <TextField
        {...params}
        label={label}
        // prefix={'$ '}
        placeholder={textFieldPaceholder}
      />
    );
  };

  return (
    <Autocomplete
      id={name}
      options={options}
      getOptionLabel={(option) => option.toString()}
      filterOptions={filterOptions || defaultFilterOptions}
      value={value}
      onChange={onChange}
      margin={margin}
      size={size}
      renderInput={renderInput || defaultInputRender}
      {...rest}
    />
  );
}

// Set proptypes
OutPointAutoComplete.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
};

export default React.memo(OutPointAutoComplete);
