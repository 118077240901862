/* eslint-disable no-restricted-syntax */
import { capitalize } from "lodash";

export const sortTableHeaders = (
  metricName1,
  metricName2,
  nonMetrics,
  metrics,
  channels,
) => {
  for (const metric of nonMetrics) {
    if (metricName1 === metric) return -1;
    if (metricName2 === metric) return 1;
  }

  for (const metric of metrics) {
    const metric1Match = metricName1.endsWith(metric);
    const metric2Match = metricName2.endsWith(metric);
    if (metric1Match && metric2Match) {
      for (const channel of channels) {
        if (metricName1.startsWith(channel)) return -1;
        if (metricName2.startsWith(channel)) return 1;
      }
    }
    if (metric1Match) return -1;
    if (metric2Match) return 1;
  }

  return -1;
};

export const formatDisplayName = (metricName) => {
  const words = metricName.split("_").map((word) => {
    if (["top", "post"].includes(word) || word.length > 4) {
      return capitalize(word);
    }
    if (word === "num") {
      return "#";
    }
    if (word.length > 2) {
      return word.toUpperCase();
    }
    if (word === "ig") {
      return "IG";
    }
    return word;
  });
  if (words[words.length - 1]?.length <= 4) {
    words[words.length - 1] = words[words.length - 1].toUpperCase();
  }
  return words.join(" ");
};
