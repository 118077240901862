import AmazonLogo from "assets/small logos/Amazon.png";
import BingAdsLogo from "assets/logos/bing-ads.png";
import FacebookLogo from "assets/small logos/Meta.jpeg";
import GoogleAdsLogo from "assets/small logos/Google-Ads.png";
import GoogleAnalyticsLogo from "assets/small logos/Google-Analytics.png";
import PinterestLogo from "assets/small logos/Pinterest.png";
import ShopifyLogo from "assets/small logos/Shopify.png";
import SnapchatLogo from "assets/small logos/Snap.png";
import SpotifyAdsLogo from "assets/small logos/Spotify.png";
import StripeLogo from "assets/small logos/Stripe.png";
import TiktokLogo from "assets/small logos/TikTok.png";
import TwitterLogo from "assets/small logos/Twitter.png";
import GoogleButton from "react-google-button";
import React from "react";

function GoogleSSOButton(props) {
  return (
    <GoogleButton
      type="light" // we don't support dark mode
      style={{ margin: "30px", display: "inline-block" }}
      {...props}
    />
  );
}

export const cardData = {
  amazon: {
    logo: AmazonLogo,
    fields: ["Amazon Profile Id"],
  },
  bing: {
    logo: BingAdsLogo,
    fields: ["Bing Ad Account Id"],
    secondStepToolTipContent: `
      To identify your Bing Ad Account Id, please login to your
      Microsoft Advertiser platform over at ads.microsoft.com/.
      After selecting your account, the Bing Ad Account Id can be found
      in your browser's addressbar's URL.
      You should see something like
      https://ui.ads.microsoft.com/campaign/vnext/overview?aid=123456789&cid=XXXXXXXXX&uid=XXXXXXXXX.
      Your account id is the 9 digit number that comes after 'aid'.
      `,
  },
  facebook: {
    logo: FacebookLogo,
  },
  google: {
    logo: GoogleAdsLogo,
    SSOButton: GoogleSSOButton,
  },
  "Google Analytics": {
    logo: GoogleAnalyticsLogo,
    SSOButton: GoogleSSOButton,
  },
  pinterest: {
    logo: PinterestLogo,
  },
  shopify: {
    logo: ShopifyLogo,
  },
  snapchat: {
    logo: SnapchatLogo,
  },
  spotify: {
    logo: SpotifyAdsLogo,
  },
  stripe: {
    logo: StripeLogo,
  },
  tiktok: {
    logo: TiktokLogo,
  },
  twitter: {
    logo: TwitterLogo,
  },
};

export const cardGroups = {
  all: [
    "google",
    // 'Google Analytics',
    "facebook",
    "snapchat",
    "tiktok",
    // 'stripe',
    // 'bing',
    // 'amazon',
    "pinterest",
    // 'shopify',
    // 'spotify',
    "twitter",
  ],
  revenue: [
    // 'stripe',
    // 'Google Analytics',
    // 'shopify',
  ],
  ads: [
    "google",
    "facebook",
    "snapchat",
    "tiktok",
    // 'bing',
    // 'amazon',
    "pinterest",
    // 'spotify',
    "twitter",
  ],
};
