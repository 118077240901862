import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box } from "@mui/system";
import { ClickAwayListener } from "@mui/material";
import { format, parseISO } from "date-fns";
import { CALENDAR_DATE_FORMAT } from "utils/enums";
import { formatDate } from "utils/data/dates";

/**
 * Returns a date object after formating the datestring into a suitable format first.
 *
 * TODO: ensure that onChange for the dates don't trigger too early and break things because of invalid time inputs (or half-inputs)
 * */
function safeCreateDate(date) {
  const formatted = parseISO(formatDate({ date }));

  return formatted;
}

function OPDatePicker({
  disabled,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  sx = {},
  startInputProps = null,
  extraDateProps = {},
  inputFormat = CALENDAR_DATE_FORMAT,
}) {
  const [error, setError] = useState(false);
  const [errorCalendarTwo, setErrorCalendarTwo] = useState(false);
  const renderCalendarTwo = endDate && setEndDate;

  const startDateInputProps = startInputProps ?? {
    sx: {
      borderRadius: "100px",
      width: "160px",
      height: "48px",
      ...extraDateProps,
    },
  };

  const endDateInputProps = {
    sx: {
      "& .MuiOutlinedInput-notchedOutline": {
        borderLeftColor: "transparent",
      },
      borderTopRightRadius: startDateInputProps.sx.borderRadius,
      borderBottomRightRadius: startDateInputProps.sx.borderRadius,
      width: startDateInputProps.sx.width,
      height: startDateInputProps.sx.height,
      ...extraDateProps,
    },
  };

  if (renderCalendarTwo) {
    startDateInputProps.sx = {
      ...startDateInputProps.sx,
      borderTopLeftRadius: startDateInputProps.sx.borderRadius,
      borderBottomLeftRadius: startDateInputProps.sx.borderRadius,
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
      ...extraDateProps,
    };
  }

  const [openCalendar, setOpenCalendar] = useState(false);
  const [openCalendarEndDate, setOpenCalendarEndDate] = useState(false);

  const handleClickAway = () => {
    if (openCalendar) setOpenCalendar(false);
    if (openCalendarEndDate) setOpenCalendarEndDate(false);
  };

  const handleAdornmentClick = (e) => {
    if (openCalendar || openCalendarEndDate) {
      setOpenCalendar(false);
      setOpenCalendarEndDate(false);
      return;
    }

    setOpenCalendar(true);
    setOpenCalendarEndDate(true);

    e.stopPropagation();
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <DesktopDatePicker
              sx={sx}
              disabled={disabled}
              format={inputFormat}
              disableMaskedInput // Open issue that needs to be fixed so we can apply masks https://github.com/mui/mui-x/issues/4487
              value={safeCreateDate(startDate)}
              maxDate={
                endDate ? parseISO(formatDate({ date: endDate })) : undefined
              }
              onChange={(tValue) => {
                setError(false);
                setOpenCalendarEndDate(false);

                try {
                  const tValueDate = formatDate({
                    date: format(tValue, inputFormat),
                  });

                  if (tValueDate > formatDate({ date: endDate })) {
                    throw new Error("Invalid date selection");
                  }

                  setErrorCalendarTwo(false);
                  setStartDate(format(tValue, inputFormat));
                  if (!renderCalendarTwo) setOpenCalendar(false);
                } catch (e) {
                  setError(true);
                  setStartDate(format(new Date(), inputFormat));
                }
              }}
              InputAdornmentProps={{
                position: "start",
                onClick: handleAdornmentClick,
              }}
              InputProps={startDateInputProps}
              PopperProps={{
                popperOptions: {
                  placement: "bottom-end",
                },
                style: {
                  paddingRight: "10px",
                },
              }}
              renderInput={({ inputProps, ...params }) => (
                <TextField
                  sx={extraDateProps}
                  {...params}
                  onClick={(_) => {
                    if (!disabled) {
                      setOpenCalendar(!openCalendar);
                      setOpenCalendarEndDate(false);
                    }
                  }}
                  inputProps={{
                    ...inputProps,
                    placeholder: "Choose date",
                  }}
                  error={error}
                />
              )}
            />
            {renderCalendarTwo && (
              <DesktopDatePicker
                open={openCalendarEndDate}
                inputFormat={inputFormat}
                disableMaskedInput
                value={safeCreateDate(endDate)}
                disabled={disabled}
                minDate={parseISO(formatDate({ date: startDate }))}
                onChange={(tValue) => {
                  setErrorCalendarTwo(false);
                  setOpenCalendar(false);

                  // evaluate tValue
                  try {
                    const tValueDate = formatDate({
                      date: format(tValue, inputFormat),
                    });

                    if (tValueDate < formatDate({ date: startDate })) {
                      throw new Error("Invalid date selection");
                    }

                    setError(false);
                    setEndDate(format(tValue, inputFormat));
                  } catch (e) {
                    setErrorCalendarTwo(true);
                  }
                }}
                InputProps={endDateInputProps}
                disableOpenPicker
                PopperProps={{
                  popperOptions: {
                    placement: "bottom-start",
                  },
                  style: {
                    boxShadow: "none",
                  },
                }}
                renderInput={({ inputProps, ...params }) => (
                  <TextField
                    {...params}
                    onClick={() => {
                      if (!disabled) {
                        setOpenCalendarEndDate(!openCalendarEndDate);
                        setOpenCalendar(false);
                      }
                    }}
                    inputProps={{
                      ...inputProps,
                      placeholder: "Choose date",
                    }}
                    error={errorCalendarTwo}
                    sx={extraDateProps}
                  />
                )}
              />
            )}
          </Box>
        </LocalizationProvider>
      </Box>
    </ClickAwayListener>
  );
}

export default OPDatePicker;
