import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { authenticateToken, getToken, tokenValid } from "utils/authentication";

const PrivateRoute = ({ component: Component }) => {
  const dispatch = useDispatch();
  const authStatus = useSelector((state) => state.login.authStatus);
  const [pageStatus, setPageStatus] = useState("success");

  const privatePage = {
    success: Component,
    failure: <Navigate to="/login" />,
  };

  const [token, setToken] = useState(getToken());

  useEffect(() => {
    authenticateToken(dispatch);
  }, []);

  useEffect(() => {
    const tokenStatus = tokenValid();
    if (!tokenStatus) {
      window.location.pathname = "/login";
    }
  }, []);

  useEffect(() => {
    setToken(getToken());
  }, [authStatus]);

  useEffect(() => {
    if (authStatus || token) {
      setPageStatus("success");
    } else {
      setPageStatus("failure");
    }
  }, [authStatus, token]);

  return privatePage[pageStatus];
};
export default PrivateRoute;
