import React from "react";

import BodyText from "library/text/body/BodyText";
import OutPointSelector from "library/form/OutPointSelector";
import OutPointTextField from "library/form/OutPointTextField";
import OutPointToggle from "library/buttons/OutPointToggle";
import { TWO_DECIMAL_REGEX } from "utils/regex";
import { formatMetricForDisplay } from "utils/data/strings";

const styles = {
  targetValueSelector: {
    borderRadius: "5px",
    height: "40px",
    marginTop: "6px",
    width: "100%",
  },
  formField: {
    width: "100%",
    marginBottom: "10px",
  },
};

export default function TargetMetricForm({
  displayMetricsData,
  kpi,
  setKpi,
  benchmark,
  setBenchmark,
  impressionThreshold,
  setImpressionThreshold,
  buyingUnit,
  setBuyingUnit,
  shouldUpdateExistingAdsets = null,
  setShouldUpdateExistingAdsets = null,
  isBenchmarkError,
  setIsBenchmarkError,
  isImpressionThresholdError,
  setIsImpressionThresholdError,
  isBuyingUnitError,
  setIsBuyingUnitError,
}) {
  const benchmarkUnit = displayMetricsData.filter(
    (metric) => formatMetricForDisplay(metric.metric) === kpi,
  )?.[0]?.unit;
  const kpiMenuItems = displayMetricsData.map((metric) =>
    formatMetricForDisplay(metric.metric),
  );

  const onBenchmarkChange = (value) => {
    if (!TWO_DECIMAL_REGEX.test(value)) {
      setIsBenchmarkError(true);
    } else {
      setBenchmark(value);
      setIsBenchmarkError(false);
    }
  };

  const onImpressionThresholdChange = (value) => {
    if (!TWO_DECIMAL_REGEX.test(value) && value !== "") {
      setIsImpressionThresholdError(true);
    } else {
      setImpressionThreshold(value);
      setIsImpressionThresholdError(false);
    }
  };

  const onBuyingUnitChange = (value) => {
    if (!TWO_DECIMAL_REGEX.test(value)) {
      setIsBuyingUnitError(true);
    } else {
      setBuyingUnit(value);
      setIsBuyingUnitError(false);
    }
  };

  const onShouldUpdateExistingAdsetsChange = () => {
    setShouldUpdateExistingAdsets(!shouldUpdateExistingAdsets);
  };

  const shouldShowMassUpdateToggle =
    shouldUpdateExistingAdsets != null &&
    setShouldUpdateExistingAdsets !== null;

  return (
    <div style={{ width: "100%" }} key="container-target-value">
      <BodyText>
        We'll use this to determine what needs to be boosted and the amount to
        boost by.
      </BodyText>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingTop: "20px",
        }}
      >
        <div
          style={{
            width: "40%",
            paddingRight: "20px",
          }}
        >
          <BodyText>KPI</BodyText>
          <OutPointSelector
            sx={styles.targetValueSelector}
            menuItems={kpiMenuItems}
            onChange={(value) => setKpi(value)}
            selectedValue={kpi}
            isSentenceCaseMenu={false}
          />
        </div>
        <div
          style={{
            width: "60%",
          }}
        >
          <BodyText>Benchmark</BodyText>
          <OutPointTextField
            sx={styles.formField}
            value={benchmark}
            error={isBenchmarkError}
            startAdornment={benchmarkUnit === "$" ? "$" : ""}
            endAdornment={benchmarkUnit === "%" ? "%" : ""}
            errorMsg="Input must be a valid number."
            onChange={(value) => onBenchmarkChange(value)}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          paddingTop: "20px",
        }}
      >
        <div
          style={{
            width: "40%",
            paddingRight: "20px",
          }}
        >
          <BodyText>Impression Threshold</BodyText>
          <OutPointTextField
            sx={styles.formField}
            value={impressionThreshold}
            error={isImpressionThresholdError}
            errorMsg="Input must be a valid number."
            onChange={(value) => onImpressionThresholdChange(value)}
          />
        </div>
        <div
          style={{
            width: "60%",
          }}
        >
          <BodyText>Budget Unit</BodyText>
          <OutPointTextField
            sx={styles.formField}
            value={buyingUnit}
            error={isBuyingUnitError}
            errorMsg="Input must be a valid number."
            startAdornment="$"
            onChange={(value) => onBuyingUnitChange(value)}
          />
        </div>

        <div />
      </div>
      {shouldShowMassUpdateToggle && (
        <div style={{ marginTop: "10px" }}>
          <BodyText>Update Existing Adsets?</BodyText>
          <OutPointToggle
            checked={shouldUpdateExistingAdsets}
            onChange={(val) => onShouldUpdateExistingAdsetsChange(val)}
          />
        </div>
      )}
    </div>
  );
}
