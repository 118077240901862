//
// Methods in this file should do mathematical operations.
//

export function getQuotientRounded(num1, num2) {
  if (num2 === 0) return 0;
  return Math.round(num1 / num2);
}

export function getQuotientFixed(num1, num2) {
  if (num2 === 0) return 0;
  return parseFloat((num1 / num2).toFixed(2));
}

export function getPercentageChange(start, end) {
  if (start === 0) return Infinity;
  return (getQuotientFixed(end, start) - 1) * 100;
}

/*
 * Common percentage change calculation
 *
 *
 * Handles division by zero, but infinity values are kept as is. This
 * is because infinity values should best be handled as a formatting step.
 */
export const computePercentageChange = (updated, old) => {
  if (updated === old) {
    return 0;
  }

  if (old === 0) {
    // guards against DIV by 0 error
    return Infinity;
  }

  return 100 * ((updated - old) / old);
};
