import React, { useState } from "react";
import { Button } from "@mui/material";
import OutPointModal from "library/surface/ModalCentered";
import StopModal from "./StopModal";

const styles = {
  button: {
    fontWeight: "bold",
    color: "red",
    borderColor: "red",
  },
};

export default function StopButton({
  channel,
  adsetId,
  adsetName,
  recommendedOn,
  boostAmount,
  submittedOn,
  expiredOn,
  currentBudget,
  disabled,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <OutPointModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        sx={{ maxWidth: "500px" }}
      >
        <StopModal
          channel={channel}
          adsetId={adsetId}
          adsetName={adsetName}
          recommendedOn={recommendedOn}
          boostAmount={boostAmount}
          currentBudget={currentBudget}
          submittedOn={submittedOn}
          expiredOn={expiredOn}
          close={() => setModalOpen(false)}
        />
      </OutPointModal>
      <Button
        disabled={disabled}
        variant="outlined"
        size="small"
        sx={styles.button}
        onClick={() => setModalOpen(true)}
      >
        Stop
      </Button>
    </>
  );
}
