import { Button } from "@mui/material";
import React from "react";
import { capitalize } from "lodash";
import { THEME } from "assets/palette";

const styles = {
  activeButton: {
    boxShadow:
      "0px 0px 2px rgba(143, 140, 140, 0.2), 0px 2px 10px rgba(143, 140, 140, 0.1)",
    marginRight: "3px",
  },
  inactiveButton: {
    backgroundColor: THEME.grey.light,
    marginRight: "3px",
  },
  buttonGroup: {
    height: "min-content",
    backgroundColor: THEME.grey.light,
    padding: "3px 0 3px 3px",
    borderRadius: "4px",
    display: "inline-block",
  },
};

function OutPointButtonGroup({
  values,
  curState,
  setter,
  isSentenceCase = true,
  sx = {},
  ...rest
}) {
  return (
    <div style={{ ...sx, ...styles.buttonGroup }} {...rest}>
      {values.map((curVal) => (
        <Button
          key={curVal}
          variant={curState === curVal ? "contained" : "text"}
          onClick={() => setter(curVal)}
          color={curState === curVal ? "base" : "grey"}
          sx={curState === curVal ? styles.activeButton : styles.inactiveButton}
        >
          {isSentenceCase ? capitalize(curVal) : curVal}
        </Button>
      ))}
    </div>
  );
}

export default OutPointButtonGroup;
