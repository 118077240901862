import React from "react";

const styles = {
  logoChip: {
    borderColor: "#E3E6EC",
    borderWidth: "1px",
    borderRadius: "8px",
    borderStyle: "solid",
    position: "relative",
    padding: "10px",
  },
  logoChipImage: {
    height: "inherit",
    width: "inherit",
  },
};

export default function LogoChip({ logo, channel, size = "35px", sx = {} }) {
  return (
    <div style={{ ...styles.logoChip, height: size, width: size, ...sx }}>
      <img src={logo} style={styles.logoChipImage} alt={`${channel} logo`} />
    </div>
  );
}
