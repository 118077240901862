import React, { useState } from "react";
import { Button } from "@mui/material";
import OutPointModal from "library/surface/ModalCentered";
import UpdateModal from "./UpdateModal";

const styles = {
  button: {
    fontWeight: "bold",
  },
};

export default function BoostButton({
  currentBudget,
  investment,
  channel,
  adsetId,
  adsetName,
  recommendedOn,
  adsetEndTime,
  disableDateTimePicker = false,
  disabled = false,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <OutPointModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        sx={{ maxWidth: "500px" }}
      >
        <UpdateModal
          adsetId={adsetId}
          adsetName={adsetName}
          channel={channel}
          currentBudget={currentBudget}
          recommendedInvestment={investment}
          recommendedOn={recommendedOn}
          adsetEndTime={adsetEndTime}
          close={() => setModalOpen(false)}
          header="Boost this ad set"
          subheader={"You're about to boost the following ad set:"}
          action="boost"
          disableDateTimePicker={disableDateTimePicker}
        />
      </OutPointModal>
      <Button
        color="green"
        variant="outlined"
        size="small"
        sx={styles.button}
        disabled={disabled}
        onClick={() => setModalOpen(true)}
      >
        Boost
      </Button>
    </>
  );
}
