import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

function BasicDataGrid({
  displayColumns,
  displayRows,
  pageSize = 25,
  pageSizeOptions = [25, 50, 100],
  rowHeight = 100,
  headerHeight = 80,
  disableColumnReorder = true,
  disableRowSelectionOnClick = true,
  disableColumnMenu = true,
  getRowId = null,
  sx = {},
}) {
  return (
    <Box>
      <DataGrid
        autoHeight
        disableAutosize
        density="compact"
        disableColumnMenu={disableColumnMenu}
        disableColumnReorder={disableColumnReorder}
        rowHeight={rowHeight}
        disableRowSelectionOnClick={disableRowSelectionOnClick}
        getRowId={getRowId}
        rows={displayRows}
        columns={displayColumns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize,
            },
          },
        }}
        columnHeaderHeight={headerHeight}
        pageSizeOptions={pageSizeOptions}
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-cell:focus, &.MuiDataGrid-root .MuiDataGrid-columnHeader:focus":
            {
              outline: "none",
            },
          ".MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold !important",
          },
          ...sx,
        }}
      />
    </Box>
  );
}

export default BasicDataGrid;
