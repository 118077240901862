/* eslint-disable camelcase */
import React, { useState } from "react";

import { Grid } from "@mui/material";
import useUrlPrettifier from "hooks/useUrlPrettifier";

import { useSelector } from "react-redux";

import Header1 from "library/text/headers/Header1";
import BasicTable from "library/display/BasicTable";
import OutPointDropdown from "library/buttons/OutPointDropdown";
import OutPointCSVDownloader from "library/buttons/CSVDownloader";
import Header4 from "library/text/headers/Header4";
import { getPercentageChange } from "utils/data/math";
import { addCommasToDecimal, addCommasToNumber } from "utils/data/strings";
import tableHeaders from "./data/tableData";
import ReportPageCard from "./components/ReportPageCard";

const styles = {
  button: {
    marginTop: "20px",
    marginBottom: "20px",
    fontWeight: "bold",
    width: "250px",
  },
  toolbar: {
    margin: "20px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  selector: {
    marginRight: "20px",
  },
};

function HistoryPage() {
  useUrlPrettifier();

  const boostReportData = useSelector((state) => state.boostReport.data) || [];

  const channels = Array.from(
    new Set(boostReportData.map(({ channel }) => channel)),
  );

  const [platformSelection, setPlatformSelection] = useState("All");
  const [statusSelection, setStatusSelection] = useState("All");

  const displayData = boostReportData
    .map((row) => {
      const {
        expired_at,
        invalidated_at,
        stopped_at,
        previous_lifetime_budget,
        boost_amount,
      } = row;
      const newRow = { ...row };
      newRow.status =
        !expired_at && !invalidated_at && !stopped_at
          ? "in progress"
          : "complete";
      newRow.change = Math.round(
        getPercentageChange(
          previous_lifetime_budget,
          previous_lifetime_budget + boost_amount,
        ),
      );
      return newRow;
    })
    .filter(({ channel, status }) => {
      if (platformSelection === "All" && statusSelection === "All") {
        return true;
      }

      let filterOut = true;
      if (platformSelection !== "All") {
        filterOut = filterOut && platformSelection === channel;
      }

      if (statusSelection !== "All") {
        filterOut = filterOut && statusSelection.toLowerCase() === status;
      }

      return filterOut;
    });

  const monthCardData = [
    {
      title: "Total Boosts",
      body: addCommasToNumber(
        boostReportData.reduce(
          (acc, { start_timestamp }) =>
            Date.parse(start_timestamp) >= Date.now() - 28 * 24 * 60 * 60 * 1000
              ? acc + 1
              : acc,
          0,
        ),
      ),
    },
    {
      title: "Boost Amount",
      body: `$${addCommasToDecimal(
        boostReportData.reduce(
          (acc, { start_timestamp, boost_amount }) =>
            Date.parse(start_timestamp) >= Date.now() - 28 * 24 * 60 * 60 * 1000
              ? acc + boost_amount
              : acc,
          0,
        ),
      )}`,
    },
  ];
  const weekCardData = [
    {
      title: "Total Boosts",
      body: addCommasToNumber(
        boostReportData.reduce(
          (acc, { start_timestamp }) =>
            Date.parse(start_timestamp) >= Date.now() - 7 * 24 * 60 * 60 * 1000
              ? acc + 1
              : acc,
          0,
        ),
      ),
    },
    {
      title: "Boost Amount",
      body: `$${addCommasToDecimal(
        boostReportData.reduce(
          (acc, { start_timestamp, boost_amount }) =>
            Date.parse(start_timestamp) >= Date.now() - 7 * 24 * 60 * 60 * 1000
              ? acc + boost_amount
              : acc,
          0,
        ),
      )}`,
    },
  ];

  return (
    <>
      <Header1>Report</Header1>
      <Grid container spacing={3} sx={{ width: "100%", paddingTop: "20px" }}>
        <Grid item container spacing={3} xs={6}>
          <Grid item xs={12}>
            <Header4>7 day performance</Header4>
          </Grid>
          {weekCardData.map((data) => (
            <Grid item xs={6}>
              <ReportPageCard key={data.title} {...data} />
            </Grid>
          ))}
        </Grid>
        <Grid item container spacing={3} xs={6}>
          <Grid item xs={12}>
            <Header4>4 week performance</Header4>
          </Grid>
          {monthCardData.map((data) => (
            <Grid item xs={6}>
              <ReportPageCard key={data.title} {...data} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid container sx={styles.toolbar}>
        <div>
          <OutPointDropdown
            sx={styles.selector}
            menuItems={["All", "In progress", "Complete"]}
            selectedValue={statusSelection}
            onChange={(value) => setStatusSelection(value)}
            leading="Status: "
          />
          <OutPointDropdown
            sx={styles.selector}
            menuItems={["All", ...channels]}
            selectedValue={platformSelection}
            onChange={(value) => setPlatformSelection(value)}
            leading="Platform: "
          />
        </div>
        <OutPointCSVDownloader
          data={boostReportData}
          label="Download Data"
          filename="frontrunnrData"
          ButtonProps={{
            sx: styles.button,
            color: "green",
            variant: "outlined",
          }}
        />
      </Grid>

      <BasicTable
        tableHeight="90vh"
        columnHeaders={tableHeaders}
        rowData={displayData}
        sortable
      />
    </>
  );
}

export default HistoryPage;
