import React, { useState, useEffect } from "react";

import { IconButton } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import IconText from "library/containers/IconText";
import BodyText from "library/text/body/BodyText";
import { ExpandMoreRounded, ExpandLessRounded } from "@mui/icons-material";

const styles = {
  checkIcon: {
    fontSize: "20px",
  },
  titleContainer: {
    width: "100%",
    display: "flex",
    direction: "column",
    justifyContent: "space-between",
    margin: "7px",
  },
  container: {
    display: "flex",
    direction: "row",
    justifyContent: "flex-start",
    width: "100%",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "90%",
  },
};

export default function PlatformCardModalSection({ title, form, done }) {
  const [open, setOpen] = useState(!done);
  const Icon = done ? CheckCircleIcon : RadioButtonUncheckedIcon;

  useEffect(() => {
    setOpen(!done);
  }, [done]);

  return (
    <>
      <div style={styles.container}>
        <IconButton onClick={() => setOpen(!open)}>
          {open ? <ExpandMoreRounded /> : <ExpandLessRounded />}
        </IconButton>
        <div style={{ width: "100%" }}>
          <IconText sx={styles.titleContainer}>
            <BodyText color="secondary">{title}</BodyText>
            <Icon
              sx={{ ...styles.checkIcon, color: done ? "green" : "grey" }}
            />
          </IconText>
        </div>
      </div>
      <div style={styles.formContainer}>{open && form}</div>
    </>
  );
}
