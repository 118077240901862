import React from "react";
import { Box, Grid } from "@mui/material";
import SubHead from "library/text/headers/SubHead";

const styles = {
  lightHeading: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    whiteSpace: "nowrap",
    color: "#606066",
    marginBottom: "12px",
    fontFamily: "IBM Plex Sans",
  },
  flexDisplay: {
    display: "flex",
    alignItems: "flex-end",
  },
  headingBig: {
    fontWeight: 400,
    fontSize: "29px",
    lineHeight: "32px",
    color: "#1C1C1F",
    fontFamily: "IBM Plex Sans",
  },
  unitText: {
    fontSize: "20px",
    fontWeight: 700,
    marginLeft: "10px",
  },
  grid: {
    display: "inline-flex",
    flexDirection: "column",
  },
};

function MetricDisplay({
  keyText,
  valueText,
  keyAdornment = <div />,
  valueAdornment = <div />,
  sx = {},
  unit = null,
  ...rest
}) {
  return (
    <Grid
      item
      sx={{ ...styles.grid, ...sx }}
      key={`${keyText}-${valueText}`}
      {...rest}
    >
      <Box sx={styles.flexDisplay}>
        <SubHead sx={styles.lightHeading}>{keyText}</SubHead>
        {keyAdornment}
      </Box>
      <Box sx={styles.flexDisplay}>
        <SubHead sx={styles.headingBig}>
          {valueText}
          {unit && <span style={styles.unitText}>/{unit}</span>}
        </SubHead>
        {valueAdornment}
      </Box>
    </Grid>
  );
}

export default MetricDisplay;
