import React, { useState } from "react";
import { Grid } from "@mui/material";
import OPAutoComplete from "library/form/OutPointAutoComplete";
import TextFieldSimple from "library/form/OutPointTextField";
import OPDatePicker from "library/form/DatePicker";
import { isAttributeNAN } from "utils/dataset/taxonomy";
import {
  mmddToCalendarFmt,
  calendarDateFmtToMmdd,
} from "../../../utils/data/dates";

function BoundedInputField({ attributeInfo, userInput, handleInput }) {
  const {
    name: attributeName,
    display_name: attributeDisplayName,
    options,
  } = attributeInfo;

  return (
    <OPAutoComplete
      name={`Select ${attributeDisplayName}`}
      key={attributeName}
      options={Object.keys(options)}
      value={userInput}
      autoComplete
      selectOnFocus
      handleHomeEndKeys
      freeSolo
      openOnFocus
      forcePopupIcon
      onChange={handleInput}
    />
  );
}

function UnBoundedInputField({ attributeInfo, userInput, handleInput }) {
  const [unboundedVal, setUnboundedVal] = useState(userInput || "");

  const {
    name: attributeName,
    display_name: attributeDisplayName,
    default_value: defaultValue,
    is_bounded_value: isBoundedValue,
    is_required_by_airtable_form: isRequiredByAirtableForm,
  } = attributeInfo;

  // Date of Post Launch has to be in the MMDD format
  if (attributeName === "launch_date") {
    // our calendar works best in calendar format, sidestepped potential bugs in date stuff by just doing a conversion to and fro
    const startDate = mmddToCalendarFmt(unboundedVal);

    const formatThenHandle = (val) => {
      const formatted = calendarDateFmtToMmdd(val);
      setUnboundedVal(formatted);
      handleInput(formatted);
    };

    const datePicker = (
      <OPDatePicker
        startDate={startDate || Date.now()}
        setStartDate={formatThenHandle}
        startInputProps={{
          sx: {
            height: "40px",
            width: "230px",
          },
        }}
      />
    );

    return (
      <Grid container direction="column">
        <Grid item sx={{ marginBottom: "4px" }}>
          {attributeDisplayName}
        </Grid>
        <Grid item>{datePicker}</Grid>
      </Grid>
    );
  }

  const exceptAttributesForDisabledEntry = ["target_sub_type"]; // add to this exceptions list based on biz-side requirements
  const isInExceptionsList =
    exceptAttributesForDisabledEntry.includes(attributeName);
  const isDefaultValueNA = isAttributeNAN(defaultValue);
  const isDisabled =
    !isInExceptionsList &&
    !isRequiredByAirtableForm &&
    !isBoundedValue &&
    isDefaultValueNA;

  return (
    <TextFieldSimple
      name={`Input value for ${attributeDisplayName}`}
      disabled={isDisabled}
      key={attributeName}
      value={unboundedVal}
      onChange={(val) => {
        setUnboundedVal(val);
      }} // shims the onChange
      onBlur={(e) => {
        e.preventDefault();
        const val = e.target.value;
        handleInput(val);
      }}
    />
  );
}

/**
 * There are two sources of data:
 * 1. airtable-copied information from the social.airtable data
 * 2. options for the different attributes
 * The value in the airtable_data table may or may not have been written yet.
 *
 * A:
 * If that table value is non-null then we can just use that as the initial user input.
 *
 * B:
 * If it's currently null, then we have to consider:
 * 1. is bounded (dropdown) attr?
 *    a) is_required:
 *       - it should have been provided, throw a warning because it implies that its a data issue
 *    b) is not required:
 *       - use the default value as the initial user input
 * 2. is unbounded (manually input) attr?
 *    a) is_required:
 *       - it should have been provided, throw a warning because it implies that its a data issue
 *    b) is_not_required:
 *       2.b.i) to be ignored? ==> to be ignored if !is_bounded && !is_required && default_value
 *            - use whatever is in the default value and disable the input.
 *       2.b.ii) to use the default value...
 *
 *
 * The value in the airtable may or may not have been written yet.
 * If that table value is non-null then  we can just use that
 *
 * They yield the following cases:
 * 1. is dropdown attribute? i.e. it's bounded and values are to be selected from
 *    a) is option to be ignored
 *    b) is value present in post? ==> use that as initial value
 *    c) value is not in post
         - is value a required value?
         is value present in default value?
         - is
 * 2. is manually input? i.e. either a text input or a datepicker
 * */
export const determineInitialUserInput = (attrValFromPost, attrInfo) => {
  const {
    name: attributeName,
    default_value: defaultValue,
    is_bounded_value: isBoundedValue,
    is_required_by_airtable_form: isRequiredByAirtableForm,
    options,
  } = attrInfo;

  if (attrValFromPost) {
    // A: use table value if non-null
    return attrValFromPost;
  }

  /// Cases A1a) and A2a):
  const shouldShowWarning = isRequiredByAirtableForm && !attrValFromPost;
  if (shouldShowWarning) {
    // eslint-disable-next-line no-console
    console.warn(
      `There's likely a data-side issue since required attributes must be provided for ${attributeName}`,
    );

    return defaultValue; // this usually ends up being null / nan
  }

  /// Case A1b):
  const optionsKeys = Object.keys(options);
  const shouldUseDefaultForDropdown =
    isBoundedValue && !isRequiredByAirtableForm;
  if (shouldUseDefaultForDropdown) {
    // eslint-disable-next-line no-console
    console.assert(
      optionsKeys.includes(defaultValue),
      `Possible data issue: the default value provided (${defaultValue}) should be a key in the options map`,
    );

    return defaultValue;
  }

  /// Case A2bi): to ignore
  const shouldIgnore = !isBoundedValue && !isRequiredByAirtableForm;
  if (shouldIgnore) {
    return defaultValue;
  }

  return defaultValue;
};

export function TaxonomyInputField({
  setAttributeValue,
  attrValFromPost,
  attributeInfo = {},
}) {
  const { is_bounded_value: isBoundedValue, options } = attributeInfo;

  const optionNames = Object.keys(options);
  const initialUserInput = determineInitialUserInput(
    attrValFromPost,
    attributeInfo,
  );
  const [userInput, setUserInput] = useState(initialUserInput);

  /// selection handlers:
  const handleBoundedValueInput = (_, val) => {
    if (!val || !optionNames.includes(val)) {
      return;
    }
    setUserInput(val);
    setAttributeValue(val);
  };

  /**
   * Formats then sets the attributes
   * */
  const handleUnboundedValueInput = (val) => {
    if (!val) {
      return;
    }
    const formattedVal = val;
    setUserInput(formattedVal);
    setAttributeValue(val);
  };

  if (!isBoundedValue) {
    return (
      <UnBoundedInputField
        attributeInfo={attributeInfo}
        userInput={userInput}
        handleInput={handleUnboundedValueInput}
      />
    );
  }
  return (
    <BoundedInputField
      attributeInfo={attributeInfo}
      userInput={userInput}
      handleInput={handleBoundedValueInput}
    />
  );
}
