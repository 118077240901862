import axios from "axios";

const BACKEND_DOMAIN = process.env.REACT_APP_DOMAIN;

function checkAuth(error) {
  if (error.response?.status === 401) {
    window.location.pathname = "/login";
  }
}

export async function makeAuthenticatedGetRequest(route, queryParams = {}) {
  const Token = localStorage.getItem("Token");
  let response;

  const uriFriendlyQueryParams = Object.keys(queryParams)
    .map((queryParam, index) => {
      return `${index ? "" : "?"}${queryParam}=${queryParams[queryParam]}`;
    })
    .join("&");

  try {
    response = await axios.get(
      `${BACKEND_DOMAIN}/${route}${uriFriendlyQueryParams}`,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      },
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Unable to make authenticated GET request to /${route}`, e);
    checkAuth(e);

    return Promise.reject(new Error(e.response?.data?.message));
  }

  return Promise.resolve(response.data);
}

export async function makeAuthenticatedPostRequest(route, data = {}) {
  const Token = localStorage.getItem("Token");
  let response;

  try {
    response = await axios.post(
      `${process.env.REACT_APP_DOMAIN}/${route}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      },
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`Unable to make authenticated POST request to /${route}`, e);
    checkAuth(e);

    return Promise.reject(new Error(e.response?.data?.message));
  }

  return Promise.resolve(response.data);
}

export async function makeAuthenticatedDeleteRequest(route, queryParams = {}) {
  const Token = localStorage.getItem("Token");
  let response;

  const uriFriendlyQueryParams = Object.keys(queryParams)
    .map((queryParam, index) => {
      return `${index ? "" : "?"}${queryParam}=${queryParams[queryParam]}`;
    })
    .join("&");

  try {
    response = await axios.delete(
      `${BACKEND_DOMAIN}/${route}${uriFriendlyQueryParams}`,
      {
        headers: {
          Authorization: `Bearer ${Token}`,
        },
      },
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `Unable to make authenticated DELETE request to /${route}`,
      e,
    );
    checkAuth(e);

    return Promise.reject(new Error(e.response?.data?.message));
  }

  return Promise.resolve(response.data);
}

export const deleteCredentials = async (credentialId, tokenType) => {
  try {
    const response = await makeAuthenticatedDeleteRequest("token", {
      credential_id: credentialId,
      token_type: tokenType,
    });
    if (response.data?.success) {
      return true;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
  }

  return false;
};
