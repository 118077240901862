import { jesterPostRequest } from "utils/jester-api";

const submit = async (
  credentialId,
  channel,
  description,
  tokenType,
  refetchData,
  dataList,
  setRequestPending,
) => {
  const body = {
    credential_id: credentialId,
    channel,
    description,
    token_type: tokenType,
  };

  let canSubmit = true;
  let isDataToSubmit = false;

  dataList.forEach(({ name, data, checkSubmit, checkEquality }) => {
    canSubmit = canSubmit && checkSubmit();
    if (checkEquality()) {
      isDataToSubmit = true;
      body[name] = data;
    }
  });

  if (!canSubmit) {
    return false;
  }

  if (!isDataToSubmit) {
    return true;
  }

  const makeMetadataRequest = async () => {
    try {
      await jesterPostRequest("metadata", body);
      return true;
    } catch (e) {
      return false;
    }
  };

  try {
    setRequestPending(true);
    const responseSuccess = await makeMetadataRequest();
    if (responseSuccess) {
      await refetchData();
      return true;
    }
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  } finally {
    setRequestPending(false);
  }

  return false;
};

export default submit;
