import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { ClickAwayListener } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Box } from "@mui/system";

function OPTimePicker({
  disabled,
  time,
  setTime,
  inputProps = null,
  sx = {},
  extraTimeProps = {},
}) {
  const [openTimeSelector, setOpenTimeSelector] = useState(false);
  const [error, setError] = useState(false);

  const handleClickAway = () => {
    if (openTimeSelector) setOpenTimeSelector(false);
  };

  const timeInputProps = inputProps ?? {
    sx: {
      borderRadius: "100px",
      width: "160px",
      height: "48px",
      ...extraTimeProps,
    },
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <DesktopTimePicker
              sx={sx}
              ampm
              minutesStep={5}
              disabled={disabled}
              value={time}
              onChange={(value) => {
                if (Number.isNaN(value.getTime())) {
                  setError(true);
                } else {
                  setError(false);
                }
                setTime(value);
                setOpenTimeSelector(false);
              }}
              InputProps={timeInputProps}
              renderInput={({ inputProps: renderInputProps, ...params }) => (
                <TextField
                  sx={extraTimeProps}
                  {...params}
                  onClick={(_) => {
                    if (!disabled) {
                      setOpenTimeSelector(!openTimeSelector);
                    }
                  }}
                  error={error}
                  inputProps={{
                    ...renderInputProps,
                    placeholder: "Choose time",
                  }}
                />
              )}
            />
          </Box>
        </LocalizationProvider>
      </Box>
    </ClickAwayListener>
  );
}

export default OPTimePicker;
