import React from "react";
import { Typography } from "@mui/material";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";
import { sentenceCase } from "utils/data/strings";

const styles = {
  header: {
    fontSize: "14px",
    fontWeight: "600",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
  },
};

function SubHead2({ children, sx = {}, isSentenceCase = true, ...rest }) {
  return (
    <Typography sx={{ ...styles.header, ...sx }} {...rest}>
      {isSentenceCase && children
        ? sentenceCase(children)
        : children?.toUpperCase() || ""}
    </Typography>
  );
}

export default SubHead2;
