import React from "react";
import { Typography } from "@mui/material";
import { sentenceCase } from "utils/data/strings";

function BaseHeader({ children, isSentenceCase = true, ...rest }) {
  let newChildren;
  if (typeof children === "string") {
    newChildren = sentenceCase(children);
  } else if (Array.isArray(children)) {
    let changedFirstLetter = false;

    newChildren = children.map((child) => {
      if (typeof child === "string") {
        if (!changedFirstLetter) {
          child = sentenceCase(child);
          changedFirstLetter = true;
        } else child = child.toLowerCase();
      }
      return child;
    });
  } else {
    newChildren = children;
  }
  return (
    <Typography {...rest}>{isSentenceCase ? newChildren : children}</Typography>
  );
}

export default BaseHeader;
