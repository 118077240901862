// Colours should all be in rgba format with an opacity of 1

const colours = {
  facebook: "rgba(66, 103, 178, 1)",
  google: "rgba(244, 180, 0, 1)",
  youtube: "rgba(221, 179, 71, 1)",
  snapchat: "rgba(238, 238, 0, 1)",
  tiktok: "rgba(238, 29, 82, 1)",
  tv: "rgba(108, 122, 137, 1)",
  choozle: "rgba(120, 179, 50, 1)",
  reddit: "rgba(255, 69, 0, 1)",
  brand: "rgba(96, 96, 96, 1)",
  email: "rgba(220, 220, 220, 1)",
  influencers: "rgba(255, 155, 206, 1)",
  spotify: "rgba(28, 204, 91, 1)",
  "out of home": "rgba(39, 217, 245, 1)",
  "direct mail": "rgba(207, 55, 55, 1)",
};

export default colours;

/// Acknowledgement: https://css-tricks.com/snippets/javascript/random-hex-color/
const getRandomHexColour = () => {
  const colour = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  return colour;
};

export const CHANNEL_COLOURS = {
  affiliate: "#6a0dad",
  bing: "#00FF00",
  facebook: "#4267B2",
  google: "#F4B400",
  influencer: "#FF9BCE",
  "native ads": "#0000FF",
  ooh: "#27D9F5",
  podcast: "#FFA500",
  radio: "#ff0000",
  reddit: "#FF4500",
  snapchat: "#EEEE00",
  tiktok: "#EE1D52",
  tv: "#6C7A89",
  youtube: "#DDB347",
  programmatic: "#000000",
  spotify: "#1CCC5B",
};

/**
 * Returns a channel colour based on the channel provided.
 * In the case where the channel is a custom channel (and hence doesn't have an existing colour),
 * a random hex colour will be used for for that channel and an entry will be added to the CHANNEL_COLOURS
 * object. Hence, subsequently calling getChannelColour with that custom channel name will return that previously
 * generated colour instead of re-generating a random colour.
 * */
export const getChannelColour = (channel) => {
  if (channel in CHANNEL_COLOURS) {
    return CHANNEL_COLOURS[channel];
  }

  const newColour = getRandomHexColour();
  CHANNEL_COLOURS[channel] = newColour;

  return newColour;
};

/**
 * Given a change value (representing either absolute or percentage changes),
 * returns an object representing text and background colours that
 * correspond to that change.
 * */
export const getChangeColours = (change) => {
  const negative = {
    // negative, red colour
    textColour: "#B42318",
    backgroundColour: "#FEF3F2",
  };

  const positive = {
    // positive, green colour:
    textColour: "#027A48",
    backgroundColour: "#ECFDF3",
  };

  const neutral = {
    // neutral, orange colour:
    textColour: "#DE7A00",
    backgroundColour: "#FFFAEE",
  };

  if (Number.isNaN(change)) {
    return neutral;
  }

  const noChangeCondition = change === 0 ? neutral : positive;
  return change < 0 ? negative : noChangeCondition;
};

export const METRIC_COLOURS = {
  roas: "#0D16FF",
  spend: "#DD4B3E",
  return: "#1EA362",
};

export const ERROR_THEME = {
  backgroundColor: "#FEF1F1",
  color: "#E12D36",
};

export const SUCCESS_THEME = {
  backgroundColor: "#EDFDF8",
  color: "#08875D",
};

export const GRAPH_TICK_COLOR = "#616161";
