import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import OutPointSnackBar from "library/surface/SnackBar";
import { removeMessage } from "redux/snackbarSlice";

export default function SnackbarManager() {
  const dispatch = useDispatch();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const { messages } = useSelector((state) => state.snackbarStore);

  const [currentMessage = ""] = messages;

  useEffect(() => {
    setSnackBarOpen(messages.length > 0);
  }, [messages]);

  return (
    <OutPointSnackBar
      open={snackBarOpen}
      message={currentMessage}
      onClose={() => {
        setSnackBarOpen(false);
        setTimeout(() => dispatch(removeMessage()), 400);
      }}
    />
  );
}
