import React, { useState } from "react";

import Header2 from "library/text/headers/Header2";
import OutPointTextField from "library/form/OutPointTextField";
import PrimaryButton from "library/buttons/PrimaryButton";
import Label from "library/text/body/LabelText";

import { makeAuthenticatedPostRequest } from "utils/backend-api";
import { addMessage } from "redux/snackbarSlice";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import OPDatePicker from "library/form/DatePicker";
import SubHead from "library/text/headers/SubHead";
import { getIngestionData } from "redux/ingestionSlice";
import OPTimePicker from "library/form/TimePicker";
import { CALENDAR_DATE_FORMAT } from "utils/enums";
import { format } from "date-fns";
import BoostDetails from "./BoostDetails";

const styles = {
  header: {
    marginBottom: "30px",
  },
  subHeader: {
    marginTop: "30px",
  },
  updateDetailsGrid: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "25px",
  },
  textfield: {
    width: "230px",
  },
  timePicker: {
    height: "40px",
    width: "230px",
  },
  adsetNameCard: {
    backgroundColor: "#F7F5F5",
    borderRadius: "4px",
    margin: "10px 0",
    padding: "10px",
  },
  buttonDiv: {
    marginTop: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
  button: {
    width: "200px",
  },
};

const budgetRegex = /^([0-9]*([.][0-9]{0,2})?)$/;

const handleUpdate = async (
  channel,
  adsetId,
  investment,
  deadlineDate,
  deadlineTime,
  dispatch,
  setUpdateResponsePending,
  action,
  closeModal,
) => {
  let deadlineDateTime = null;
  if (deadlineDate && deadlineTime) {
    deadlineDateTime = deadlineDate.setHours(
      deadlineTime.getHours(),
      deadlineTime.getMinutes(),
      0,
      0,
    );
  }

  setUpdateResponsePending(true);
  try {
    await makeAuthenticatedPostRequest("boost", {
      channel,
      adsetId,
      investment: parseFloat(investment),
      endTimeStamp:
        deadlineDateTime + deadlineDate.getTimezoneOffset() * 60 * 1000,
      type: action,
    });
    setUpdateResponsePending(false);

    if (action === "boost") {
      dispatch(
        addMessage({
          message: `Your boost of $${investment} has been successfully applied!`,
        }),
      );
      dispatch(getIngestionData());
    } else if (action === "extend") {
      dispatch(
        addMessage({
          message: `Your extension to ${new Date(
            deadlineDateTime,
          )} has been successfully applied!`,
        }),
      );
      dispatch(getIngestionData());
    } else {
      dispatch(
        addMessage({
          message: `Error: Response marked as success, but action is "${action}" is not "boost" or "extend"`,
        }),
      );
    }
  } catch (e) {
    dispatch(
      addMessage({
        message: `Request failed: ${e.message.replaceAll("\n", ", ")}`,
      }),
    );
  }

  closeModal();
};

export default function UpdateModal({
  adsetName,
  currentBudget,
  recommendedInvestment,
  channel,
  adsetId,
  adsetEndTime,
  boostAmount,
  recommendedOn,
  submittedOn,
  expiredOn,
  close,
  header,
  action,
  disableDateTimePicker,
}) {
  const [investment, setInvestment] = useState(recommendedInvestment);
  const [investmentError, setInvestmentError] = useState(false);
  const [deadlineDate, setDeadlineDate] = useState(
    channel === "google"
      ? adsetEndTime
      : format(
          new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
          CALENDAR_DATE_FORMAT,
        ),
  );
  const [deadlineTime, setDeadlineTime] = useState(
    channel === "google" ? adsetEndTime : new Date(),
  );
  const [updateResponsePending, setUpdateResponsePending] = useState(false);

  // eslint-disable-next-line react/no-unstable-nested-components
  function UpdateButton({ children, ...props }) {
    return updateResponsePending ? (
      <LoadingButton loading {...props}>
        {children}
      </LoadingButton>
    ) : (
      <PrimaryButton {...props}>{children}</PrimaryButton>
    );
  }

  const dispatch = useDispatch();

  return (
    <>
      <Header2 sx={styles.header}>{header}</Header2>
      <BoostDetails
        adsetName={adsetName}
        currentBudget={currentBudget}
        recommendedOn={recommendedOn}
        boostAmount={boostAmount}
        submittedOn={submittedOn}
        expiredOn={expiredOn}
      />

      {action === "boost" ? (
        <SubHead sx={styles.subHeader}>Set up your boost</SubHead>
      ) : (
        <div />
      )}

      {action === "boost" ? (
        <div style={styles.updateDetailsGrid}>
          <OutPointTextField
            name="Recommended boost amount"
            disabled
            startAdornment="$"
            value={recommendedInvestment}
            sx={styles.textfield}
          />
          {action === "boost" ? (
            <OutPointTextField
              name="Set boost"
              startAdornment="$"
              value={investment}
              sx={styles.textfield}
              error={investmentError}
              errorMsg="Input must be a valid number"
              onChange={(newInvestment) => {
                if (budgetRegex.test(newInvestment)) {
                  setInvestmentError(false);
                  setInvestment(newInvestment);
                } else {
                  setInvestmentError(true);
                }
              }}
            />
          ) : (
            <div />
          )}
        </div>
      ) : (
        <div />
      )}

      <div style={styles.updateDetailsGrid}>
        <div style={{ paddingTop: "3px" }}>
          <Label
            sx={
              disableDateTimePicker
                ? {
                    marginBottom: "3px",
                    color: "grey",
                  }
                : { marginBottom: "3px", color: "inherit" }
            }
          >
            Deadline date
          </Label>
          <OPDatePicker
            startDate={deadlineDate || null}
            setStartDate={setDeadlineDate}
            disabled={disableDateTimePicker}
            startInputProps={{
              sx: {
                height: "40px",
                width: "230px",
              },
            }}
          />
        </div>
        <div style={{ paddingTop: "3px" }}>
          <Label
            sx={
              disableDateTimePicker
                ? {
                    marginBottom: "3px",
                    color: "grey",
                  }
                : { marginBottom: "3px", color: "inherit" }
            }
          >
            Deadline time
          </Label>
          <OPTimePicker
            time={deadlineTime}
            setTime={setDeadlineTime}
            disabled={disableDateTimePicker}
            inputProps={{
              sx: {
                height: "40px",
                width: "230px",
              },
            }}
          />
        </div>
      </div>

      <div style={styles.buttonDiv}>
        <PrimaryButton sx={styles.button} variant="text" onClick={close}>
          Cancel
        </PrimaryButton>
        <UpdateButton
          sx={styles.button}
          disabled={action === "boost" && (!investment || investment === ".")}
          onClick={() => {
            handleUpdate(
              channel,
              adsetId,
              investment,
              new Date(deadlineDate),
              new Date(deadlineTime),
              dispatch,
              setUpdateResponsePending,
              action,
              close,
            );
          }}
        >
          {action}
        </UpdateButton>
      </div>
    </>
  );
}
