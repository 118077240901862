import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";

import { LoadingButton } from "@mui/lab";

import Header2 from "library/text/headers/Header2";
import Header3 from "library/text/headers/Header3";
import PrimaryButton from "library/buttons/PrimaryButton";
import IconText from "library/containers/IconText";
import OutPointModal from "library/surface/ModalCentered";
import { PERIWINKLE_BLUE } from "assets/palette";
import { cardData } from "pages/connections/data/connectionsData";
import { getJesterData } from "redux/jesterSlice";
import { getIngestionData, getBrands } from "redux/ingestionSlice";
import { goToURL } from "utils/jester-api";
import { formatMetricForDisplay } from "utils/data/strings";
import PlatformCardModalSection from "../PlatformCardModalSection";
import LogoChip from "../LogoChip";
import CredentialsForm from "./CredentialsForm";
import BrandForm from "./BrandForm";
import ParentCredentialForm from "./ParentCredentialForm";
import MetaDataForm from "./MetadataForm";
import TargetMetricForm from "./TargetMetricForm";
import submit from "./utils";

const styles = {
  closeButton: {
    width: "175px",
  },
  capitalize: {
    textTransform: "capitalize",
  },
  textContainer: {
    width: "100%",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    marginTop: "35px",
    justifyContent: "space-between",
  },
  hyperlink: {
    color: PERIWINKLE_BLUE,
    fontWeight: 600,
  },
  iconContainer: {
    display: "flex",
    width: "100%",
    marginBottom: "10px",
  },
  modal: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  line: {
    borderTop: "1px solid #E3E6EC",
    width: "100%",
    margin: "20px 0 20px 0",
  },
  logoChip: {
    margin: "0 15px 10px 0",
  },
};

const formatMetricForDatabase = (metric) => {
  if (metric) return metric.toLowerCase().replaceAll(" ", "_");
  return null;
};

function PlatformCardModalForms({
  channel,
  description,
  credentialId,
  credentials,
  whereFrom,
  setModalOpen,
  tokenType,
}) {
  const { logo, SSOButton } = cardData[channel] || {};

  const dispatch = useDispatch();
  const refetchData = async () => {
    await Promise.all([
      dispatch(getJesterData()),
      dispatch(getIngestionData()),
      dispatch(getBrands()),
    ]);
  };
  const authenticatePlatform = () =>
    goToURL(channel, credentialId, credentials, whereFrom, tokenType);

  // Values for form data defaults from database
  const fields =
    useSelector(
      (state) => state.jester?.fields?.data?.[tokenType]?.[channel],
    ) || [];
  const hasCredentials = credentials?.credentials;

  const currentMetadata = credentials?.metadata || {};

  const currentBrands = credentials?.brand ?? [];
  const brands = useSelector((state) => state.ingestion.brands) || [];

  const currentKpi = formatMetricForDisplay(credentials?.target_metric);
  const currentBenchmark = credentials?.benchmark_value;
  const currentImpressionThreshold = credentials?.impression_threshold;
  const currentBuyingUnit = credentials?.buying_unit;
  const defaultShouldUpdateExistingAdsets = false;

  const currentParentId = credentials?.parent_credential_id;

  const metricsData = useSelector((state) => state.metrics)?.data || [];
  const displayMetricsData = metricsData.filter(
    (metric) => metric.channel === null || metric.channel === channel,
  );

  // Local variables for modal forms
  const [metadataForm, setMetadataForm] = useState(currentMetadata ?? {});
  const [metadataErrors, setMetadataErrors] = useState({});
  const [canEditMetadata, setCanEditMetadata] = useState({});

  const [brandFormList, setBrandFormList] = useState(currentBrands);
  const [parentCredentialId, setParentCredentialId] = useState(
    currentParentId || "",
  );

  const [kpi, setKpi] = useState(currentKpi ?? "");
  const [benchmark, setBenchmark] = useState(currentBenchmark ?? "");
  const [impressionThreshold, setImpressionThreshold] = useState(
    currentImpressionThreshold ?? "",
  );
  const [buyingUnit, setBuyingUnit] = useState(currentBuyingUnit ?? "");
  const [shouldUpdateExistingAdsets, setShouldUpdateExistingAdsets] =
    useState(false);
  const [isBenchmarkError, setIsBenchmarkError] = useState(false);
  const [isImpressionThresholdError, setIsImpressionThresholdError] =
    useState(false);
  const [isBuyingUnitError, setIsBuyingUnitError] = useState(false);

  const [sectionStates, setSectionStates] = useState({
    credentials: true,
    metadata: true,
    brand: true,
    targetMetric: true,
    parentCredentialId: true,
  });

  const [requestPending, setRequestPending] = useState(false);

  const closeModals = () => setModalOpen(false);

  useEffect(() => {
    const newSectionState = {
      credentials: hasCredentials,
      metadata: fields
        .map((field) => currentMetadata[field])
        .reduce((field, acc) => field && acc, true),
      targetMetric:
        !!currentKpi &&
        !!currentBenchmark &&
        !!currentImpressionThreshold &&
        !!currentBuyingUnit,
      brand: brandFormList,
      parentCredentialId,
    };

    setSectionStates(newSectionState);
  }, [credentials]);

  const dataSubmit = [
    {
      name: "metadata",
      data: metadataForm,
      checkSubmit: () => {
        let canSubmitMetadata = true;
        const errorsObject = {};
        fields.forEach((field) => {
          const fieldErrored = !metadataForm[field];
          errorsObject[field] = fieldErrored;
          canSubmitMetadata = canSubmitMetadata && !fieldErrored;
        });
        setMetadataErrors(errorsObject);

        return canSubmitMetadata;
      },
      checkEquality: () => currentMetadata !== metadataForm,
    },
  ];

  if (tokenType === "ad") {
    dataSubmit.push({
      name: "brand",
      data: {
        brands: brandFormList,
        brands_original: currentBrands,
      },
      checkSubmit: () => true,
      checkEquality: () => !isEqual(currentBrands, brandFormList),
    });

    dataSubmit.push({
      name: "target_metric",
      data: {
        credential_id: credentialId,
        target_metric: formatMetricForDatabase(kpi),
        benchmark_value: Number(benchmark),
        impression_threshold: Number(impressionThreshold),
        buying_unit: Number(buyingUnit),
        is_integration_level_change: true,
        should_update_existing_adsets: shouldUpdateExistingAdsets,
      },
      checkSubmit: () => {
        const canSubmit =
          kpi &&
          benchmark &&
          impressionThreshold &&
          buyingUnit &&
          !isBenchmarkError &&
          !isImpressionThresholdError &&
          !isBuyingUnitError;

        setIsBenchmarkError(!benchmark || isBenchmarkError);
        setIsImpressionThresholdError(
          !impressionThreshold || isImpressionThresholdError,
        );
        setIsBuyingUnitError(!buyingUnit || isBuyingUnitError);

        return canSubmit;
      },
      checkEquality: () =>
        benchmark !== currentBenchmark ||
        kpi !== currentKpi ||
        impressionThreshold !== currentImpressionThreshold ||
        buyingUnit !== currentBuyingUnit ||
        shouldUpdateExistingAdsets !== defaultShouldUpdateExistingAdsets,
    });
  }

  if (tokenType === "page") {
    dataSubmit.push({
      name: "parent_id",
      data: parentCredentialId,
      checkSubmit: () => !!parentCredentialId,
      checkEquality: () => true,
    });
  }

  // eslint-disable-next-line react/no-unstable-nested-components, no-shadow
  function ContinueButton({ children, ...props }) {
    return requestPending ? (
      <LoadingButton loading {...props}>
        {children}
      </LoadingButton>
    ) : (
      <PrimaryButton {...props}>{children}</PrimaryButton>
    );
  }

  const sections = [
    <PlatformCardModalSection
      key="signin"
      title="Start by signing in to your account."
      form={
        <CredentialsForm
          storedMetadata={currentMetadata}
          hasCredentials={hasCredentials}
          tokenType={tokenType}
          channel={channel}
          credentialId={credentialId}
          closeModals={closeModals}
          refetchData={refetchData}
          SSOButton={SSOButton}
          authenticatePlatform={authenticatePlatform}
        />
      }
      done={sectionStates.credentials}
    />,
    <PlatformCardModalSection
      key="metadata"
      title="Enter your Account ID"
      form={
        <MetaDataForm
          fields={fields}
          canEditMetadata={canEditMetadata}
          setCanEditMetadata={setCanEditMetadata}
          storedMetadata={currentMetadata}
          editedMetadata={metadataForm}
          setEditedMetadata={setMetadataForm}
          metadataFieldErrorStatuses={metadataErrors}
          setMetadataFieldErrorStatuses={setMetadataErrors}
        />
      }
      done={sectionStates.metadata}
    />,
  ];

  if (tokenType === "ad") {
    sections.push(
      <PlatformCardModalSection
        key="kpi"
        title="Set your KPI and benchmark"
        form={
          <TargetMetricForm
            displayMetricsData={displayMetricsData}
            kpi={kpi}
            setKpi={setKpi}
            benchmark={benchmark}
            setBenchmark={setBenchmark}
            impressionThreshold={impressionThreshold}
            setImpressionThreshold={setImpressionThreshold}
            buyingUnit={buyingUnit}
            setBuyingUnit={setBuyingUnit}
            shouldUpdateExistingAdsets={shouldUpdateExistingAdsets}
            setShouldUpdateExistingAdsets={setShouldUpdateExistingAdsets}
            isBenchmarkError={isBenchmarkError}
            setIsBenchmarkError={setIsBenchmarkError}
            isImpressionThresholdError={isImpressionThresholdError}
            setIsImpressionThresholdError={setIsImpressionThresholdError}
            isBuyingUnitError={isBuyingUnitError}
            setIsBuyingUnitError={setIsBuyingUnitError}
          />
        }
        done={sectionStates.targetMetric}
      />,
    );
    sections.push(
      <PlatformCardModalSection
        key="brand"
        title="Add a brand tag for this account (Optional)"
        form={
          <BrandForm
            brands={brands}
            brandFormList={brandFormList}
            setBrandFormList={setBrandFormList}
          />
        }
        done={sectionStates.brand?.length > 0}
      />,
    );
  }

  if (tokenType === "page") {
    sections.push(
      <PlatformCardModalSection
        key="adcredential"
        title="Assign to ad credential"
        form={
          <ParentCredentialForm
            channel={channel}
            parentId={parentCredentialId}
            setParentId={setParentCredentialId}
          />
        }
        done={sectionStates.parentCredentialId}
      />,
    );
  }

  const handleModalSubmission = async () => {
    const success = await submit(
      credentialId,
      channel,
      description,
      tokenType,
      refetchData,
      dataSubmit,
      setRequestPending,
    );
    if (success) {
      closeModals();
    }
  };

  return (
    <>
      <div style={styles.textContainer}>
        <IconText sx={styles.iconContainer}>
          <LogoChip sx={styles.logoChip} logo={logo} channel={channel} />
          <Header2>{description}</Header2>
        </IconText>
        <Header3>
          Connect to <span style={styles.capitalize}>{channel}</span>:
        </Header3>
      </div>

      {sections.map((section, i) => (
        <>
          {section}
          <div key={`line${i}`} style={styles.line} />
        </>
      ))}

      <div style={styles.buttonContainer}>
        <PrimaryButton
          sx={styles.closeButton}
          variant="text"
          color="primary"
          onClick={closeModals}
        >
          Cancel
        </PrimaryButton>
        <ContinueButton sx={styles.closeButton} onClick={handleModalSubmission}>
          Continue
        </ContinueButton>
      </div>
    </>
  );
}

export default function PlatformCardModal({
  modalOpen,
  setModalOpen,
  ...rest
}) {
  return (
    <OutPointModal
      modalOpen={modalOpen}
      setModalOpen={() => setModalOpen(false)}
      sx={styles.modal}
    >
      <PlatformCardModalForms {...rest} setModalOpen={setModalOpen} />
    </OutPointModal>
  );
}
