import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice({
  name: "loginSlice",
  initialState: {
    authStatus: false,
  },
  reducers: {
    authSuccess: (state) => {
      state.authStatus = true;
    },
    authFailed: (state) => {
      state.authStatus = false;
    },
  },
});

export const { authSuccess, authFailed } = loginSlice.actions;
export default loginSlice.reducer;
