import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, Tooltip, Box } from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useSelector } from "react-redux";
import OutPointModal from "library/surface/ModalCentered";
import Header3 from "library/text/headers/Header3";
import { sortBy, keyBy, isEmpty } from "lodash";
import { createSelectorForGranularityLevel } from "../../../redux/postsSlice";
import {
  TaxonomyInputField,
  determineInitialUserInput,
} from "./TaxonomyInputField";
import encodeTaxonomyDetails from "../../../utils/dataset/taxonomy";
import { dateStringToMmdd } from "../../../utils/data/dates";

const createInitialFormAttrsMap = (relevantOptions, post) => {
  const prunedOptions = relevantOptions.map((option) => {
    const attrName = option.name;
    let attrValFromPost = post?.[attrName];

    // reformats date for form purposes (into MMdd):
    if (attrName === "launch_date") {
      const formatted = dateStringToMmdd(attrValFromPost);
      attrValFromPost = formatted;
    }
    /// copies from airtable data only:
    if (attrName === "brand") {
      const brandValInAirtable = post?.airtable_data_brand;
      attrValFromPost = brandValInAirtable;
    }

    /// special case: as of v3.0 latest, there's an intentional duplication of values
    if (attrName === "size_or_duration") {
      const creativeExecSizeOrDuration = post?.creative_exec_size_or_duration;
      attrValFromPost = creativeExecSizeOrDuration;
    }

    /// special case: as of v3.0 Feb 1 2024, these two unbounded fields got re-introduced but
    //  in order to avoid making any more changes to the airtable, they won't get any more values
    //  their actual value will always remain as "NA" (unless otherwise advicsed by the biz side)
    const attributesToBeNA = [
      "copydescription",
      "version",
      "dam_id",
      "po_number",
    ];
    if (attributesToBeNA.includes(attrName)) {
      attrValFromPost = "NA";
    }

    const initialValue = determineInitialUserInput(attrValFromPost, option);

    const initialEntry = {
      name: attrName,
      currentValue: initialValue,
    };

    return initialEntry;
  });
  const initialMap = keyBy(prunedOptions, (attr) => attr.name);

  return initialMap;
};

export function GeneratedNameDisplay({ generatedName = "" }) {
  const handleClick = async () => {
    try {
      await navigator.clipboard.writeText(generatedName);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(`Failed to copy ${generatedName} to the clipboard`, err);
    }
  };

  if (!generatedName) {
    return null;
  }

  const copyIcon = (
    <Tooltip
      title={<Typography variant="body1">Copy to clipboard.</Typography>}
      placement="right"
      arrow
      noWrap={false}
    >
      <ContentCopyIcon fontSize="small" />
    </Tooltip>
  );

  return (
    <Grid container item>
      <Button
        component="div"
        onClick={handleClick}
        sx={{
          background: "#F9F9F9",
          margin: "8px",
          padding: "16px",
          maxWidth: "800px",
          maxHeight: "200px",
        }}
      >
        <Grid item container direction="column">
          <Grid
            container
            direction="row"
            item
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Grid item xs={8}>
              Generated name:
            </Grid>
            <Grid item xs={1}>
              {copyIcon}
            </Grid>
          </Grid>

          <Grid item>
            <Box maxWidth="100%">
              <Typography
                variant="body2"
                noWrap={false}
                sx={{
                  whiteSpace: "pre-wrap",
                  wordBreak: "break-all",
                  width: "100%",
                }}
              >
                {generatedName}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Button>
    </Grid>
  );
}

export default function TaxonomyForm({ granularityLevel, post, onNameUpdate }) {
  const relevantTaxonomyOptions = sortBy(
    useSelector(createSelectorForGranularityLevel(granularityLevel)),
    (option) => option.attribute_order,
  );
  const granularityLevelDisplayName =
    relevantTaxonomyOptions?.[0]?.granularity_level_display_name;
  const [currentFormAttributes, setCurrentFormAttributes] = useState(
    createInitialFormAttrsMap(relevantTaxonomyOptions, post),
  );
  const [currentGeneratedName, setCurrentGeneratedName] = useState(
    encodeTaxonomyDetails(relevantTaxonomyOptions, currentFormAttributes),
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  /**
   * Creates a value setter for a particular attribute name. This value setter
   * shall update the current form attributes that is kept in state.
   * */
  const createFormValueSetter = (attrName) => (attrVal) => {
    const isUnhandledAttributeName = !Object.keys(
      currentFormAttributes,
    ).includes(attrName);
    const updatedAttr = {
      ...currentFormAttributes[attrName],
      currentValue: attrVal,
    };
    let updatedAttrs = { ...currentFormAttributes, [attrName]: updatedAttr };

    if (attrName === "creative_exec_size_or_duration") {
      const duplicateAttrName = "size_or_duration";
      const updatedDuplicateAttr = {
        ...currentFormAttributes[duplicateAttrName],
        currentValue: attrVal,
      };
      updatedAttrs = {
        ...updatedAttrs,
        [duplicateAttrName]: updatedDuplicateAttr,
      };
    }

    if (isUnhandledAttributeName) {
      // eslint-disable-next-line no-console
      console.warn(
        `${attrName} is not relevant to the current granularity level of ${granularityLevel}.`,
      );
    }
    setCurrentFormAttributes(updatedAttrs);
  };

  /// updates the generated name when the current form attributes change:
  useEffect(
    function updateName() {
      const generatedName = encodeTaxonomyDetails(
        relevantTaxonomyOptions,
        currentFormAttributes,
      );
      if (generatedName) {
        setCurrentGeneratedName(generatedName);
        onNameUpdate(generatedName);
      }
    },
    [currentFormAttributes],
  );

  const isReadyToDisplay =
    relevantTaxonomyOptions &&
    currentFormAttributes &&
    !isEmpty(relevantTaxonomyOptions) &&
    !isEmpty(currentFormAttributes);

  if (!isReadyToDisplay) {
    // returns null when data is not ready
    return null;
  }

  const formContents = (
    <Grid container direction="column">
      <Grid container item>
        <Grid item>
          <Header3 sx={{ margin: "16px" }}>
            Edit the {granularityLevelDisplayName} name
          </Header3>
        </Grid>
        <Grid container item>
          <GeneratedNameDisplay generatedName={currentGeneratedName} />
        </Grid>
      </Grid>
      <Grid item container direction="column">
        {Object.entries(relevantTaxonomyOptions).map(([idx, attributeInfo]) => {
          const attributeName = attributeInfo.name;
          const attrValFromPost =
            currentFormAttributes?.[attributeName]?.currentValue;

          return (
            <Grid key={idx} item sx={{ margin: "8px", padding: "8px" }}>
              <TaxonomyInputField
                id={idx}
                setAttributeValue={createFormValueSetter(attributeInfo.name)}
                attrValFromPost={attrValFromPost}
                attributeInfo={attributeInfo}
              />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );

  const modal = (
    <OutPointModal
      modalOpen={isModalOpen}
      setModalOpen={setIsModalOpen}
      sx={{ maxWidth: "2000px" }}
    >
      {formContents}
    </OutPointModal>
  );

  const modalTrigger = (
    <Grid
      container
      onClick={() => setIsModalOpen(!isModalOpen)}
      sx={{ width: "100%" }}
    >
      <Grid item xs={12}>
        {granularityLevelDisplayName}
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" sx={{ width: "100%", marginTop: "8px" }}>
          <Typography variant="body4" noWrap>
            {currentGeneratedName}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Grid>
      {modalTrigger}
      {modal}
    </Grid>
  );
}
