import { createTheme } from "@mui/material";

const POPPINS = {
  fontFamily: "Poppins",
  fontWeight: "600",
  letterSpacing: "0.342857px",
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#D62B5E",
    },
    secondary: {
      main: "rgba(0, 0, 0, 0.6)",
    },
  },
  typography: {
    h1: POPPINS,
    h2: POPPINS,
    h3: POPPINS,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
  },
});

export default theme;
