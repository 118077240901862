import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import { STATUS } from "utils/enums";

export const getTimeOfDayData = createAsyncThunk(
  "asyncRedux/timeOfDay",
  async () => {
    return makeAuthenticatedGetRequest("intervals");
  },
);

const timeOfDaySlice = createSlice({
  name: "timeOfDaySlice",
  initialState: {
    data: null,
    status: null,
  },
  reducers: {
    timeOfDayLogOut: (state, _) => {
      state.data = null;
      state.status = null;
    },
  },
  extraReducers: {
    [getTimeOfDayData.pending]: (state, _) => {
      state.status = STATUS.LOADING;
    },
    [getTimeOfDayData.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.status = STATUS.SUCCESS;
    },
    [getTimeOfDayData.rejected]: (state, _) => {
      state.status = STATUS.FAILED;
    },
  },
});

export const { timeOfDayLogOut } = timeOfDaySlice.actions;

export default timeOfDaySlice.reducer;
