import React from "react";
import { useNavigate } from "react-router-dom";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";
import { THEME } from "assets/palette";

const styles = {
  hyperlink: {
    color: THEME.primary.main,
    textDecoration: "underline",
    fontFamily: "IBM Plex Sans",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
    cursor: "pointer",
  },
};

export default function Hyperlink({
  children,
  where,
  sx = {},
  newPage,
  onClick,
  ...rest
}) {
  const navigateFunc = useNavigate();

  const navigate = () => {
    if (newPage) {
      window.open(where);
    } else if (where.slice(0, 4) === "http") {
      window.location = where;
    } else {
      navigateFunc(where);
    }
  };

  let onClickFunction = () => {};
  if (where) onClickFunction = navigate;
  if (onClick) onClickFunction = onClick;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <a
      onClick={onClickFunction}
      style={{ ...styles.hyperlink, ...sx, cursor: "pointer" }}
      {...rest}
    >
      {children}
    </a>
  );
}
