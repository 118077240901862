import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  IconButton,
} from "@mui/material";

import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import CloseIcon from "@mui/icons-material/Close";

import { FAINT_BLUE, NEUTRAL } from "assets/palette";
import SubHead from "library/text/headers/SubHead";
import SubHeadLight from "library/text/headers/SubHeadLight";
import { capitalize } from "lodash";

const styles = {
  formControl: {
    minWidth: 120,
  },
  selectStyle: {
    borderRadius: "8px",
    height: "29px",
    border: "none",
    // lineHeight: '20px',
    backgroundColor: NEUTRAL,
  },
  selectTextStyle: {
    fontSize: "14px",
    color: "#787071",
  },
  menuItemStyle: {
    display: "flex",

    alignItems: "center",
    padding: "16px 24px",
    gap: "10px",

    // width: '132px',
    height: "56px",
  },
};

export default function OutPointDropdown({
  onChange,
  menuItems = [],
  selectedValue,
  leading = "", // leading value
  trailing = "", // trailing value
  StartAdornment = () => <div />,
  startAdornmentStyle = { marginRight: 5 },
  sx = {},
  multiple = false,
  menuItemAdornment = {},
  isSentenceCaseMenu = true,
  renderValueOverride = null, // Supply a custom component render value
  onClear = null,
  defaultDisplay = "",
  ...rest
}) {
  const handleChange = (event) => {
    const eventTargetValue = event.target.value;

    if (!multiple) {
      return onChange(eventTargetValue);
    }

    const values = JSON.parse(JSON.stringify(selectedValue));
    const [, selectedTargetValue] = eventTargetValue;

    const indexOfValue = values.indexOf(selectedTargetValue);

    if (indexOfValue > -1) {
      values.splice(indexOfValue, 1);

      return onChange(values);
    }

    values.push(selectedTargetValue);

    // add
    return onChange(values);
  };

  let formattedMenuItems = menuItems.map((item) => {
    return {
      display: item,
      decoratedDisplay: `${leading}${item}${trailing}`,
    };
  });

  const displayValue =
    typeof selectedValue === "string" && isSentenceCaseMenu
      ? capitalize(selectedValue)
      : selectedValue;
  let value = `${leading}${displayValue || defaultDisplay}${trailing}`;

  if (multiple) {
    formattedMenuItems = menuItems.map((item) => {
      return {
        display: item,
      };
    });

    value = [`${leading}${selectedValue.length}${trailing}`];
  }

  return (
    <FormControl required sx={styles.formControl}>
      <Select
        value={value}
        multiple={multiple}
        onChange={handleChange}
        startAdornment={<StartAdornment style={startAdornmentStyle} />}
        renderValue={() => {
          if (renderValueOverride) return renderValueOverride();

          return (
            <SubHead sx={styles.selectTextStyle} isSentenceCase={false}>
              {value}
            </SubHead>
          );
        }}
        IconComponent={
          onClear && selectedValue
            ? (props) => (
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    onClear(e);
                  }}
                  sx={{
                    height: "17px",
                    width: "17px",
                    marginTop: "3px",
                  }}
                >
                  <CloseIcon
                    {...props}
                    sx={{ height: "17px", width: "17px" }}
                  />
                </IconButton>
              )
            : ArrowDropDownRoundedIcon
        }
        MenuProps={{
          PaperProps: {
            sx: {
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: "white",
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: FAINT_BLUE,
              },
            },
          },
        }}
        sx={{
          "& fieldset": { border: "none" },
          ...styles.selectStyle,
          ...sx,
        }}
        {...rest}
      >
        {formattedMenuItems.map(({ display = "" }) => {
          const { itemValue, position } = menuItemAdornment;

          let menuItemDisplay = display;

          if (position === "start" && itemValue) {
            menuItemDisplay = `${itemValue}${menuItemDisplay}`;
          }

          if (position === "end" && itemValue) {
            menuItemDisplay = `${menuItemDisplay}${itemValue}`;
          }

          return (
            <MenuItem key={display} value={display} sx={styles.menuItemStyle}>
              {multiple && (
                <Checkbox
                  hidden={!display}
                  checked={selectedValue.indexOf(display) > -1}
                />
              )}
              <SubHeadLight isSentenceCase={isSentenceCaseMenu}>
                {menuItemDisplay}
              </SubHeadLight>
            </MenuItem>
          );
        })}
        {/* {
        MuI doesn't allow a different value option if not included in menu options

        Render shadow menu items that are hidden to the user
        The purpose of this is two fold:

        1. Meet design requirements where the display to the user includes formatting to the
        menu items.
          Example:
            - menu items = [1, 2, 3] (if the user clicks 1, the value is 1)
            However design requirements: show the user the menu Items as above when selecting, but once selected, add formatting. Like so:
            - "You selected: 1" (<- this is the "value")
          items.include(value) === false, and therefore is not permitted.
        
        The solution: rendering shadow hidden items as below to silence the warnings
      } */}
        {(!!leading || !!trailing) &&
          formattedMenuItems.map(({ decoratedDisplay }) => (
            <MenuItem
              key={decoratedDisplay}
              value={decoratedDisplay}
              sx={{ display: "none" }}
            >
              <SubHeadLight isSentenceCase={false}>
                {decoratedDisplay}
              </SubHeadLight>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
