import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Grid,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import OPAutoComplete from "library/form/OutPointAutoComplete";
import PrimaryButton from "library/buttons/PrimaryButton";
import { jesterPostRequest } from "utils/jester-api";
import { getIngestionData } from "redux/ingestionSlice";
import { getTaggingOptions } from "redux/postsSlice";
import Header3 from "library/text/headers/Header3";
import Spinner from "../../../library/progress/Spinner";

const createDataRefetcher = (dispatch) => {
  return async () => {
    await Promise.all([
      dispatch(getIngestionData()),
      dispatch(getTaggingOptions()),
    ]);
  };
};

/*
 * Given serialised tags of the form tag_name:tag_type, create a list of objects using information
 * present from the taggingOptions.
 */
const deserialiseTags = (serialisedTags, taggingOptions) => {
  const TAG_DELIM = ",";
  const TAG_NAME_TAG_TYPE_DELIM = ":";
  if (!serialisedTags) {
    // eslint-disable-next-line no-console
    console.warn(
      "There are no serialised tags for this adset that can be deserialised.",
    );
    return [];
  }

  const currentTags = serialisedTags.split(TAG_DELIM).map((substr) => {
    const [tagType, tagName] = substr.split(TAG_NAME_TAG_TYPE_DELIM);
    const correspondingTagType = taggingOptions?.[tagType];
    const correspondingTagOption = correspondingTagType?.find(
      (tag) => tag.tag_name === tagName,
    );

    return {
      tagName,
      tagType,
      id: correspondingTagOption?.id,
    };
  });

  return currentTags;
};

const serialiseTags = (tags) => {
  const serialised = tags
    .map(({ tagType, tagName }) => `${tagType}:${tagName}`)
    .join(",");
  return serialised;
};

function NewTagForm({
  isSubmissionPending,
  handleNewTag,
  taggingOptions,
  currentTags,
}) {
  const [selectedTaggingType, setSelectedTaggingType] = useState();
  const [selectedTagName, setSelectedTagName] = useState();

  const taggingTypes = Object.keys(taggingOptions);
  const taggingTypeSelector = (
    <OPAutoComplete
      name="Select Tag Type"
      key="Tag Type"
      options={taggingTypes}
      value={selectedTaggingType}
      autoComplete
      selectOnFocus
      handleHomeEndKeys
      openOnFocus
      forcePopupIcon
      onChange={(_, val) => setSelectedTaggingType(val)}
    />
  );

  const tagNameOptions = selectedTaggingType
    ? taggingOptions?.[selectedTaggingType].map((option) => option.tag_name)
    : [];

  const tagNameSelector = (
    <OPAutoComplete
      name="Select Tag Name"
      key="Tag Name"
      disabled={tagNameOptions.length === 0}
      options={tagNameOptions}
      value={selectedTagName}
      autoComplete
      selectOnFocus
      handleHomeEndKeys
      openOnFocus
      forcePopupIcon
      onChange={(_, val) => setSelectedTagName(val)}
    />
  );

  const isNewTagExisting = currentTags.find(
    (tag) =>
      tag.tagName === selectedTagName && tag.tagType === selectedTaggingType,
  );
  const canAddNewTag =
    !isSubmissionPending &&
    selectedTaggingType &&
    selectedTagName &&
    !isNewTagExisting;

  const handleAddingNewTag = () => {
    if (!selectedTaggingType || !selectedTagName) {
      return;
    }

    const isLegalTagType =
      Object.keys(taggingOptions).includes(selectedTaggingType);
    // eslint-disable-next-line no-console
    console.assert(
      isLegalTagType,
      "Tagging types are bounded values hence there may never be a brand new type of tag that is supported in the frontend.",
    );

    const matchingExistingTag = taggingOptions?.[selectedTaggingType].find(
      (option) =>
        option.tag_name === selectedTagName &&
        option.tag_type === selectedTaggingType,
    );
    const matchingExistingId = matchingExistingTag?.id;

    const newTag = {
      id: matchingExistingId,
      tagName: selectedTagName,
      tagType: selectedTaggingType,
    };
    handleNewTag(newTag);
  };

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item xs={4} sx={{ margin: "2px" }}>
        {taggingTypeSelector}
      </Grid>
      <Grid item xs={4} sx={{ margin: "2px" }}>
        {tagNameSelector}
      </Grid>
      <Grid item xs={3}>
        <PrimaryButton
          type="submit"
          disabled={!canAddNewTag}
          onClick={() => handleAddingNewTag()}
        >
          Add Tag
        </PrimaryButton>
      </Grid>
    </Grid>
  );
}

function AdsetTaggingForm({ rowData, serialisedTags, setSerialisedTags }) {
  const { adset_id: adsetId } = rowData;

  const taggingOptions =
    useSelector((state) => state.postsData)?.taggingOptions?.data || {};
  let originalTags = deserialiseTags(serialisedTags, taggingOptions);
  const dispatch = useDispatch();
  const refetchData = createDataRefetcher(dispatch);
  const [currentTags, setCurrentTags] = useState(originalTags);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [isSubmissionPending, setIsSubmissionPending] = useState(false);

  const submitNewTags = async (newTags) => {
    const payload = {
      adset_id: adsetId,
      tags: newTags,
      original_tags: originalTags,
    };
    try {
      setIsSubmissionPending(true);
      await jesterPostRequest("update_adset_tag", payload);
      originalTags = newTags;
      setCurrentTags(newTags);
      setSerialisedTags(serialiseTags(newTags));
      await refetchData();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Couldn't submit the new tags", payload);
    } finally {
      setIsSubmissionPending(false);
    }
  };

  const handleTagDelete = async (deletedTag) => {
    const { tagName: deletedTagName, tagType: deletedTagType } = deletedTag;
    // filters out symantically because a brand new tag won't have an id to filter using
    const newTags = currentTags.filter((currentTag) => {
      const { tagName: currentTagName, tagType: currentTagType } = currentTag;

      const isTargettedForDelete =
        deletedTagName === currentTagName && currentTagType === deletedTagType;
      return !isTargettedForDelete;
    });
    await submitNewTags(newTags);
  };

  const currentTagChips = currentTags.map((tag) => {
    const chipLabel = `${tag.tagType}:${tag.tagName}`;
    return (
      <Chip
        sx={{ margin: "2px" }}
        label={chipLabel}
        variant="outlined"
        onDelete={() => handleTagDelete(tag)}
      />
    );
  });

  const handleNewTag = async (newTag) => {
    const newTags = [...currentTags];
    newTags.push(newTag);

    await submitNewTags(newTags);
  };

  return (
    <Accordion
      sx={{
        borderRadius: "8px",
        marginTop: "8px",
        boxShadow: "none",
        border: "1px solid lightgrey",
        "&:before": {
          display: "none",
        },
      }}
      onChange={() => setIsAccordionOpen(!isAccordionOpen)}
    >
      <AccordionSummary
        expandIcon={isAccordionOpen ? <RemoveCircleIcon /> : <AddCircleIcon />}
      >
        <Grid container direction="column">
          <Grid item>
            <Header3>Adset Tags:</Header3>
          </Grid>
          <Grid
            sx={{
              margin: "8px",
              padding: "8px",
              marginLeft: "0px",
              paddingLeft: "2px",
            }}
            item
          >
            {isSubmissionPending ? <Spinner /> : currentTagChips}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <NewTagForm
          isSubmissionPending={isSubmissionPending}
          handleNewTag={handleNewTag}
          taggingOptions={taggingOptions}
          currentTags={currentTags}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default AdsetTaggingForm;
