import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import { STATUS } from "utils/enums";

export const getMetricsData = createAsyncThunk(
  "asyncRedux/metrics",
  async () => {
    return makeAuthenticatedGetRequest("metrics");
  },
);

const metricsSlice = createSlice({
  name: "metricsSlice",
  initialState: {
    data: null,
    status: null,
  },
  reducers: {
    metricsLogOut: (state, _) => {
      state.data = null;
      state.status = null;
    },
  },
  extraReducers: {
    [getMetricsData.pending]: (state, _) => {
      state.status = STATUS.LOADING;
    },
    [getMetricsData.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.status = STATUS.SUCCESS;
    },
    [getMetricsData.rejected]: (state, _) => {
      state.status = STATUS.FAILED;
    },
  },
});

export const { metricsLogOut } = metricsSlice.actions;

export default metricsSlice.reducer;
