import React, { useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowUp from "@mui/icons-material/ArrowUpward";
import ArrowDown from "@mui/icons-material/ArrowDownward";

const styles = {
  tableBody: {
    backgroundColor: "white",
  },
  tableHeader: {
    backgroundColor: "#F7F5F5",
  },
  headerText: {
    fontSize: "12px",
    fontWeight: "bold",
  },
  arrowIcon: {
    fontSize: "18px",
  },
  smallHeader: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
  smallSubHeader: {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
};

/* Given a list of rows and columns, where there may be multiple rows associated to a single group 
(ie. by "rowName" which is rendered under the left-most column) render a simple table 
*/
function BasicTable({
  columnHeaders,
  headerGroups = null,
  rowData = [],
  sortable = false,
  tableHeight,
  aggregationRowReducerFn = null,
}) {
  // rows should be of the form: { rowName: [ { column1: value, column2: value, ... } ] }
  // columnHeaders should be of the form [ { displayName, columnName, formattingFunction?, align? = right}, ... ]

  const [sortColumn, setSortColumn] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);

  let displayData = rowData;

  if (sortable && sortColumn && sortDirection) {
    const sortFunctions = columnHeaders.filter(
      ({ columnName }) => columnName === sortColumn,
    )[0]?.sortingFunctions;

    let comparisonFunc = sortFunctions
      ? sortFunctions.desc
      : (a, b) => (a[sortColumn] < b[sortColumn] ? 1 : -1);
    if (sortDirection === "asc") {
      comparisonFunc = sortFunctions
        ? sortFunctions.asc
        : (a, b) => (a[sortColumn] > b[sortColumn] ? 1 : -1);
    }
    displayData = [...rowData].sort(comparisonFunc);
  }

  const aggregationRow = aggregationRowReducerFn
    ? rowData.reduce(aggregationRowReducerFn, {})
    : null;

  const handleHeaderClick = (column) => {
    if (sortable) {
      if (sortColumn !== column) {
        setSortColumn(column);
        setSortDirection("desc");
      } else if (sortDirection === "desc") {
        setSortDirection("asc");
      } else if (sortDirection === "asc") {
        setSortColumn(null);
        setSortDirection(null);
      }
    }
  };

  const ArrowIcon = sortDirection === "asc" ? ArrowUp : ArrowDown;

  const getBorderRadius = (i, length) => {
    if (i === 0) return "8px 0 0 0";
    if (i === length - 1) return "0px 8px 0 0";
    return "auto";
  };

  return (
    <div style={{ display: "block", overflowX: "auto", whiteSpace: "nowrap" }}>
      <TableContainer sx={{ maxHeight: tableHeight }}>
        <Table stickyHeader>
          <TableHead
            sx={{
              position: headerGroups?.length > 0 ? "sticky" : "auto",
              top: 0,
            }}
          >
            {headerGroups?.length > 0 && (
              <TableRow>
                {headerGroups.map(
                  ({ displayName, colSpan = 1, sx = {} }, i) => (
                    <TableCell
                      key={i}
                      colSpan={colSpan}
                      style={{
                        "&:hover": {
                          color: "red",
                        },
                        borderRadius: getBorderRadius(i, headerGroups.length),
                        border: "none",
                      }}
                      sx={{
                        ...styles.headerText,
                        ...styles.tableHeader,
                        ...styles.smallHeader,
                        ...sx,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        {displayName}
                      </div>
                    </TableCell>
                  ),
                )}
              </TableRow>
            )}
            <TableRow>
              {columnHeaders.map(
                ({ columnName, displayName, align, sx = {} }, i) => (
                  <TableCell
                    key={i}
                    onClick={() =>
                      displayName && sortable && handleHeaderClick(columnName)
                    }
                    style={{
                      cursor: displayName && sortable ? "pointer" : "auto",
                      "&:hover": {
                        color: "red",
                      },
                      borderRadius: headerGroups
                        ? 0
                        : getBorderRadius(i, columnHeaders.length),
                    }}
                    sx={
                      headerGroups?.length > 0
                        ? {
                            ...styles.headerText,
                            ...styles.tableHeader,
                            ...styles.smallSubHeader,
                            ...sx,
                          }
                        : { ...styles.headerText, ...styles.tableHeader, ...sx }
                    }
                  >
                    <div
                      style={{
                        cursor: displayName && sortable ? "pointer" : "auto",
                        "&:hover": {
                          color: "red",
                        },
                        borderRadius: getBorderRadius(i),
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          justifyContent:
                            // eslint-disable-next-line no-nested-ternary
                            align === "center"
                              ? "center"
                              : align === "left"
                              ? "flex-start"
                              : "flex-end",
                        }}
                      >
                        {displayName}
                        {columnName === sortColumn && displayName && (
                          <ArrowIcon sx={styles.arrowIcon} />
                        )}
                      </div>
                    </div>
                  </TableCell>
                ),
              )}
            </TableRow>
          </TableHead>
          <TableBody sx={styles.tableBody}>
            {displayData &&
              displayData.map((row, i) => (
                <TableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  {columnHeaders.map((header, j) => {
                    const {
                      columnName,
                      formattingFunction,
                      align,
                      sx = {},
                    } = header;
                    const formattedValue = formattingFunction
                      ? formattingFunction(row[columnName], row)
                      : row[columnName];

                    return (
                      <TableCell key={j} sx={sx} align={align || "right"}>
                        {formattedValue}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            {aggregationRow && (
              <TableRow
                sx={{
                  position: "sticky",
                  bottom: 0,
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                {columnHeaders.map((header, j) => {
                  const {
                    columnName,
                    formattingFunction,
                    align,
                    sx = {},
                  } = header;
                  const formattedValue = formattingFunction
                    ? formattingFunction(
                        aggregationRow[columnName],
                        aggregationRow,
                      )
                    : aggregationRow[columnName];

                  return (
                    <TableCell
                      style={{ fontWeight: "bold" }}
                      key={j}
                      sx={{ ...styles.tableHeader, ...sx }}
                      align={align || "right"}
                    >
                      {formattedValue}
                    </TableCell>
                  );
                })}
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default BasicTable;
