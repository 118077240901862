import React from "react";

import { Cancel, CheckCircle } from "@mui/icons-material";
import Caption1 from "library/text/body/Caption";
import IconText from "library/containers/IconText";
import { PASSWORD_CONDITION_SPECIAL_CHARS_REGEX } from "utils/regex";

const styles = {
  checkIcon: {
    fontSize: "0.95rem",
    marginRight: "0.2em",
  },
};

export function PasswordCheckField({ condition, text }) {
  return (
    <IconText sx={{ marginBottom: "5px" }}>
      {condition ? (
        <CheckCircle sx={{ ...styles.checkIcon, color: "green" }} />
      ) : (
        <Cancel sx={{ ...styles.checkIcon, color: "red" }} />
      )}
      <Caption1 color="secondary">{text}</Caption1>
    </IconText>
  );
}

export const getPasswordConditions = (password) => {
  return [
    {
      text: "Minimum 8 characters",
      condition: password?.length > 8,
    },
    {
      text: "Minimum one special character",
      condition: PASSWORD_CONDITION_SPECIAL_CHARS_REGEX.test(password),
    },
    {
      text: "Minimum one number",
      condition: /\d/.test(password),
    },
  ];
};

export default function PasswordFields({ password }) {
  const passwordConditions = getPasswordConditions(password);

  return (
    <div style={{ margin: "10px 0" }}>
      {passwordConditions.map((fields) => (
        <PasswordCheckField key={fields} {...fields} />
      ))}
    </div>
  );
}
