import React from "react";
import { useSelector } from "react-redux";

import OutPointSelector from "library/form/OutPointSelector";

export default function ParentCredentialForm({
  channel,
  parentId,
  setParentId,
}) {
  const credentials =
    useSelector((state) => state.jester)?.data?.integration_level?.ad?.[
      channel
    ] || [];
  const options = credentials.map(({ description }) => description);
  const selectedName = credentials.reduce(
    (name, { id, description }) => (parentId === id ? description : name),
    "",
  );

  return (
    <div>
      <OutPointSelector
        onChange={(selectedDescription) => {
          const selectedCredential = credentials.find(
            ({ description }) => description === selectedDescription,
          );
          setParentId(selectedCredential?.id);
        }}
        menuItems={options}
        selectedValue={selectedName}
      />
    </div>
  );
}
