import React from "react";
import { Box } from "@mui/material";
import { THEME } from "assets/palette";

const styles = {
  textStyle: {
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.02em",
    color: THEME.primary.main,
  },
  inherit: {
    height: "inherit",
    width: "inherit",
  },
};

function PngContainer({ icon, boxStyle = {} }, rest) {
  if (!icon) return null;

  return (
    <Box sx={boxStyle}>
      <img src={icon} style={styles.inherit} alt={`${icon} logo`} {...rest} />
    </Box>
  );
}

export default PngContainer;
