import React from "react";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";
import BaseHeader from "./BaseHeader";

const styles = {
  header: {
    fontSize: "2.188rem",
    lineHeight: "2.75rem",
    fontWeight: "bold",
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    letterSpacing: "-0.02em",
  },
};

function Header1({ children, sx = {}, ...rest }) {
  return (
    <BaseHeader sx={{ ...styles.header, ...sx }} {...rest}>
      {children}
    </BaseHeader>
  );
}

export default Header1;
