import React from "react";

import OutPointTextField from "library/form/OutPointTextField";
import { EditMetadataButton } from "../PlatformCardTemplateComponents";

const styles = {
  formField: {
    width: "100%",
    marginBottom: "10px",
  },
};

export default function MetaDataForm({
  fields,
  canEditMetadata,
  setCanEditMetadata,
  storedMetadata,
  editedMetadata,
  setEditedMetadata,
  metadataFieldErrorStatuses,
  setMetadataFieldErrorStatuses,
}) {
  const onMetadataChange = (text, description) => {
    if (text?.length > 100) {
      setMetadataFieldErrorStatuses((prevState) => {
        const newState = { ...prevState };
        newState[description] = "Character limit is 100.";
        return newState;
      });
      return;
    }

    setMetadataFieldErrorStatuses((prevState) => {
      const newState = { ...prevState };
      newState[description] = false;
      return newState;
    });

    setEditedMetadata((prevState) => {
      const newState = { ...prevState };
      newState[description] = text;
      return newState;
    });
  };

  return (
    <>
      {fields.map((fieldName) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "flex-end",
            marginBottom: "5px",
          }}
          key={`container-${fieldName}`}
        >
          <OutPointTextField
            key={`textfield-${fieldName}`}
            name={fieldName}
            disabled={
              storedMetadata?.[fieldName] && !canEditMetadata[fieldName]
            }
            error={metadataFieldErrorStatuses[fieldName]}
            errorMsg={
              typeof metadataFieldErrorStatuses[fieldName] === "string" &&
              metadataFieldErrorStatuses[fieldName]
            }
            sx={styles.formField}
            description={fieldName}
            onChange={(value) => onMetadataChange(value, fieldName)}
            value={editedMetadata[fieldName] || ""}
          />
          {storedMetadata?.[fieldName] && !canEditMetadata[fieldName] && (
            <EditMetadataButton
              onClick={() =>
                setCanEditMetadata((prev) => {
                  const newState = { ...prev };
                  newState[fieldName] = true;
                  return newState;
                })
              }
              key={`editbtn-${fieldName}`}
            />
          )}
        </div>
      ))}
    </>
  );
}
