import React from "react";
import { Grid, IconButton } from "@mui/material";
import { capitalize } from "lodash";
import { Close } from "@mui/icons-material";

import SubHead from "library/text/headers/SubHead";
import OutPointSelector from "library/form/OutPointSelector";
import OutPointTextField from "library/form/OutPointTextField";
import Header4 from "library/text/headers/Header4";
import { DAYS, HOURS } from "utils/data/dates";

const styles = {
  intervalSelect: {
    width: "100%",
  },
  container: {
    marginBottom: "20px",
  },
  closeModalButton: {
    width: "20px",
  },
};

function IntervalField({ title, value, type, setValue, editable }) {
  return (
    <Grid item xs={6}>
      <SubHead>{capitalize(title)}</SubHead>
      {editable ? (
        <OutPointSelector
          onChange={(newValue) => setValue(newValue)}
          menuItems={type === "days" ? DAYS : HOURS}
          selectedValue={value}
          sx={styles.intervalSelect}
        />
      ) : (
        <OutPointTextField value={value} disabled />
      )}
    </Grid>
  );
}

export default function IntervalSelectionPanel({
  startDay,
  endDay,
  startHour,
  endHour,
  setStartDay,
  setEndDay,
  setStartHour,
  setEndHour,
  removeInterval,
  index,
  editable = true,
}) {
  return (
    <Grid sx={styles.container} container columnSpacing={3} rowSpacing={1}>
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Header4>Interval {index + 1}</Header4>
        <IconButton
          sx={styles.closeModalButton}
          onClick={removeInterval}
          disableRipple
        >
          <Close sx={styles.closeModalButton} />
        </IconButton>
      </Grid>
      <IntervalField
        title="start day"
        value={startDay}
        setValue={(value) => {
          if (endDay) {
            const startDayIndex = DAYS.indexOf(value);
            const endDayIndex = DAYS.indexOf(endDay);
            if (startDayIndex > endDayIndex) {
              setEndDay("");
            } else if (
              startDayIndex === endDayIndex &&
              startHour === endHour &&
              startHour
            ) {
              setEndDay("");
            }
          }
          setStartDay(value);
        }}
        type="days"
        editable={editable}
      />
      <IntervalField
        title="end day"
        value={endDay}
        setValue={(value) => {
          if (startDay) {
            const startDayIndex = DAYS.indexOf(startDay);
            const endDayIndex = DAYS.indexOf(value);
            if (startDayIndex > endDayIndex) {
              setStartDay("");
            } else if (
              startDayIndex === endDayIndex &&
              startHour === endHour &&
              startHour
            ) {
              setStartDay("");
            }
          }
          setEndDay(value);
        }}
        type="days"
        editable={editable}
      />
      <IntervalField
        title="start hour"
        value={startHour}
        setValue={(value) => {
          if (endHour) {
            const startHourIndex = HOURS.indexOf(value);
            const endHourIndex = HOURS.indexOf(endHour);
            if (startHourIndex > endHourIndex && startDay === endDay) {
              setEndHour("");
            } else if (
              startHourIndex === endHourIndex &&
              startDay === endDay &&
              startDay
            ) {
              setEndHour("");
            }
          }
          setStartHour(value);
        }}
        type="hours"
        editable={editable}
      />
      <IntervalField
        title="end hour"
        value={endHour}
        setValue={(value) => {
          if (startHour) {
            const startHourIndex = HOURS.indexOf(startHour);
            const endHourIndex = HOURS.indexOf(value);
            if (startHourIndex > endHourIndex && startDay === endDay) {
              setStartHour("");
            } else if (
              startHourIndex === endHourIndex &&
              startDay === endDay &&
              startDay
            ) {
              setStartHour("");
            }
          }
          setEndHour(value);
        }}
        type="hours"
        editable={editable}
      />
    </Grid>
  );
}
