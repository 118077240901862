import React from "react";
import WelcomeGraphic from "assets/welcome_graphic.png";

const styles = {
  rightPanel: {
    position: "relative",
    height: "100%",
  },
  graphic: {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    left: "0",
    objectFit: "cover",
  },
};

const graphicVersions = {
  signin: {
    graphic: WelcomeGraphic,
    header: "Maximize your spend",
    body: "We use Media Mix Modelling (MMM) to recommend how you should adjust spend across your channels.",
  },
  signup: {
    graphic: WelcomeGraphic,
    header: "Spend your budget better",
    body: "We use Media Mix Modelling (MMM) and machine learning (ML) to recommend how to improve growth efficiency and scale ad spend.",
  },
  signupSuccess: {
    graphic: WelcomeGraphic,
    header: "Maximize your spend",
    body: "We use Media Mix Modelling (MMM) to recommend how you should adjust spend across your channels.",
  },
};

export default function GraphicPanel({ graphicVersion }) {
  const data = graphicVersions[graphicVersion];

  return (
    <div style={styles.rightPanel} data-testid="GraphicPanel">
      <img style={styles.graphic} src={data.graphic} alt="Graphic Panel" />
    </div>
  );
}
