import { authSuccess, authFailed } from "redux/loginSlice";
import axios from "axios";
import jwtDecode from "jwt-decode";
import { initializeApp } from "firebase/app";
import { getDatabase, get, ref, set } from "firebase/database";
import {
  signInWithEmailAndPassword,
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";
import { featuresLogOut } from "redux/featuresSlice";
import { jesterLogOut } from "redux/jesterSlice";
import { ingestionLogOut } from "redux/ingestionSlice";
import { boostReportLogOut } from "redux/boostReportSlice";
import { metricsLogOut } from "redux/metricSlice";
import { notificationsLogOut } from "redux/notificationsSlice";
import { selectionsLogOut } from "redux/homeFiltersSlice";
import { timeOfDayLogOut } from "redux/timeOfDaySlice";
import { postsLogOut } from "redux/postsSlice";
import { reportingLogOut } from "redux/reportingSlice";

const initializeFirebase = () => {
  try {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_GCP_API_KEY,
      authDomain: process.env.REACT_APP_GCP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_GCP_DATABASE_URL,
      projectId: process.env.REACT_APP_GCP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_GCP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_GCP_MESSAGING_SENDER_ID,
      appId: process.env.REACT_APP_GCP_APP_ID,
    };
    const firebaseApp = initializeApp(firebaseConfig);
    const firebaseAuth = getAuth(firebaseApp);
    const firebaseDatabase = getDatabase(firebaseApp);
    return { firebaseAuth, firebaseDatabase };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`Error initializing Firebase: ${error.message}`);
    return {};
  }
};
const { firebaseAuth, firebaseDatabase } = initializeFirebase();
const firebaseAppId = process.env.REACT_APP_GCP_APP_ID;

export const getToken = (_) => localStorage.getItem("Token");
const setToken = (token) => localStorage.setItem("Token", token);
const removeToken = (_) => localStorage.removeItem("Token");

const newCustomError = (code, message = "") => {
  const newError = new Error(message);
  newError.code = code;
  return newError;
};

const createErrorMsgFromCode = (code) => {
  if (code === "non-verified-email") {
    return "Account has not been verified, please verify your account and try again. Contact us if you have not received a verification email. ";
  }
  if (code === "signup-error") {
    return "Unable to signup new user. Try again or contact us. ";
  }
  if (code === "wrong-app") {
    return "Your account does not have access to this website. Please sign up for a new account. ";
  }
  if (code === "not-validated") {
    return "Your account will be confirmed by an admin shortly. Contact us for more information. ";
  }
  if (
    code === "no-user" ||
    code === "auth/user-not-found" ||
    code === "auth/user_disabled"
  ) {
    return "The email you entered is not associated with an account. Please sign up for a new account. ";
  }
  if (code === "auth/email-already-in-use") {
    return "An account with this email already exists. ";
  }
  if (code === "auth/wrong-password") {
    return "The password you entered is incorrect. Try again or contact us. ";
  }
  return "Internal error encountered. Try again or contact us. ";
};

export const authenticateUser = (email, password, dispatch) => {
  return new Promise((resolve, reject) => {
    signInWithEmailAndPassword(firebaseAuth, email, password)
      .then((userCredential) => {
        if (!userCredential.user.emailVerified) {
          throw newCustomError("non-verified-email");
        }
        get(ref(firebaseDatabase, `/users/${userCredential.user.uid}`))
          .then((snapshot) => {
            if (snapshot.exists()) {
              const userDetails = snapshot.val();
              if (!userDetails.validated) {
                throw newCustomError("not-validated");
              }
              if (
                (userDetails.restricted &&
                  userDetails.restrictedApp === firebaseAppId) ||
                !userDetails.restricted
              ) {
                userCredential.user.getIdToken().then((token) => {
                  dispatch(authSuccess());
                  setToken(token);
                  resolve("Authenticated");
                });
              } else {
                throw newCustomError("wrong-app");
              }
            } else {
              throw newCustomError("no-user");
            }
          })
          .catch((error) => {
            dispatch(authFailed());
            reject(createErrorMsgFromCode(error.code));
          });
      })
      .catch((error) => {
        dispatch(authFailed());
        reject(createErrorMsgFromCode(error.code));
      });
  });
};

export const signUpUser = (firstName, lastName, email, password) => {
  return new Promise((resolve, reject) => {
    createUserWithEmailAndPassword(firebaseAuth, email, password)
      .then((userCredential) => {
        if (userCredential.user != null) {
          updateProfile(userCredential.user, {
            displayName: `${firstName} ${lastName}`,
          });
          set(ref(firebaseDatabase, `/users/${userCredential.user.uid}`), {
            firstName,
            lastName,
            email,
            validated: false,
            restricted: true,
            restrictedApp: firebaseAppId,
          });
          sendEmailVerification(userCredential.user);
          resolve("New user signed up");
        } else {
          throw newCustomError("signup-error");
        }
      })
      .catch((error) => {
        reject(createErrorMsgFromCode(error.code));
      });
  });
};

export const changePassword = (currentPassword, newPassword) => {
  return new Promise((resolve, reject) => {
    const user = firebaseAuth.currentUser;
    const currentUserCredentials = EmailAuthProvider.credential(
      user.email,
      currentPassword,
    );
    reauthenticateWithCredential(user, currentUserCredentials)
      .then(() => {
        updatePassword(user, newPassword)
          .then(() => {
            resolve("Password changed successfully");
          })
          .catch((error) => {
            reject(new Error(`An unexpected error occured: ${error.message}`));
          });
      })
      .catch(() => {
        reject(new Error("Incorrect password"));
      });
  });
};

export const logOut = (dispatch) => {
  dispatch(authFailed());
  removeToken();
  dispatch(jesterLogOut());
  dispatch(featuresLogOut());
  dispatch(ingestionLogOut());
  dispatch(boostReportLogOut());
  dispatch(metricsLogOut());
  dispatch(notificationsLogOut());
  dispatch(selectionsLogOut());
  dispatch(timeOfDayLogOut());
  dispatch(postsLogOut());
  dispatch(reportingLogOut());

  if (window.Cypress && authFailed) {
    window.isLoggedOut = true;
  }

  window.location.pathname = "/login";
};

export const authenticateToken = async (dispatch) => {
  const token = getToken();
  if (!token) {
    dispatch(authFailed());
    return false;
  }
  try {
    const result = await axios.get(`${process.env.REACT_APP_DOMAIN}/auth`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const success = result.data.success === true;
    if (success) {
      dispatch(authSuccess());
    } else {
      logOut(dispatch);
    }
    return success;
  } catch {
    logOut(dispatch);
    return false;
  }
};

/**
 * NOTE:
 * Returns true if a token exists, without checking it's actual validity
 * */
export const tokenValid = () => {
  try {
    const token = getToken();
    if (token) {
      const decoded = jwtDecode(token);
      const now = Date.now() / 1000;
      const { exp } = decoded;
      return now < exp;
    }
    return false;
  } catch {
    return false;
  }
};
