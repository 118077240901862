import React, { useState } from "react";
import { Button } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

import OutPointModal from "library/surface/ModalCentered";
import InfoModal from "./InfoModal";

const styles = {
  button: {
    minWidth: "40px",
    maxWidth: "40px",
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
};

export default function InfoButton({
  channel,
  adsetId,
  adsetName,
  boostAmount,
  submittedOn,
  expiredOn,
  currentBudget,
  from,
}) {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <>
      <OutPointModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        sx={{ maxWidth: "500px" }}
      >
        <InfoModal
          channel={channel}
          adsetId={adsetId}
          adsetName={adsetName}
          boostAmount={boostAmount}
          currentBudget={currentBudget}
          submittedOn={submittedOn}
          expiredOn={expiredOn}
          from={from}
          close={() => setModalOpen(false)}
        />
      </OutPointModal>
      <Button
        variant="default"
        sx={styles.button}
        onClick={() => setModalOpen(true)}
      >
        <InfoOutlined />
      </Button>
    </>
  );
}
