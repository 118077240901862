import React from "react";

import { Snackbar, IconButton } from "@mui/material";
import Close from "@mui/icons-material/Close";

const styles = {
  icon: {
    color: "white",
  },
};

export default function OutPointSnackBar({ open, onClose, message }) {
  return (
    <Snackbar
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      open={open}
      message={message}
      action={
        <IconButton
          size="small"
          aria-label="close"
          onClick={onClose}
          sx={styles.icon}
        >
          <Close fontSize="small" />
        </IconButton>
      }
    />
  );
}
