import React from "react";
import LogoChip from "pages/connections/components/LogoChip";
import { cardData } from "pages/connections/data/connectionsData";
import BodyText from "library/text/body/BodyText";
import { capitalize } from "lodash";
import { Chip } from "@mui/material";
import InfoButton from "pages/home/components/InfoButton";
import { addCommasToDecimal } from "utils/data/strings";
import TableCellDate from "../components/TableCellDate";

const tableHeaders = [
  {
    displayName: "",
    columnName: "channel",
    align: "center",
    sx: { width: "40px" },
    formattingFunction: (channel) => (
      <LogoChip logo={cardData[channel]?.logo} channel={channel} size="20px" />
    ),
  },
  {
    displayName: "Adset name",
    columnName: "adset_name",
    align: "left",
    formattingFunction: (adsetName) => (
      <BodyText
        sx={{
          textOverflow: "ellipsis",
          width: "200px",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {adsetName}
      </BodyText>
    ),
    sx: { width: "200px" },
  },
  {
    displayName: "Status",
    columnName: "status",
    align: "center",
    formattingFunction: (status) => (
      <Chip
        size="small"
        label={capitalize(status)}
        sx={{
          fontWeight: "bold",
          color: status === "in progress" ? "#B75600" : "#1700A1",
          backgroundColor: status === "in progress" ? "#FFF3D9" : "#EEEBFA",
        }}
      />
    ),
  },
  {
    displayName: "Granularity",
    columnName: "granularity",
    align: "left",
  },
  {
    displayName: "Previous Budget",
    columnName: "previous_lifetime_budget",
    formattingFunction: (spend) => `$${addCommasToDecimal(spend)}`,
  },
  {
    displayName: "Boost Amount",
    columnName: "boost_amount",
    // sx: { width: '60px' },
    align: "right",
    formattingFunction: (boostAmount) => `$${addCommasToDecimal(boostAmount)}`,
  },
  {
    displayName: "Change",
    columnName: "change",
    sx: { width: "50px" },
    formattingFunction: (change) => (
      <Chip
        size="small"
        label={`${change}%`}
        sx={{
          fontWeight: "bold",
          color: "#0028B7",
          backgroundColor: "#0028B71A",
        }}
      />
    ),
    align: "center",
  },
  {
    displayName: "Start",
    columnName: "start_timestamp",
    sx: { width: "80px" },
    formattingFunction: (date) => <TableCellDate date={date} />,
    sortingFunctions: {
      desc: (a, b) => new Date(a.start_timestamp) - new Date(b.start_timestamp),
      asc: (a, b) => new Date(b.start_timestamp) - new Date(a.start_timestamp),
    },
  },
  {
    displayName: "End",
    columnName: "end_timestamp",
    sx: { width: "80px" },
    formattingFunction: (date) => <TableCellDate date={date} />,
    sortingFunctions: {
      desc: (a, b) => new Date(a.end_timestamp) - new Date(b.end_timestamp),
      asc: (a, b) => new Date(b.end_timestamp) - new Date(a.end_timestamp),
    },
  },
  // {
  //   displayName: 'Status',
  //   columnName: 'end_timestamp',
  //   formattingFunction: (end, row) => {
  //     if (row.invalidated_at) {
  //       return 'Invalid';
  //     } else if (row.deleted_at) {
  //       return 'Replaced';
  //     } else if (row.expired_at) {
  //       return 'Expired';
  //     } else if (row.stopped_at) {
  //       return 'Stopped';
  //     } else {
  //       return 'Active';
  //     }
  //   },
  //   align: 'center',
  // },
  {
    displayName: "",
    columnName: "boost_amount",
    sx: { width: "40px" },
    formattingFunction: (inv, row) => {
      return (
        <InfoButton
          channel={row.channel}
          currentBudget={row.lifetime_budget}
          adsetId={row.api_adset_id}
          adsetName={row.adset_name}
          boostAmount={row.boost_amount}
          submittedOn={row.start_timestamp}
          expiredOn={row.end_timestamp}
          from="boost_report"
        />
      );
    },
  },
];

export default tableHeaders;
