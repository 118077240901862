import { makeAuthenticatedPostRequest } from "./backend-api";

export async function jesterPostRequest(route, payload) {
  if (payload.credentials) {
    payload.credentials = JSON.stringify(payload.credentials);
  }

  return makeAuthenticatedPostRequest(route, payload);
}

export async function goToURL(
  channelName,
  credentialId,
  credentials,
  whereFrom,
  tokenType,
) {
  try {
    const req = {
      channel: channelName,
      credential_id: credentialId,
      from: whereFrom,
      token_type: tokenType,
    };
    if (tokenType === "page") {
      req.parent_id = credentials.parent_credential_id;
    }

    const result = await jesterPostRequest("connect", req);
    window.location.href = result.url;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err);
  }
}
