import React from "react";

import { useSelector } from "react-redux";
import Header4 from "library/text/headers/Header4";

import Header1 from "library/text/headers/Header1";
import { Grid } from "@mui/material";
import SubHead2 from "library/text/headers/SubHeadWide";
import MonthlyMetrics from "./components/MonthlyMetrics";
import TopPostsCard from "./components/TopPostsCard";
import TopBrandsCard from "./components/TopBrandsCard";

const styles = {
  headerMain: {
    marginBottom: "20px",
  },
};

function SectionHeader({ title, date }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: "12px",
        alignItems: "center",
        marginBottom: "12px",
      }}
    >
      <Header4 isSentenceCase={false}>{title}</Header4>
      <SubHead2 color="secondary">{date}</SubHead2>
    </div>
  );
}

function ReportingPage() {
  const monthlyData =
    useSelector((state) => state.reportingData?.data?.all_monthly) || [];
  const topPostsData =
    useSelector((state) => state.reportingData?.data?.top_posts) || [];
  const topBrandsData =
    useSelector((state) => state.reportingData?.data?.top_brands) || {};
  const momPerformance =
    useSelector((state) => state.reportingData?.data?.mom_performance) || [];
  const prevMonth = new Date();
  prevMonth.setDate(0);
  const prevMonthName = prevMonth.toLocaleString("default", {
    month: "long",
    year: "numeric",
  });

  return (
    <div>
      <Header1 sx={styles.headerMain}>Reporting</Header1>
      <Grid
        container
        spacing={2}
        style={{
          width: "100%",
          marginBottom: "60px",
        }}
      >
        <Grid xs={6} item>
          <SectionHeader title="Top posts" date={prevMonthName} />
          <TopPostsCard
            posts={topPostsData.slice(0, Math.min(3, topPostsData.length))}
          />
        </Grid>
        <Grid xs={12} item>
          <SectionHeader title="Top brands" date={prevMonthName} />
          <TopBrandsCard brandsData={topBrandsData} />
        </Grid>
      </Grid>
      <MonthlyMetrics title="Monthly breakdown" monthlyData={monthlyData} />
      <div style={{ height: "60px" }} />
      <MonthlyMetrics title="MOM Performance" monthlyData={momPerformance} />
    </div>
  );
}

export default ReportingPage;
