import React from "react";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";
import BaseHeader from "./BaseHeader";

const styles = {
  header: {
    fontSize: "0.875rem",
    lineHeight: "1rem",
    fontWeight: 600,
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    letterSpacing: "0.08em",
  },
};

function SubHead({ children, sx = {}, ...rest }) {
  return (
    <BaseHeader sx={{ ...styles.header, ...sx }} {...rest}>
      {children}
    </BaseHeader>
  );
}

export default SubHead;
