// TODO, move static URLS to once place to prevent duplication in code base
export const URLS = Object.freeze({
  HOW_IT_WORKS: "https://www.outpoint.app/how-it-works",
  GET_IN_TOUCH_SURVEY:
    "https://survey.survicate.com/83a4c9e2a8360805/?p=hubspot&first_name={{contact.firstname}}&last_name={{contact.lastname}}&email={{contact.email}}",
});

export const MAP_GOALS = Object.freeze({
  efficiency: "profit",
  growth: "revenue",
});

export const RECOMMENDATION_SCENARIO_DETAILS = {
  profit: {
    title: "Your recommendations are set to efficiency.",
    subtitle: "The recommendations below are designed to maximize profit.",
  },
  revenue: {
    title: "Your recommendations are set to growth.",
    subtitle: "The recommendations below are designed to maximize return.",
  },
  custom: {
    title: "Your recommendations are set to custom.",
    subtitle:
      "The recommendations below are based on your spend level preference.",
  },
};

export const BASELINE = Object.freeze("baseline");

export const STATUS = Object.freeze({
  LOADING: "loading",
  SUCCESS: "success",
  FAILED: "failed",
});

export const UPLOAD_VALIDITY_STATUS = {
  VALID: "valid",
  INVALID: "invalid",
  PROCESSING: "processing",
  PENDING: "pending", // pending verification
  PARSED: "parsed", // indicates that a csv file has been parsed into coordinates
  NETWORK_ERROR: "networkError",
};

export const STATIC_CHANNELS_LIST = [
  "Affiliate",
  "Bing",
  "Facebook",
  "Google",
  "Influencer",
  "Native Ads",
  "Ooh",
  "Podcast",
  "Radio",
  "Reddit",
  "SnapChat",
  "TikTok",
  "TV",
  "YouTube",
  "Programmatic",
  "Twitter",
];
export const CALENDAR_DATE_FORMAT = "MMM dd yyyy";
export const TOOLTIP_DATE_FORMAT = "MMM dd, yyyy";
export const DEFAULT_DATE_FORMAT = "yyyy-MM-dd";
export const GRAPH_DATE_FORMAT = "MMM dd";
export const KHC_TAXONOMY_DATE_FORMAT = "MMdd";

export const CAMPAIGN_ATTRIBUTES = {
  Medium: {
    label: "Medium",
    keyName: "medium",
  },
  Channel: {
    label: "Channel",
    keyName: "parentChannelId",
  },
  Source: {
    label: "Source",
    keyName: "source",
  },
  "Campaign Group": {
    label: "Campaign Group",
    keyName: "parentGroupId",
  },
  "Campaign Name": {
    label: "Campaign Name",
    keyName: "campaignName",
  },
};

export const SORT_ORDER = {
  ASC: "asc",
  DSC: "dsc",
};

export const STAGGERED_REQUESTS_LIMIT = Object.freeze({
  ROAS_GET: 2,
});

export const SEC_DAY = 86400000;
