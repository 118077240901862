import React from "react";
import BodyText from "library/text/body/BodyText";
import { THEME } from "assets/palette";

const styles = {
  active: {
    color: "black",
    cursor: "pointer",
    ":hover": {
      color: "black",
    },
  },
  inactive: {
    color: THEME.secondary.main,
    cursor: "pointer",
    ":hover": {
      color: "black",
    },
  },
};

export default function PressableText({
  children,
  active,
  onClick,
  sx = {},
  ...rest
}) {
  return (
    <BodyText
      onClick={onClick}
      sx={active ? { ...styles.active, ...sx } : { ...styles.inactive, ...sx }}
      {...rest}
    >
      {children}
    </BodyText>
  );
}
