import { Button } from "@mui/material";
import React from "react";
import { sentenceCase } from "utils/data/strings";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";

const styles = {
  button: {
    width: "100%",
    fontSize: "0.875rem",
    fontWeight: 600,
    fontStyle: "normal",
    fontFamily: "IBM Plex Sans",
    letterSpacing: "0.02em",
    borderRadius: "10px",
    "&:disabled": {
      backgroundColor: "#3D202499",
      color: "#FFFFFF",
    },
  },
};

function PrimaryButton({
  variant = "contained",
  sx = {},
  onClick,
  text,
  isSentenceCase = true,
  isDisabled = false,
  color = "primary",
  endIcon = <div />,
  startIcon = <div />,
  children,
  ...rest
}) {
  const newText = sentenceCase(text || children);

  return (
    <Button
      type="button"
      color={color}
      variant={variant}
      sx={{ ...styles.button, ...sx }}
      onClick={onClick}
      disabled={isDisabled}
      endIcon={endIcon}
      startIcon={startIcon}
      {...rest}
    >
      {isSentenceCase ? newText : text || children}
    </Button>
  );
}

export default PrimaryButton;
