import React from "react";
import { Typography } from "@mui/material";
import "assets/fonts/IBM_Plex_Sans/stylesheet.css";

const styles = {
  text: {
    fontFamily: "IBM Plex Sans",
    fontSize: "0.875rem",
    lineHeight: "1.25rem",
  },
};

function BodyText({ children, sx = {}, ...rest }) {
  return (
    <Typography sx={{ ...styles.text, ...sx }} {...rest}>
      {children}
    </Typography>
  );
}

export default BodyText;
