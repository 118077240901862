import { configureStore } from "@reduxjs/toolkit";
import featuresReducer from "./featuresSlice";
import jesterReducer from "./jesterSlice";
import loginReducer from "./loginSlice";
import snackbarReducer from "./snackbarSlice";
import ingestionReducer from "./ingestionSlice";
import boostReportReducer from "./boostReportSlice";
import metricsReducer from "./metricSlice";
import notificationsSlice from "./notificationsSlice";
import homeFiltersSlice from "./homeFiltersSlice";
import timeOfDaySlice from "./timeOfDaySlice";
import hourlySlice from "./hourlySlice";
import postsSlice from "./postsSlice";
import reportingSlice from "./reportingSlice";

export default configureStore({
  reducer: {
    features: featuresReducer,
    jester: jesterReducer,
    ingestion: ingestionReducer,
    login: loginReducer,
    snackbarStore: snackbarReducer,
    boostReport: boostReportReducer,
    metrics: metricsReducer,
    notifications: notificationsSlice,
    homeFilters: homeFiltersSlice,
    timeOfDay: timeOfDaySlice,
    hourlyData: hourlySlice,
    postsData: postsSlice,
    reportingData: reportingSlice,
  },
});
