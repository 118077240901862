import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeAuthenticatedGetRequest } from "utils/backend-api";
import { STATUS } from "utils/enums";

export const getIngestionData = createAsyncThunk(
  "asyncRedux/ingestion",
  async () => {
    return makeAuthenticatedGetRequest("ingestion");
  },
);

export const getBrands = createAsyncThunk("asyncRedux/brands", async () => {
  return makeAuthenticatedGetRequest("brand");
});

const ingestionSlice = createSlice({
  name: "ingestionSlice",
  initialState: {
    data: null,
    status: null,
    agile_monthly: {},
  },
  reducers: {
    ingestionLogOut: (state, _) => {
      state.data = null;
      state.status = null;
      state.brands = null;
    },
  },
  extraReducers: {
    [getIngestionData.pending]: (state, _) => {
      state.status = STATUS.LOADING;
    },
    [getIngestionData.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.status = STATUS.SUCCESS;
    },
    [getIngestionData.rejected]: (state, _) => {
      state.status = STATUS.FAILED;
    },
    [getBrands.pending]: (state, _) => {
      state.status = STATUS.LOADING;
    },
    [getBrands.fulfilled]: (state, { payload }) => {
      state.brands = payload.brands;
      state.status = STATUS.SUCCESS;
    },
    [getBrands.rejected]: (state, _) => {
      state.status = STATUS.FAILED;
    },
  },
});

export const { ingestionLogOut } = ingestionSlice.actions;

export default ingestionSlice.reducer;
