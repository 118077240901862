import React, { useState, useMemo } from "react";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { IconButton } from "@mui/material";

import OutPointDropdown from "library/buttons/OutPointDropdown";
import BasicTable from "library/display/BasicTable";
import SubHead from "library/text/headers/SubHeadWide";
import CSVDownloader from "library/buttons/CSVDownloader";
import { BRAND_GRAY_700 } from "assets/palette";
import { uniq } from "lodash";
import { sortTableHeaders, formatDisplayName } from "../utils";

const nonMetrics = ["month", "brand"];
const metrics = [
  {
    name: "num_posts",
    decimals: 0,
  },
  {
    name: "spend",
    decimals: 2,
    startAdornment: "$",
  },
  {
    name: "cpe",
    decimals: 2,
    startAdornment: "$",
  },
  {
    name: "cpe_change",
    decimals: 2,
    startAdornment: "$",
  },
  {
    name: "cpe_change_%",
    decimals: 2,
    endAdornment: "%",
  },
  {
    name: "cpm",
    decimals: 2,
    startAdornment: "$",
  },
  {
    name: "enr",
    decimals: 2,
    endAdornment: "%",
  },
  {
    name: "enr_change",
    decimals: 2,
    endAdornment: "%",
  },
  {
    name: "enr_change_%",
    decimals: 2,
    endAdornment: "%",
  },
  {
    name: "cpcv",
    decimals: 2,
    startAdornment: "$",
  },
  {
    name: "cpcv_change",
    decimals: 2,
    startAdornment: "$",
  },
  {
    name: "cpcv_change_%",
    decimals: 2,
    startAdornment: "$",
  },
  {
    name: "vcr",
    decimals: 2,
    endAdornment: "%",
  },
  {
    name: "vcr_change",
    decimals: 2,
    endAdornment: "%",
  },
  {
    name: "vcr_change_%",
    decimals: 2,
    endAdornment: "%",
  },
  {
    name: "video_views_to_100",
    decimals: 0,
  },
];
const channels = ["tiktok", "instagram", "ig_static", "ig_video"];
const selectableChannels = ["tiktok", "instagram"];

const sortAndFilterHeaders = (data, selectedChannel) => {
  return Object.keys(data?.[0] || {})
    .filter((column) => {
      if (selectedChannel === null) return true;
      if (nonMetrics.includes(column)) return true;
      if (column.startsWith(selectedChannel)) return true;
      if (selectedChannel === "instagram" && column.startsWith("ig"))
        return true;
      return false;
    })
    .sort((a, b) =>
      sortTableHeaders(
        a,
        b,
        nonMetrics,
        metrics.map(({ name }) => name),
        channels,
      ),
    );
};

const makeColumnHeaders = (columns) =>
  columns.map((column) => {
    if (nonMetrics.includes(column)) {
      return {
        columnName: column,
        displayName: formatDisplayName(column),
        align: "center",
        formattingFunction: (metric) => {
          if (!metric || metric.length === 0) return "-";
          return metric;
        },
      };
    }

    let columnName = "";
    const channel = channels.reduce((acc, possibleChannel) =>
      column.startsWith(possibleChannel) ? possibleChannel : acc,
    );
    const metricName = column.slice(channel.length + 1);
    const { decimals, startAdornment, endAdornment } =
      metrics.find(({ name }) => name === metricName) || {};
    columnName = channel;

    return {
      columnName: column,
      displayName: formatDisplayName(columnName),
      align: "center",
      formattingFunction: (metric) => {
        if (!metric || metric.length === 0) return "-";
        if (typeof metric === "number") {
          const metricDisplayValue = `${startAdornment ?? ""}${Math.abs(
            metric.toFixed(decimals),
          )}${endAdornment ?? ""}`;
          let sign = "";
          if (metric < 0) {
            sign = "-";
          } else if (column.includes("change")) {
            sign = "+";
          }
          return sign + metricDisplayValue;
        }

        return metric;
      },
    };
  });

const makeHeaderGroups = (columns) =>
  columns.reduce((headerGroups, column) => {
    if (nonMetrics.includes(column)) {
      headerGroups.push({
        groupName: "",
      });
    } else {
      const channel = channels.reduce((acc, possibleChannel) =>
        column.startsWith(possibleChannel) ? possibleChannel : acc,
      );
      const metricName = column.slice(channel.length + 1);
      if (headerGroups[headerGroups.length - 1].groupName !== metricName) {
        headerGroups.push({
          groupName: metricName,
          displayName: formatDisplayName(metricName),
          colSpan: 1,
        });
      } else {
        headerGroups[headerGroups.length - 1].colSpan += 1;
      }
    }
    return headerGroups;
  }, []);

function MonthlyMetrics({ title, monthlyData }) {
  const [startMonth, setStartMonth] = useState(null);
  const [endMonth, setEndMonth] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState();
  const [selectedChannel, setSelectedChannel] = useState(null);

  const months = uniq(monthlyData.map(({ month }) => month));
  const brands = uniq(monthlyData.map(({ brand }) => String(brand)));

  const slicedData = monthlyData
    .slice(
      startMonth
        ? monthlyData.findIndex(({ month }) => month === months[startMonth])
        : 0,
      endMonth === null
        ? monthlyData.length
        : monthlyData.findLastIndex(({ month }) => months[endMonth] === month) +
            1,
    )
    .filter(({ brand }) =>
      selectedBrands ? selectedBrands.includes(brand) : true,
    );

  const sortedColumns = useMemo(
    () => sortAndFilterHeaders(monthlyData, selectedChannel),
    [monthlyData.length, selectedChannel],
  );
  const columnHeaders = useMemo(
    () => makeColumnHeaders(sortedColumns),
    [sortedColumns.length, selectedChannel],
  );
  const headerGroups = useMemo(
    () => makeHeaderGroups(sortedColumns),
    [sortedColumns.length, selectedChannel],
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 12,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <SubHead isSentenceCase={false} style={{ color: BRAND_GRAY_700 }}>
          {title}
        </SubHead>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 16,
          }}
        >
          <OutPointDropdown
            onChange={(channel) => {
              if (channel === selectedChannel) {
                setSelectedChannel(null);
              } else {
                setSelectedChannel(channel);
              }
            }}
            leading="Platform: "
            selectedValue={selectedChannel}
            defaultDisplay="None"
            onClear={() => setSelectedChannel(null)}
            menuItems={selectableChannels}
          />
          <OutPointDropdown
            onChange={(dropdownBrands) => setSelectedBrands(dropdownBrands)}
            leading="Brands: "
            selectedValue={selectedBrands || []}
            defaultDisplay="None"
            onClear={() => setSelectedBrands(null)}
            menuItems={brands}
            multiple
          />
          <OutPointDropdown
            onChange={(month) => {
              const i = months.indexOf(month);
              setStartMonth(i);
              if (i > endMonth) {
                setEndMonth(null);
              }
            }}
            leading="Start date: "
            selectedValue={months[startMonth]}
            defaultDisplay="None"
            menuItems={months}
          />
          <OutPointDropdown
            onChange={(month) => {
              const i = months.indexOf(month);
              setEndMonth(i);
              if (i < startMonth) {
                setStartMonth(null);
              }
            }}
            leading="End date: "
            selectedValue={months[endMonth]}
            defaultDisplay="None"
            menuItems={months}
          />
          <CSVDownloader
            data={slicedData}
            columns={columnHeaders.map(({ columnName }) => columnName)}
            filename={title}
          >
            <IconButton>
              <FileDownloadIcon />
            </IconButton>
          </CSVDownloader>
        </div>
      </div>
      {monthlyData?.length > 0 && (
        <BasicTable
          tableHeight="70vh"
          headerGroups={headerGroups}
          columnHeaders={columnHeaders}
          rowData={slicedData}
          sortable
          aggregationRowReducerFn={(acc, row, i) => {
            Object.keys(row).forEach((key) => {
              if (key === "month") {
                acc[key] = " ";
              } else if (key === "brand") {
                acc[key] = "Average:";
              } else {
                if (!(key in acc)) {
                  acc[key] = row[key];
                } else {
                  acc[key] += row[key];
                }
                if (i === slicedData.length - 1) {
                  acc[key] /= slicedData.length;
                }
              }
            });
            return acc;
          }}
        />
      )}
    </div>
  );
}

export default MonthlyMetrics;
