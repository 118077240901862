import React from "react";
import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";

const styles = {
  boxStyle: {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

function Spinner({ moreBoxStyles, moreProgressStyles }) {
  return (
    <Box sx={{ ...styles.boxStyle, ...moreBoxStyles }}>
      <CircularProgress color="primary" sx={moreProgressStyles} />
    </Box>
  );
}

// Set proptypes
Spinner.propTypes = {
  moreBoxStyles: PropTypes.object,
  moreProgressStyles: PropTypes.object,
};

export default Spinner;
