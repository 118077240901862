//
// Methods in this file should handle string related processing.
//
import { CUSTOM_CHANNEL_REGEX, CUSTOM_BRAND_REGEX } from "utils/regex";

/* Capitalize the first character of the given string and returns it */
export function capitalize(s) {
  if (s.length > 1) return s[0].toUpperCase() + s.slice(1);

  return s[0].toUpperCase();
}

/* Given an array of items, generate a string which reads as a sentence */
export function genItemsString(arr) {
  const len = arr.length;
  const newArr = arr.slice();

  if (len > 1) {
    newArr[len - 1] = `and ${newArr[len - 1]}`;
  }

  if (len === 2) {
    return newArr.join(" ");
  }
  return newArr.join(", ");
}

export function sentenceCase(s) {
  if (!s || typeof s !== "string") return s;
  if (s.length === 1) return s.toUpperCase();
  return s[0].toUpperCase() + s.slice(1).toLowerCase();
}

export function isValidEmail(email) {
  // TODO: make more robust
  if (typeof email !== "string") return false;
  return email.includes("@");
}

/**
 * Validates custom channel provided by the user
 * */
export function isValidCustomChannel(channel) {
  if (typeof channel !== "string") {
    return false;
  }

  return CUSTOM_CHANNEL_REGEX.test(channel);
}

/**
 * Validates custom channel provided by the user
 * */
export function isValidCustomBrand(brand) {
  if (typeof brand !== "string") {
    return false;
  }

  return CUSTOM_BRAND_REGEX.test(brand);
}

/* Given a string (eg. "Hello world"), return it in a label format (eg. "hello-world") */
export function createHTMLLabel(s) {
  return s
    .split(" ")
    .map((item) => item.toLowerCase())
    .join("-");
}

export function addCommasToNumber(number) {
  // user facing friendly display for NaN (friendlier than ,nan or NaN, and may be better than zero (0), since 0 is still a value)
  if (Number.isNaN(number)) return "--";

  // NOTE, possible region specific locale support
  return Number(number).toLocaleString("en-US");
}

export function addCommasToDecimal(number) {
  // user facing friendly display for NaN (friendlier than ,nan or NaN, and may be better than zero (0), since 0 is still a value)
  if (Number.isNaN(number)) return "--";

  // NOTE, possible region specific locale support
  return Number(number).toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function reverseString(str) {
  return str.split("").reverse().join("");
}

export const stringToTitleCase = (str = "") => {
  if (!str) return str;

  return str
    .split(" ")
    .map((word) => {
      const lowerWord = word.toLowerCase();
      return lowerWord.charAt(0).toUpperCase(0) + lowerWord.substring(1);
    })
    .join(" ");
};

export const kDigitConversion = (number, leadingDecorator = "$") => {
  if (Number.isNaN(number)) return null;

  const thousandthNum = Number(number) / 1000;

  if (thousandthNum < 0) return null;

  return `${leadingDecorator}${parseFloat(thousandthNum.toFixed(2))}K`;
};

export const normalizeChannelName = (channelName = "") => {
  return channelName.toLowerCase().replaceAll("_", " ");
};

/**
 * Returns true if inputString is a substring of the testString.
 * */
export const isSubstringOf = (testString, inputString, options = {}) => {
  const inputsAreStrings = [testString, inputString].every(
    (s) => typeof s === "string" || s instanceof String,
  );

  if (!inputsAreStrings) {
    // eslint-disable-next-line no-console
    console.assert(
      inputsAreStrings,
      "Inputs to [isSubstringOf] are not strings",
    );

    return false;
  }

  const testSubstring = (target, query) =>
    Boolean(target.indexOf(query) !== -1);

  const { isCaseInsensitive = false } = options;

  if (isCaseInsensitive) {
    return testSubstring(testString.toLowerCase(), inputString.toLowerCase());
  }

  return testSubstring(testString, inputString);
};

export const formatMetricForDisplay = (metric) => {
  if (!metric) return null;

  if (metric.length > 4) {
    return (
      metric.charAt(0).toUpperCase() + metric.slice(1).replaceAll("_", " ")
    );
  }

  return metric.toUpperCase();
};

/**
 * @LLM USED: Modified output from phind v9 Model
 * @prompt: js function to convert any string to PascalCase (including adding in trims and stuff); improve the ans by adding inline comments that explain the regex
 * */
export const makePascalCase = (string) => {
  const matchAllHyphensAndOrUnderscores = /[-_]+/g; // The regular expression /[-_]+/ matches one or more occurrences of either a hyphen (-) or an underscore (_)
  const matchAllNonWordChars = /[^\w\s]/g; // The regular expression /[^\w\s]/ matches any character that is not a word character (\w) or a whitespace character (\s)
  const matchAnyWordChar = /\w/; // The regular expression /\w/ matches any word character (equivalent to [a-zA-Z0-9_])
  const matchGroupsOfSpaceAndWordChars = /\s+(.)(\w*)/g; // The regular expression /\s+(.)(\w*)/ matches a space followed by any character (captured in group 1) and zero or more word characters (captured in group 2)

  const capitaliseFirstWordAndConcat = (_, $2, $3) =>
    `${$2.toUpperCase() + $3}`; // In the replacement function, the second group is capitalized and concatenated with the third group (effectively getting rid of the space)

  const formattedString = string
    .trim()
    .toLowerCase()
    .replace(matchAllHyphensAndOrUnderscores, " ") // Replaces hyphens and underscores with spaces
    .replace(matchAllNonWordChars, "") // Removes any non-word characters
    .replace(matchGroupsOfSpaceAndWordChars, capitaliseFirstWordAndConcat) // Capitalizes the first letter of each word
    .replace(matchAnyWordChar, (s) => s.toUpperCase()); // Capitalizes the first character of the string

  return formattedString;
};
