import React from "react";
import { Chip } from "@mui/material";
import { useDispatch } from "react-redux";
import OPAutoCompleteDynamic from "library/form/AutoCompleteDynamic";
import { isValidCustomBrand } from "utils/data/strings";
import { jesterPostRequest } from "utils/jester-api";
import { getBrands } from "redux/ingestionSlice";

function addBrand(brand, setBrandFormList) {
  setBrandFormList((prevBrandList) => {
    const currBrandList = [...prevBrandList];
    currBrandList.push(brand);
    return currBrandList;
  });
}

function removeBrand(brand, setBrandFormList) {
  setBrandFormList((prevBrandList) => {
    const currBrandList = [...prevBrandList];
    currBrandList.splice(currBrandList.indexOf(brand), 1);
    return currBrandList;
  });
}

export default function BrandForm({ brands, brandFormList, setBrandFormList }) {
  const dispatch = useDispatch();

  const brandsToDisplay = brands.filter(
    (brand) => !brandFormList.includes(brand),
  );

  const handleBrandRequest = async (brand) => {
    const formattedBrand = `${brand}`.trim().toLowerCase();
    const options = [];
    brands.forEach((eachBrand) => {
      options.push(eachBrand.toLowerCase());
    });

    const isNewBrand = !options.includes(formattedBrand);
    if (isNewBrand) {
      try {
        await jesterPostRequest("brand", {
          brand,
        });
        const success = await dispatch(getBrands());
        if (!success) return false;
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn(err);
        // TODO: Else push error toast
        return false;
      }
    }

    if (!brandFormList.includes(brand)) addBrand(brand, setBrandFormList);
    return true;
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <OPAutoCompleteDynamic
        name="BrandSelectionAutoComplete"
        handleSelection={handleBrandRequest}
        options={brandsToDisplay}
        textFieldLabel="Add brand"
        dialogTextFieldLabel="Brand"
        dialogTitle="Add a new Brand"
        dialogContentText="Do you want to add a custom brand?"
        isValidInput={isValidCustomBrand}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          flexWrap: "wrap",
        }}
      >
        {brandFormList.map((currBrand) => (
          <Chip
            key={currBrand}
            label={currBrand}
            onDelete={() => removeBrand(currBrand, setBrandFormList)}
          />
        ))}
      </div>
    </div>
  );
}
