import React from "react";

import { Grid } from "@mui/material";

import OutPointCard from "library/surface/OutPointCard";
import SubHead from "library/text/headers/SubHead";
import TopItemWithMetrics from "./TopItemWithMetrics";

function TopBrandsCard({ brandsData }) {
  return (
    <Grid container spacing={2}>
      {Object.keys(brandsData).map((channel) => {
        const channelData = brandsData[channel];
        return (
          <Grid item xs={6}>
            <OutPointCard
              style={{
                padding: "16px",
                height: "auto",
              }}
            >
              <SubHead>{channel}</SubHead>
              {channelData.map((row, i) => {
                const { brand } = row;
                const cpe = row[`${channel}_cpe`];
                const spend = row[`${channel}_spend`];
                const enr = row[`${channel}_enr`];
                return (
                  <TopItemWithMetrics
                    key={channel + brand}
                    name={brand}
                    spend={spend}
                    cpe={cpe}
                    enr={enr}
                    i={i}
                  />
                );
              })}
            </OutPointCard>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default TopBrandsCard;
