import React from "react";
import BodyText from "library/text/body/BodyText";

export default function TableCellDate({ date }) {
  const dateObj = new Date(Date.parse(date));
  const month = dateObj.getMonth() + 1;
  const day = dateObj.getDate();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
      }}
    >
      <div style={{ lineHeight: "1.25rem" }}>
        {dateObj.getFullYear()}-{month < 10 ? 0 : ""}
        {month}-{day < 10 ? 0 : ""}
        {day}
      </div>
      <BodyText color="secondary">
        {dateObj.getHours() < 10 ? 0 : ""}
        {dateObj.getHours()}:{dateObj.getMinutes() < 10 ? 0 : ""}
        {dateObj.getMinutes()}
      </BodyText>
    </div>
  );
}
