/**
 * This hook checks if there are empty url fragments in the address bar and removes them.
 *
 * The initial reason for creating it was that the Facebook oauth flow redirects back to the application
 * and keeps the empty url fragment. i.e. localhost:3000/connections?#_=_ will automatically be changed to localhost:3000/connections
 * crtl-f "Redirect Behaviour" in this source: https://developers.facebook.com/blog/post/552/
 * */
import { useEffect } from "react";

export default function useUrlPrettifier() {
  // prevents empty url fragments in addressbar:
  useEffect(() => {
    if (window.location.hash && window.location.hash === "#_=_") {
      if (window.history && window.history.pushState) {
        window.history.pushState("", document.title, window.location.pathname);
      } else {
        // Prevent scrolling by storing the page's current scroll offset
        const scroll = {
          top: document.body.scrollTop,
          left: document.body.scrollLeft,
        };
        window.location.hash = "";
        // Restore the scroll offset, should be flicker free
        document.body.scrollTop = scroll.top;
        document.body.scrollLeft = scroll.left;
      }
    }
  }, []);
}
