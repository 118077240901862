import React, { useState } from "react";
import Header1 from "library/text/headers/Header1";
import BodyText from "library/text/body/BodyText";
import OutPointTextField from "library/form/OutPointTextField";
import PrimaryButton from "library/buttons/PrimaryButton";
import { useDispatch } from "react-redux";
import { addMessage } from "redux/snackbarSlice";
import PasswordFields, {
  getPasswordConditions,
} from "pages/login/components/PasswordFields";
import { changePassword } from "utils/authentication";

const styles = {
  header: {
    marginTop: "100px",
    marginBottom: "20px",
  },
  textBox: {
    marginBottom: "20px",
  },
};

export default function SecurityPage() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState(null);
  const [newPasswordError, setNewPasswordError] = useState(null);

  const dispatch = useDispatch();

  const submitPassword = async () => {
    setCurrentPasswordError(null);
    setNewPasswordError(null);

    changePassword(currentPassword, newPassword)
      .then(() => {
        dispatch(addMessage({ message: "Successfully updated password." }));
      })
      .catch((error) => {
        if (error.message === "Incorrect password") {
          setCurrentPasswordError(error.message);
        } else {
          dispatch(
            addMessage({
              message: `An unexpected error occured: ${error.message}`,
            }),
          );
        }
      });
  };

  const passwordConditions = getPasswordConditions(newPassword);

  return (
    <div>
      <Header1 sx={styles.header}>Security</Header1>
      <BodyText sx={{ marginBottom: "40px" }}>Change your password</BodyText>
      <OutPointTextField
        sx={styles.textBox}
        name="Current password"
        value={currentPassword}
        onChange={setCurrentPassword}
        error={Boolean(currentPasswordError)}
        errorMsg={currentPasswordError}
      />
      <OutPointTextField
        sx={styles.textBox}
        name="New password"
        value={newPassword}
        onChange={setNewPassword}
        error={Boolean(newPasswordError)}
        errorMsg={newPasswordError}
      />
      <PasswordFields password={newPassword} />
      <PrimaryButton
        onClick={submitPassword}
        disabled={
          !newPassword ||
          !currentPassword ||
          newPassword === currentPassword ||
          !passwordConditions.every(({ condition }) => condition)
        }
        sx={{ width: "150px", marginTop: "20px" }}
      >
        Save Changes
      </PrimaryButton>
    </div>
  );
}
