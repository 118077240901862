import { createSlice } from "@reduxjs/toolkit";

const snackbarSlice = createSlice({
  name: "snackbarSlice",
  initialState: {
    messages: [],
  },
  reducers: {
    addMessage: (state, { payload }) => {
      if (!payload.message) return;

      state.messages.push(payload.message);
    },
    removeMessage: (state) => {
      state.messages.shift();
    },
  },
});

export const { addMessage, removeMessage } = snackbarSlice.actions;

export default snackbarSlice.reducer;
