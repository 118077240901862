import React, { useState } from "react";

import { useSelector } from "react-redux";

import { uniqBy } from "lodash";
import Header1 from "library/text/headers/Header1";
import SubHead from "library/text/headers/SubHeadLight";
import OutPointDropdown from "library/buttons/OutPointDropdown";
import LineChart, {
  getEasilyExtendableOptions,
} from "library/graphing/LineChart";
import { createGraphItem, getClosestValueInList } from "utils/graphing/data";
import { dateSecondsToString } from "utils/data/dates";
import { GRAPH_DATE_FORMAT, TOOLTIP_DATE_FORMAT } from "utils/enums";
import { addCommasToDecimal, addCommasToNumber } from "utils/data/strings";
import BodyText from "library/text/body/BodyText";
import useAdsetPicker from "hooks/useAdsetPicker";
import { formatKpiForDisplay, unformatKpi } from "../../utils/data/formatters";

const formatMetricValue = (metric, value, metrics) => {
  const rawMetric = unformatKpi(metric);
  const [row] = metrics.filter(
    (possibleRow) => possibleRow.metric === rawMetric,
  );
  if (!row) return null;

  if (row.is_cost) {
    return `$${addCommasToDecimal(value)}`;
  }

  if (["enr", "ctr", "vtr"].includes(rawMetric)) {
    return `${addCommasToDecimal(value)}%`;
  }

  return addCommasToNumber(value);
};

const styles = {
  selector: {
    marginRight: "20px",
  },
  subhead: {
    marginBottom: "40px",
  },
};

function MetricsPage() {
  const hourlyData = useSelector((state) => state.hourlyData.data) || [];
  const ingestionData = useSelector((state) => state.ingestion.data) || [];
  const metricsData = useSelector((state) => state.metrics)?.data || [];
  const metrics = uniqBy(metricsData, (row) => row.metric).map((row) =>
    formatKpiForDisplay(row.metric),
  );
  const boostReportData = useSelector((state) => state.boostReport.data) || [];

  const [metricSelection, setMetricSelection] = useState("-");

  const {
    brandSelection,
    channelSelection,
    adsetSelection,
    brands,
    channels,
    adsets,
    changeBrand,
    changeChannel,
    changeAdset,
  } = useAdsetPicker(ingestionData);

  const graphData = (
    brandSelection && channelSelection && adsetSelection
      ? hourlyData.filter(
          (row) =>
            row.brand?.includes(brandSelection) &&
            row.channel === channelSelection &&
            row.adset_name === adsetSelection,
        )
      : []
  )
    .map((row) =>
      createGraphItem(
        new Date(row.date).getTime(),
        row[unformatKpi(metricSelection)],
      ),
    )
    .sort((e1, e2) => {
      if (e1.x > e2.x) return 1;
      if (e1.x < e2.x) return -1;
      return 0;
    });

  const graphBoosts = boostReportData.filter(
    (row) =>
      row.channel === channelSelection && row.adset_name === adsetSelection,
  );

  const graphOptions = getEasilyExtendableOptions();

  graphOptions.xScaleOptions.formatTicks = (val) =>
    dateSecondsToString(val, GRAPH_DATE_FORMAT);
  graphOptions.yScaleOptions.formatTicks = (val) =>
    formatMetricValue(metricSelection, val, metricsData);
  graphOptions.tooltipPluginOptions.formatTitle = (val) =>
    dateSecondsToString(val, TOOLTIP_DATE_FORMAT);

  graphOptions.annotationsPluginOptions = graphBoosts.map((row) => {
    const lineIndex = getClosestValueInList({
      value: new Date(row.created_at).getTime(),
      dataset: graphData,
      key: "x",
    });

    return {
      annotationSettings: {
        xMin: lineIndex,
        xMax: lineIndex,
      },
      labelSettings: {
        content: `Boost: $${addCommasToDecimal(row.boost_amount)}`,
        backgroundColor: "#50C878",
      },
    };
  });

  return (
    <>
      <Header1>Metrics</Header1>
      <SubHead sx={styles.subhead} color="secondary">
        Select an adset to view your metrics over time.
      </SubHead>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <div>
          <OutPointDropdown
            sx={styles.selector}
            menuItems={brands || [""]}
            selectedValue={brandSelection || "-"}
            onChange={changeBrand}
            leading="Brand: "
          />
          <OutPointDropdown
            sx={styles.selector}
            menuItems={channels || [""]}
            selectedValue={channelSelection || "-"}
            onChange={changeChannel}
            leading="Channel: "
            disabled={!brandSelection}
          />
          <OutPointDropdown
            sx={styles.selector}
            menuItems={adsets || [""]}
            selectedValue={adsetSelection || "-"}
            onChange={changeAdset}
            leading="Adset: "
            disabled={!brandSelection || !channelSelection}
            isSentenceCaseMenu={false}
          />
        </div>
        <OutPointDropdown
          sx={styles.selector}
          menuItems={metrics || [""]}
          selectedValue={metricSelection || "-"}
          onChange={(value) => setMetricSelection(value)}
          leading="Metric: "
          isSentenceCaseMenu={false}
        />
      </div>
      {brandSelection !== "-" &&
      channelSelection !== "-" &&
      adsetSelection !== "-" &&
      metricSelection !== "-" ? (
        <LineChart
          data={[
            {
              linePoints: graphData,
              color: "blue",
              name: metricSelection,
            },
          ]}
          moreOptions={graphOptions}
        />
      ) : (
        <BodyText>Choose an adset and a metric to view graph.</BodyText>
      )}
    </>
  );
}

export default MetricsPage;
