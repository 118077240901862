import React from "react";
import { Grid } from "@mui/material";

import { cardData, cardGroups } from "pages/connections/data/connectionsData";
import { useSelector } from "react-redux";
import PlatformCardTemplate from "./PlatformCardTemplate";

export default function PlatformCards({
  group,
  whereFrom,
  justify,
  height,
  tokenType,
}) {
  let cardsToDisplay = cardGroups.all;
  const fieldsPerChannel =
    useSelector((state) => state.jester?.fields?.data?.[tokenType]) || [];

  if (cardGroups[group]) cardsToDisplay = cardGroups[group];
  cardsToDisplay = cardsToDisplay.filter(
    (channel) => channel in fieldsPerChannel,
  );
  cardsToDisplay.sort((a, b) => {
    if (a.toLowerCase() < b.toLowerCase()) return -1;
    if (a.toLowerCase() > b.toLowerCase()) return 1;
    return 0;
  });

  return (
    <Grid
      container
      justifyContent={justify || "left"}
      spacing={3}
      data-cy="platform-cards"
    >
      {cardsToDisplay.map((channel) => (
        <Grid key={`platformCard-${channel}`} item xs={6} md={4}>
          <PlatformCardTemplate
            channel={channel}
            height={height}
            whereFrom={whereFrom}
            tokenType={tokenType}
            {...(cardData[channel] || {})}
          />
        </Grid>
      ))}
    </Grid>
  );
}
