import { useState } from "react";
import { uniq, uniqBy } from "lodash";

export default function useAdsetPicker(data) {
  const [brandSelection, setBrandSelection] = useState("-");
  const [channelSelection, setChannelSelection] = useState("-");
  const [adsetSelection, setAdsetSelection] = useState("-");

  const allBrands = data.reduce((brands, row) => brands.concat(row.brand), []);
  const brands = uniq(allBrands).filter((brand) => brand);
  const channels = uniqBy(
    data.filter((row) => row.brand?.includes(brandSelection)),
    (row) => row.channel,
  ).map((row) => row.channel);
  const adsets = uniqBy(
    data.filter(
      (row) =>
        row.brand?.includes(brandSelection) && row.channel === channelSelection,
    ),
    (row) => row.adset_name,
  ).map((row) => row.adset_name);

  const changeBrand = (value) => {
    setBrandSelection(value);
    setChannelSelection("-");
    setAdsetSelection("-");
  };

  const changeChannel = (value) => {
    setChannelSelection(value);
    setAdsetSelection("-");
  };

  const changeAdset = (value) => setAdsetSelection(value);

  return {
    brandSelection,
    channelSelection,
    adsetSelection,
    brands,
    channels,
    adsets,
    changeBrand,
    changeChannel,
    changeAdset,
  };
}
