/* eslint-disable camelcase */
import React from "react";

import OutPointCard from "library/surface/OutPointCard";
import TopItemWithMetrics from "./TopItemWithMetrics";

function TopPostsCard({ posts }) {
  return (
    <OutPointCard
      style={{
        padding: "16px",
        height: "auto",
      }}
    >
      {posts.map(({ live_asset_link, ...rest }, i) => (
        <TopItemWithMetrics
          key={live_asset_link}
          name={live_asset_link}
          link={live_asset_link}
          i={i}
          {...rest}
        />
      ))}
    </OutPointCard>
  );
}

export default TopPostsCard;
