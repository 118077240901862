import React, { useState } from "react";

import { useSelector } from "react-redux";

import LogoChip from "pages/connections/components/LogoChip";
import Header1 from "library/text/headers/Header1";
import PressableText from "library/buttons/PressableText";

import { cardData } from "pages/connections/data/connectionsData";
import { capitalize } from "lodash";
import OutPointDropdown from "library/buttons/OutPointDropdown";
import BasicDataGrid from "library/display/BasicDataGrid";
import AdvertiseButton from "./components/AdvertiseButton";

const POST_TABLE_HEADERS = [
  {
    headerName: "",
    field: "platform",
    align: "center",
    headerClassName: "header",
    cellClassName: "align-center",
    resizable: false,
    sortable: false,
    renderCell: ({ value: channel }) => (
      <LogoChip
        logo={cardData[channel]?.logo}
        channel={channel}
        size="20px"
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      />
    ),
  },
  {
    headerName: "Account",
    field: "description",
    align: "center",
    headerAlign: "center",
    headerClassName: "header",
    minWidth: 180,
  },
  {
    headerName: "Post preview",
    field: "preview_link",
    align: "center",
    headerClassName: "header",
    cellClassName: "align-center",
    headerAlign: "center",
    width: 200,
    renderCell: ({ value: preview }) =>
      preview ? (
        <img
          style={{
            maxWidth: "200px",
            maxHeight: "100px",
          }}
          src={preview}
          alt="Preview of page post"
        />
      ) : (
        "-"
      ),
  },
  {
    headerName: "Page",
    field: "page_name",
    align: "center",
    headerClassName: "header",
    headerAlign: "center",
    cellClassName: "align-center",
    minWidth: 200,
  },
  {
    headerName: "Link",
    field: "live_asset_link",
    align: "left",
    headerClassName: "header",
    cellClassName: "align-center",
    flex: 1,
    minWidth: 500,
  },
  {
    headerName: "",
    field: "",
    align: "center",
    headerClassName: "header",
    cellClassName: "align-center",
    resizable: false,
    sortable: false,
    renderCell: ({ row }) => (
      <AdvertiseButton
        platform={row.platform}
        pageCredentialId={row.page_credential_id}
        adCredentialId={row.jester_credential_id}
        postId={row.post_id}
        preFilledData={row}
      />
    ),
  },
];

function PostsPage() {
  const allPosts = useSelector((state) => state.postsData).data || [];
  const pages = useSelector((state) => state.postsData).pages?.data || [];
  const channels = Object.keys(
    useSelector((state) => state.jester?.fields?.data?.page) || {},
  );

  const [selection, setSelection] = useState("recommended");
  const [pageFilter, setPageFilter] = useState("None");
  const [channelFilter, setChannelFilter] = useState("None");

  const postData = {
    recommended: allPosts.filter(
      (post) => post.budget !== null && post.budget !== undefined,
    ),
    actioned: allPosts.filter((post) => post.most_recent_ad_created_at),
    all: allPosts,
  };
  const filteredPosts =
    pageFilter === "None" && channelFilter === "None"
      ? postData[selection]
      : postData[selection]?.filter(({ page_name: pageName, platform }) => {
        let valid = true;
        if (pageFilter !== "None") {
          valid = valid && pageName === pageFilter;
        }
        if (channelFilter !== "None") {
          valid = valid && platform === channelFilter;
        }
        return valid;
      });

  return (
    <div>
      <Header1 sx={{ marginBottom: "50px" }}>Posts</Header1>
      <div style={{ display: "flex", direction: "row", marginBottom: "30px" }}>
        {Object.keys(postData).map((group) => {
          return (
            <PressableText
              sx={{
                fontSize: "20px",
                marginRight: "10px",
              }}
              onClick={() => setSelection(group)}
              active={selection === group}
            >
              {capitalize(group)}
              {` (${postData[group].length})`}
            </PressableText>
          );
        })}
      </div>
      <div
        style={{
          marginBottom: "20px",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        <OutPointDropdown
          selectedValue={channelFilter}
          menuItems={["None", ...channels]}
          onChange={(value) => setChannelFilter(value)}
          leading="Channel: "
        />
        <OutPointDropdown
          selectedValue={pageFilter}
          menuItems={["None", ...pages]}
          onChange={(value) => setPageFilter(value)}
          leading="Page Name: "
        />
      </div>
      <BasicDataGrid
        displayColumns={POST_TABLE_HEADERS}
        displayRows={filteredPosts}
        getRowId={(row) => row.post_table_id}
      />
    </div>
  );
}

export default PostsPage;
