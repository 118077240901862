import React, { useState } from "react";

import Header1 from "library/text/headers/Header1";
import SecurityPage from "./SecurityPage";
import Sidebar from "./Sidebar";

const styles = {
  headerMain: {
    marginBottom: "20px",
  },
  container: {
    display: "flex",
    flexDirection: "row",
  },
};

const PAGES = [
  {
    name: "Security",
    page: <SecurityPage />,
  },
];

export default function AccountPage() {
  const [currentPage, setCurrentPage] = useState(0);

  return (
    <div style={styles.container}>
      <div>
        <Header1 sx={styles.headerMain}>Account</Header1>
        <Sidebar
          pages={PAGES}
          currentPage={currentPage}
          setPage={setCurrentPage}
        />
      </div>
      {PAGES[currentPage].page}
    </div>
  );
}
