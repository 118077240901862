import { createSlice } from "@reduxjs/toolkit";

const SELECTIONS = [
  "platform",
  "adsetBrand",
  "credentialBrand",
  "kpi",
  "action",
  "display",
  "status",
];

const homeFiltersSlice = createSlice({
  name: "homeFiltersSlice",
  initialState: {
    platformSelection: "All",
    adsetBrandSelection: "All",
    credentialBrandSelection: "All",
    kpiSelection: "All",
    actionSelection: "All",
    displaySelection: "recommendations",
    statusSelection: "All",
  },
  reducers: {
    getSelectionsFromLocalStorage: (state) => {
      SELECTIONS.forEach((selectionName) => {
        const selection = `${selectionName}Selection`;
        const storedSelection = localStorage.getItem(selection);
        if (storedSelection) {
          state[selection] = storedSelection;
        }
      });
    },
    setSelection: (state, { payload }) => {
      const { selectionName, selectionValue } = payload;
      const selection = `${selectionName}Selection`;
      state[selection] = selectionValue;
      localStorage.setItem(selection, selectionValue);
    },
    selectionsLogOut: (_) => {
      SELECTIONS.forEach((selectionName) => {
        const selection = `${selectionName}Selection`;
        localStorage.setItem(selection, "");
      });
    },
  },
});

export const { setSelection, getSelectionsFromLocalStorage, selectionsLogOut } =
  homeFiltersSlice.actions;

export default homeFiltersSlice.reducer;
