import React from "react";
import { FormControl, Select, MenuItem, Checkbox } from "@mui/material";

import { FAINT_BLUE } from "assets/palette";
import SubHeadLight from "library/text/headers/SubHeadLight";
import { capitalize } from "utils/data/strings";

const styles = {
  formControl: {
    minWidth: 120,
    width: "100%",
  },
  selectStyle: {
    borderRadius: "7px",
    height: "38px",
    width: "183px",
    color: "black",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    fontFamily: "IBM Plex Sans",
  },
  menuItemStyle: {
    display: "flex",

    alignItems: "center",
    padding: "16px 24px",
    gap: "10px",

    // width: '132px',
    height: "56px",
  },
};

export default function OutPointSelector({
  onChange,
  menuItems = [],
  selectedValue, // TODO add default [ not to self & reviewers don't aprove pr if this isn't removed]
  leading = "", // leading value
  trailing = "", // trailing value
  StartAdornment = () => <div />,
  startAdornmentStyle = { marginRight: 5 },
  sx = {},
  multiple = false,
  menuItemAdornment = {},
  isSentenceCaseMenu = true,
  renderValueOverride = null, // Supply a custom component render value
  ...rest
}) {
  const handleChange = (event) => {
    const eventTargetValue = event.target.value;

    if (!multiple) {
      return onChange(eventTargetValue);
    }

    const values = JSON.parse(JSON.stringify(selectedValue));
    const [, selectedTargetValue] = eventTargetValue;

    const indexOfValue = values.indexOf(selectedTargetValue);

    if (indexOfValue > -1) {
      values.splice(indexOfValue, 1);

      return onChange(values);
    }

    values.push(selectedTargetValue);

    // add
    return onChange(values);
  };

  let formattedMenuItems = menuItems.map((item) => {
    return {
      display: item,
      decoratedDisplay: `${leading}${item}${trailing}`,
    };
  });

  const displayValue =
    selectedValue && isSentenceCaseMenu
      ? capitalize(selectedValue)
      : selectedValue;
  let value = `${leading}${displayValue}${trailing}`;

  if (multiple) {
    formattedMenuItems = menuItems.map((item) => {
      return {
        display: item,
      };
    });

    value = [`${leading}${selectedValue.length}${trailing}`];
  }

  return (
    <FormControl required sx={styles.formControl}>
      <Select
        sx={{ ...styles.selectStyle, ...sx }}
        value={value}
        multiple={multiple}
        onChange={handleChange}
        startAdornment={<StartAdornment style={startAdornmentStyle} />}
        renderValue={() => {
          if (renderValueOverride) return renderValueOverride();

          return <SubHeadLight isSentenceCase={false}>{value}</SubHeadLight>;
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: "white",
              },
              "& .MuiMenuItem-root:hover": {
                backgroundColor: FAINT_BLUE,
              },
            },
          },
        }}
        {...rest}
      >
        {formattedMenuItems.map(({ display = "" }) => {
          const { value: itemValue, position } = menuItemAdornment;

          let menuItemDisplay = display;

          if (position === "start" && itemValue) {
            menuItemDisplay = `${itemValue}${menuItemDisplay}`;
          }

          if (position === "end" && itemValue) {
            menuItemDisplay = `${menuItemDisplay}${itemValue}`;
          }

          return (
            <MenuItem key={itemValue} value={display} sx={styles.menuItemStyle}>
              {multiple && (
                <Checkbox
                  hidden={!display}
                  checked={selectedValue.indexOf(display) > -1}
                />
              )}
              <SubHeadLight isSentenceCase={isSentenceCaseMenu}>
                {menuItemDisplay}
              </SubHeadLight>
            </MenuItem>
          );
        })}
        {/* {
        MuI doesn't allow a different value option if not included in menu options

        Render shadow menu items that are hidden to the user
        The purpose of this is two fold:

        1. Meet design requirements where the display to the user includes formatting to the
        menu items.
          Example:
            - menu items = [1, 2, 3] (if the user clicks 1, the value is 1)
            However design requirements: show the user the menu Items as above when selecting, but once selected, add formatting. Like so:
            - "You selected: 1" (<- this is the "value")
          items.include(value) === false, and therefore is not permitted.
        
        The solution: rendering shadow hidden items as below to silence the warnings
      } */}
        {(!!leading || !!trailing) &&
          formattedMenuItems.map(({ decoratedDisplay }) => (
            <MenuItem key={decoratedDisplay} value={decoratedDisplay} hidden>
              <SubHeadLight isSentenceCase={false}>
                {decoratedDisplay}
              </SubHeadLight>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}
