import React from "react";

import StarIcon from "@mui/icons-material/StarRounded";
import { Grid, Chip } from "@mui/material";
import CompactMetricDisplay from "./CompactMetricDisplay";

const placeToColor = ["#FFD700", "#C0C0C0", "#CD7F32"];

function TopItemWithMetrics({ name, i, cpe, spend, enr, link = null }) {
  const onClick = () => window.open(`https://${link}`, "_blank");
  return (
    <Grid
      container
      key={link}
      style={{
        width: "100%",
        padding: "4px 0px",
      }}
    >
      <Grid
        item
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          padding: "2px 4px 2px 4px",
          textAlign: "center",
          position: "relative",
        }}
        xs={8}
      >
        <Chip
          onClick={link && onClick}
          label={name}
          sx={{
            fontWeight: "bold",
            borderWidth: "2px",
            maxWidth: "95%",
          }}
        />
        {typeof i === "number" && (
          <StarIcon
            sx={{
              height: "25px",
              width: "25px",
              color: placeToColor[i],
              position: "absolute",
              top: "-4px",
              left: "-5px",
            }}
          />
        )}
      </Grid>
      <Grid item xs={4}>
        <CompactMetricDisplay cpe={cpe} spend={spend} enr={enr} />
      </Grid>
    </Grid>
  );
}

export default TopItemWithMetrics;
