import React, { useState } from "react";
import { Button } from "@mui/material";
import SubHead from "library/text/headers/SubHead";
import { KeyboardArrowRight, KeyboardArrowDown } from "@mui/icons-material";
import { addCommasToDecimal } from "utils/data/strings";

const styles = {
  card: {
    backgroundColor: "#F7F5F5",
    borderRadius: "4px",
    padding: "15px",
  },
  header: {
    marginBottom: "30px",
  },
  lineItem: {
    display: "flex",
    flexDirection: "row",
    color: "#645c5d",
    fontSize: "14px",
  },
  columnarLineItem: {
    display: "flex",
    flexDirection: "column",
    color: "#645c5d",
    fontSize: "14px",
    noWrap: false,
    whiteSpace: "pre-wrap",
    wordBreak: "break-all",
    width: "100%",
  },
  lineTitle: {
    width: "130px",
  },
  line: {
    borderTop: "1px solid #E3E6EC",
    width: "100%",
    margin: "20px 0 20px 0",
  },
  boostHistoryButton: {
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
    minWidth: "40px",
    maxWidth: "40px",
  },
  boostHistoryLine: {
    display: "flex",
    flexDirection: "row",
    color: "#645c5d",
    marginTop: "20px",
    alignItems: "center",
  },
  historyLineItem: {
    display: "flex",
    flexDirection: "row",
    color: "#645c5d",
    fontSize: "14px",
    marginLeft: "40px",
  },
};

export default function BoostDetails({
  adsetName,
  currentBudget,
  recommendedOn,
  boostAmount,
  submittedOn,
  expiredOn,
}) {
  const showBoostHistory =
    (boostAmount === 0 || boostAmount) && submittedOn && expiredOn;

  const [openBoostHistory, setOpenBoostHistory] = useState(false);

  const formatDate = (dateString) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const date = new Date(dateString);
    return date.toLocaleString(undefined, options);
  };

  return (
    <div style={styles.card}>
      <SubHead sx={styles.header}>Boost details</SubHead>
      <div style={styles.columnarLineItem}>
        <div style={styles.lineTitle}>Ad set</div>
        <br />
        <div>{adsetName}</div>
      </div>
      <div style={styles.line} />
      <div style={styles.lineItem}>
        <div style={styles.lineTitle}>Current budget</div>
        <div>
          {currentBudget ? `$${addCommasToDecimal(currentBudget)}` : "-"}
        </div>
      </div>
      {recommendedOn ? (
        <>
          <div style={styles.line} />
          <div style={styles.lineItem}>
            <div style={styles.lineTitle}>Recommended on</div>
            <div>{formatDate(recommendedOn)}</div>
          </div>
        </>
      ) : (
        <div />
      )}
      {showBoostHistory ? (
        <>
          <div style={styles.boostHistoryLine}>
            <Button
              disableRipple
              sx={styles.boostHistoryButton}
              onClick={() => setOpenBoostHistory(!openBoostHistory)}
            >
              {openBoostHistory ? (
                <KeyboardArrowDown />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
            <div>Boost history</div>
          </div>
          {openBoostHistory ? (
            <>
              <div style={{ height: "10px" }} />
              <div style={styles.historyLineItem}>
                <div style={styles.lineTitle}>Boost amount</div>
                <div>${addCommasToDecimal(boostAmount)}</div>
              </div>
              <div style={styles.line} />
              <div style={styles.historyLineItem}>
                <div style={styles.lineTitle}>Submitted on</div>
                <div>{formatDate(submittedOn)}</div>
              </div>
              <div style={styles.line} />
              <div style={styles.historyLineItem}>
                <div style={styles.lineTitle}>Expired on</div>
                <div>{formatDate(expiredOn)}</div>
              </div>
            </>
          ) : (
            <div />
          )}
        </>
      ) : (
        <div />
      )}
    </div>
  );
}
